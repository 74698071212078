import {PermitService} from "permit/service";

import {useApprovedPermitsTable} from "permit/data/ApprovedPermitsTableColumns";
import {EntityListPage} from "base/entity/components/container";
import {ApprovedPermitFilterForm} from "permit/presentational/form";
// import {discriminators, usePermitLayer} from "permit/geo";

const ListApprovedPermitsPage = () => {
    const {tableColumns, getCellProps} = useApprovedPermitsTable();

    return (
        <EntityListPage
            views={["table"]}
            entityName={{
                singular: "Autorização aprovada",
                plural: "Autorizações aprovadas",
            }}
            service={PermitService}
            tableColumns={tableColumns}
            getCellProps={getCellProps}
            filterForm={<ApprovedPermitFilterForm />}
            // layerHook={usePermitLayer}
            // layerDefaultDiscriminator={discriminators.STATUS}
            disableNavigation
        />
    );
};
export default ListApprovedPermitsPage;
