import {useState, useEffect, createContext, useContext} from "react";
import {DomainService} from "snimf/domain/service";

let DomainContext = createContext(null);

export default function DomainProvider({children}) {
    const linkedEntitiesDomain = [
        {value: "permit", label: "Autorizações", ordering: null},
        {value: "violation", label: "Infracções", ordering: null},
        {value: "plantation", label: "Plantio", ordering: null},
    ];

    const landUsePrivateDomain = [
        {value: "Privado (Gleba)", label: "Privado (Gleba)", ordering: null},
        {value: "Terreno Particular", label: "Terreno Particular", ordering: null},
    ];
    const landUseStateDomain = [
        {value: "Área florestal", label: "Área florestal", ordering: null},
        {value: "Concessão estatal", label: "Concessão estatal", ordering: null},
    ];

    const [yesNoDomain, setYesNoDomain] = useState([]);
    const [genderDomain, setGenderDomain] = useState([]);
    const [docTypeDomain, setDocTypeDomain] = useState([]);
    const [materialTypeDomain, setMaterialTypeDomain] = useState([]);
    const [landCoverDomain, setLandCoverDomain] = useState([]);
    const [landOwnershipDomain, setLandOwnershipDomain] = useState([]);
    const [landSizeDomain, setLandSizeDomain] = useState([]);
    const [landUseDomain, setLandUseDomain] = useState([]);
    const [stateConcessionDomain, setStateConcessionDomain] = useState([]);
    const [treeQualityDomain, setTreeQualityDomain] = useState([]);
    const [speciesDomain, setSpeciesDomain] = useState([]);
    const [holderTypeDomain, setHolderDomain] = useState([]);
    const [violationTypeDomain, setViolationTypeDomain] = useState([]);
    const [violationActionTakenDomain, setViolationActionTakenDomain] = useState([]);
    const [violationStatusDomain, setViolationStatusDomain] = useState([]);
    const [violationDestinationDomain, setViolationDestinationDomain] = useState([]);
    const [permitTypeDomain, setPermitTypeDomain] = useState([]);
    const [permitApplicationStatusDomain, setPermitApplicationStatusDomain] = useState(
        []
    );

    useEffect(() => {
        Promise.all([DomainService.getDomain()])
            .then(([domain]) => {
                console.log(domain);
                const {
                    yes_no,
                    gender,
                    doc_type,
                    material_type,
                    land_cover,
                    land_ownership,
                    land_size,
                    land_use,
                    state_concession,
                    species,
                    tree_quality,
                    holder_type,
                    permit_type,
                    permit_status,
                    violation_type,
                    violation_action_taken,
                    violation_status,
                    violation_destination,
                } = domain;
                setYesNoDomain(yes_no);
                setGenderDomain(gender);
                setDocTypeDomain(doc_type);
                setSpeciesDomain(species);
                setTreeQualityDomain(tree_quality);
                setMaterialTypeDomain(material_type);
                setLandCoverDomain(land_cover);
                setLandUseDomain(land_use);
                setLandOwnershipDomain(land_ownership);
                setLandSizeDomain(land_size);
                setStateConcessionDomain(state_concession);
                setHolderDomain(holder_type);
                setViolationTypeDomain(violation_type);
                setViolationActionTakenDomain(violation_action_taken);
                setViolationStatusDomain(violation_status);
                setViolationDestinationDomain(violation_destination);
                setPermitTypeDomain(permit_type);
                setPermitApplicationStatusDomain(
                    permit_status?.filter(item => item.value !== "Aprovada")
                );
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    let value = {
        yesNoDomain,
        genderDomain,
        docTypeDomain,
        materialTypeDomain,
        landCoverDomain,
        landUseDomain,
        landOwnershipDomain,
        landSizeDomain,
        stateConcessionDomain,
        speciesDomain,
        treeQualityDomain,
        holderTypeDomain,
        violationTypeDomain,
        violationActionTakenDomain,
        violationStatusDomain,
        violationDestinationDomain,
        permitTypeDomain,
        permitApplicationStatusDomain,
        linkedEntitiesDomain,
        landUsePrivateDomain,
        landUseStateDomain,
    };

    return <DomainContext.Provider value={value}>{children}</DomainContext.Provider>;
}

function useDomain() {
    return useContext(DomainContext);
}

export {useDomain};
