import {useCallback, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";

import {useList} from "base/entity/provider";
import {useDomain} from "snimf/domain/provider";
import {useAdministrativeDivisions} from "snimf/location/provider";

import {
    FormClearButtonSmall,
    FormSelect,
    FormSelectMultiple,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const PlotFilterForm = ({onClear = null}) => {
    const {filter, changeFilter, setFilterValue, resetFilter, setPage} = useList();
    const {landOwnershipDomain, linkedEntitiesDomain} = useDomain();

    const {districts} = useAdministrativeDivisions();

    useEffect(() => {
        if (!Object.keys(filter).length) {
            handleClearAllFilters();
        }
    }, [filter]);

    useEffect(() => {
        const attributeValue = {district: ""};
        let updatedFilter = {...filter, ...attributeValue};
        changeFilter(updatedFilter);
    }, [districts]);

    const formMethods = useForm({
        defaultValues: {
            district: filter?.district || "",
            land_ownership: filter?.land_ownership || "",
            for_entity: filter?.for_entity || [],
        },
    });

    const handleChange = useCallback(
        (attribute, value) => {
            setPage(1);
            setFilterValue(attribute, value);
        },
        [filter]
    );

    const handleClearAllFilters = () => {
        formMethods.reset({
            district: "",
            land_ownership: "",
            for_entity: filter?.for_entity || [],
        });
        if (onClear) {
            onClear();
        }
        if (Object.entries(filter).length > 0) resetFilter();
    };

    return (
        <FormProvider {...formMethods}>
            <Grid container columnSpacing={1} alignItems="center" mt={1}>
                <Grid item xs={3}>
                    <FormSelect
                        name="district"
                        label="Distrito"
                        options={districts}
                        onChangeHandler={option => handleChange("district", option)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormSelect
                        name="land_ownership"
                        label="Propriedade da terra"
                        options={landOwnershipDomain}
                        onChangeHandler={option =>
                            handleChange("land_ownership", option)
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormSelectMultiple
                        name="for_entity"
                        label="Procedimento"
                        options={linkedEntitiesDomain}
                        onChangeHandler={option => handleChange("for_entity", option)}
                    />
                </Grid>

                <Grid item xs={0.5}>
                    <FormClearButtonSmall handleClear={handleClearAllFilters} />
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default PlotFilterForm;
