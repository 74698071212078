import {FieldUtil} from "base/ui/section/utilities";
import Grid from "@mui/material/Grid";

const PlotLocationSection = ({plot}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Ilha", plot?.island)}
                {FieldUtil.getSectionField("Sub distrito", plot?.sub_district)}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Distrito", plot?.district)}
                {FieldUtil.getSectionField("Localidade / Comunidade", plot?.locality)}
            </Grid>
        </Grid>
    );
};

export default PlotLocationSection;
