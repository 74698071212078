import {Navigate, Route} from "react-router-dom";
import {ViewDocumentPanel} from "base/file/components";
import {
    ListPlotsPage,
    ViewPlotDocumentsSubPage,
    ViewPlotLocationSubPage,
    ViewPlotPage,
    ViewPlotProceduresSubPage,
    ViewPlotSummarySubPage,
} from "plot/container";

const plotRoutes = [
    <Route key="plot-manage" path="all/list">
        <Route key="plots-list" path="" element={<ListPlotsPage />} />
        <Route key="plot-detail" path=":id" element={<ViewPlotPage />}>
            <Route
                key="plot-summary"
                path="summary"
                element={<ViewPlotSummarySubPage />}
            />
            <Route
                key="plot-location"
                path="location"
                element={<ViewPlotLocationSubPage />}
            />
            <Route
                key="plot-procedures"
                path="procedures"
                element={<ViewPlotProceduresSubPage />}
            />
            <Route
                key="plot-documents"
                path="documents/*"
                element={<ViewPlotDocumentsSubPage />}
            >
                <Route
                    key="plot-documents-view"
                    path="detail/*"
                    element={<ViewDocumentPanel />}
                />
            </Route>
            <Route index element={<Navigate to="summary" replace />} />
        </Route>
    </Route>,

    <Route key="redirect-plot-index" index element={<Navigate to="list" replace />} />,
];

export default plotRoutes;
