import {useState, useEffect, createContext, useContext} from "react";
import {useLocation} from "react-router-dom";
import {useAuth} from "base/user/provider";
import {LocationService} from "snimf/location/service";

let LocationContext = createContext(null);

export default function LocationProvider({children}) {
    const [islands, setIslands] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subdistricts, setSubdistricts] = useState([]);
    const [userDistricts, setUserDistricts] = useState([]);
    const location = useLocation();

    const auth = useAuth();

    useEffect(() => {
        Promise.all([LocationService.getAdministrativeDivisions()]).then(
            ([administrativeDivisions]) => {
                const {islands, districts, subdistricts} = administrativeDivisions;
                setIslands(islands);
                setDistricts(districts);
                setSubdistricts(subdistricts);
            }
        );
    }, [location.state?.lastRefreshDate]);

    useEffect(() => {
        if (auth?.user.is_superuser) {
            setUserDistricts(districts);
        } else {
            setUserDistricts(
                districts.filter(district => district.island_code === auth?.user.island)
            );
        }
    }, [auth, districts]);

    let value = {
        islands,
        districts,
        subdistricts,
        userDistricts,
    };

    return (
        <LocationContext.Provider value={value}>{children}</LocationContext.Provider>
    );
}

function useAdministrativeDivisions() {
    return useContext(LocationContext);
}

export {useAdministrativeDivisions};
