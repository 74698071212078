import {cloneElement} from "react";
import {ActionsMenu} from "base/ui/menu";

import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography/Typography";

const SubSectionCardHeader = ({
    titleLabel = "",
    titleValue = "",
    titleTags = null,
    icon = null,
    actions = null,
    ...props
}) => {
    const titleIcon = icon
        ? cloneElement(icon, {
              sx: {color: "grey", fontSize: "18px"},
          })
        : null;

    return (
        <CardHeader
            action={<ActionsMenu>{actions}</ActionsMenu>}
            title={
                <Stack direction="row" alignItems="center" spacing={1}>
                    {titleIcon}
                    {titleLabel && <Typography color="grey">{titleLabel}:</Typography>}
                    {titleValue && (
                        <Typography
                            color="primary.dark"
                            sx={{
                                textTransform: "uppercase",
                                fontWeight: "bold",
                            }}
                        >
                            {titleValue}
                        </Typography>
                    )}
                    {titleTags}
                </Stack>
            }
            sx={{
                py: 1.5,
                bgcolor: "grey.50",
                borderBottom: 1,
                borderColor: "grey.200",
                "& .MuiCardHeader-action": {alignSelf: "center"},
            }}
        />
    );
};

export default SubSectionCardHeader;
