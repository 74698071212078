import {useState} from "react";

import {PlantationService} from "plantation/service";
import {plant_view_adapter} from "plant/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {PlantForm} from "plant/presentational/form";
import {AddNewInlineItemButton, AddNewInlineItemFormBox} from "base/shared/components";

const CreatePlantDataContent = ({plantationId}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("button");
    const [error, setError] = useState(null);

    const handleFormSubmit = plant => {
        PlantationService.createPlant(plantationId, plant_view_adapter({...plant}))
            .then(createdPlant => {
                setMode("button");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    return mode === "button" ? (
        <AddNewInlineItemButton
            onClick={() => {
                setMode("create");
            }}
            label="Acrescentar planta"
        />
    ) : mode === "create" ? (
        <AddNewInlineItemFormBox label="Acrescentar planta">
            <PlantForm
                plantationId={plantationId}
                onSubmit={handleFormSubmit}
                onCancel={() => {
                    setMode("button");
                }}
                error={error}
            />
        </AddNewInlineItemFormBox>
    ) : null;
};

export default CreatePlantDataContent;
