import {TreeDataFeesFormFields, TreeDataInitialFormFields} from ".";
import Grid from "@mui/material/Grid";

const TreeDataFormFields = ({tree, permit, displayFees}) => {
    return (
        <Grid container direction="row" columnSpacing={1}>
            {displayFees ? (
                <TreeDataFeesFormFields tree={tree} permit={permit} />
            ) : (
                <TreeDataInitialFormFields />
            )}
        </Grid>
    );
};

export default TreeDataFormFields;
