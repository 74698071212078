import {DomainProvider} from "snimf/domain/provider";
import {ListProvider} from "base/entity/provider";
import {ModuleLayout} from "base/ui/module/components";
import {ModuleConfigProvider} from "base/ui/module/provider";
import {PlantationPageMenu} from "plantation/menu";

const PlantationsModule = () => {
    return (
        <ModuleConfigProvider>
            <DomainProvider>
                <ListProvider>
                    <ModuleLayout menu={<PlantationPageMenu />} />
                </ListProvider>
            </DomainProvider>
        </ModuleConfigProvider>
    );
};

export default PlantationsModule;
