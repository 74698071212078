import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";

import {useNavigateWithReload} from "base/navigation/hooks";
import {useList} from "base/entity/provider";
import {LocationProvider} from "snimf/location/provider";

import {
    EntityListMap,
    EntityListPageHeader,
    EntityTable,
} from "base/entity/components/presentational";
import {PageLayout} from "base/ui/main";
import {PaperContainer} from "base/ui/containers";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import {MapProvider} from "base/map";
import {MapBaseLayersProvider} from "base/map/layer";

const EntityListPage = ({
    views = ["table", "map"],
    entityName,
    service,
    defaultFilter = null,
    tableColumns,
    getCellProps = null,
    filterForm = null,
    createButton = null,
    elementActions = null,
    subPage = true,
    disableNavigation = false,
}) => {
    const navigate = useNavigateWithReload();
    const {idInfoPanel} = useParams();

    const {view, filter, size, isLoading} = useList();

    const isFilterEmpty = filter ? Object.values(filter)?.every(value => !value) : true;

    const [selectedElement, setSelectedElement] = useState(null);
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(() => {
        return !isFilterEmpty;
    });

    const handleSelectElement = elementId => {
        setSelectedElement(elementId);
        if (!disableNavigation) navigate(`info/${elementId}`);
    };

    useEffect(() => {
        // If the sidebar panel is open we must highlight the element
        setSelectedElement(idInfoPanel ? parseInt(idInfoPanel) : null);
    }, [idInfoPanel]);

    const getViewComponent = () => {
        if (view === "map" && views.includes(view)) {
            return <EntityListMap />;
        }
        return (
            <EntityTable
                columns={tableColumns}
                service={service}
                defaultFilter={defaultFilter}
                selectedElement={selectedElement}
                onSelectElement={handleSelectElement}
                elementActions={elementActions}
                getCellProps={getCellProps}
                isFilterEmpty={isFilterEmpty}
            />
        );
    };

    const toggleFilterAccordion = () => {
        setIsAccordionExpanded(prevState => !prevState);
    };

    return (
        filter && (
            <PageLayout subPage={subPage}>
                <PaperContainer>
                    <EntityListPageHeader
                        entityName={entityName}
                        views={views}
                        filter={filter}
                        size={size}
                        isLoading={isLoading}
                        createButton={createButton}
                        onClickToggleAccordion={
                            filterForm ? toggleFilterAccordion : null
                        }
                        isAccordionExpanded={isAccordionExpanded}
                    />
                    {filterForm ? (
                        <Collapse
                            in={isAccordionExpanded}
                            timeout="auto"
                            sx={{width: "100%"}}
                        >
                            <LocationProvider>{filterForm}</LocationProvider>
                        </Collapse>
                    ) : null}
                    <Grid item xs={12} sx={{mt: 3}}>
                        {getViewComponent()}
                    </Grid>
                </PaperContainer>
            </PageLayout>
        )
    );
};
export default EntityListPage;
