import {useLocation, useNavigate} from "react-router-dom";

export function useNavigateWithReload() {
    const navigate = useNavigate();
    const location = useLocation();

    const customNavigate = (url, reload = false) => {
        const to = url || `${location.pathname}${location.hash}`;

        if (reload) {
            navigate(to, {
                state: {lastRefreshDate: new Date()},
            });
        } else {
            navigate(to);
        }
    };

    return customNavigate;
}
