import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity/components/container";
import {ApprovedPermitBillingSection} from "permit/presentational";

const ViewApprovedPermitBillingSubPage = () => {
    const context = useOutletContext();
    const permit = context[0];

    let sections = [<ApprovedPermitBillingSection permit={permit} key="billing" />];

    return permit && <EntityViewSubPage sections={sections} />;
};

export default ViewApprovedPermitBillingSubPage;
