import {
    createPermit,
    createPermits,
    permit_api_adapter,
    permits_api_adapter,
} from "permit/model";
import {createEntityService} from "base/entity/service";
import {AuthApiService} from "base/api/service";
import {createViolations, violations_api_adapter} from "violation/model";
import {createTrees, trees_api_adapter} from "tree/model";

const basePath = "/api/app/permits";

const entityService = createEntityService(
    basePath,
    createPermit,
    createPermits,
    permit_api_adapter,
    permits_api_adapter
);

const PermitService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    getTrees(permitId) {
        return AuthApiService.get(`${basePath}/${permitId}/trees/`).then(response => {
            return createTrees(trees_api_adapter(response));
        });
    },

    getViolations(permitId) {
        return AuthApiService.get(`${basePath}/${permitId}/violations/`).then(
            response => {
                return createViolations(violations_api_adapter(response));
            }
        );
    },

    create(permit) {
        return entityService.create(permit);
    },

    update(permit) {
        return entityService.update(permit);
    },

    downloadInvoice(permitId) {
        return AuthApiService.get(`${basePath}/${permitId}/printinvoice/`).then(
            response => {
                return response;
            }
        );
    },

    downloadPermit(permitId) {
        return AuthApiService.get(`${basePath}/${permitId}/printpermit/`).then(
            response => {
                return response;
            }
        );
    },

    canBeDeleted(id) {
        return entityService.canBeDeleted(id).then(response => {
            if (response.result === "success") {
                return [true, ""];
            }
            return [false, response.detail];
        });
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default PermitService;
