import {useCallback, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";

import {useList} from "base/entity/provider";
import {useDomain} from "snimf/domain/provider";

import {
    FormClearButtonSmall,
    FormSelect,
    FormSelectMultiple,
    FormSwitchInput,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const HolderFilterForm = ({onClear = null}) => {
    const {holderTypeDomain, linkedEntitiesDomain} = useDomain();
    const {filter, changeFilter, resetFilter, setPage} = useList();

    useEffect(() => {
        if (!Object.keys(filter).length) {
            handleClearAllFilters();
        }
    }, [filter]);

    const formMethods = useForm({
        defaultValues: {
            type: filter?.type || "",
            for_entity: filter?.for_entity || [],
            for_entity_plot: !!filter?.for_entity?.includes("plot"),
        },
    });

    const handleChange = useCallback(
        attributeValue => {
            setPage(1);

            const plotFilterEnabled = filter.for_entity?.includes("plot");

            let updatedFilter = {...filter, ...attributeValue};
            if (!updatedFilter.for_entity?.length) {
                updatedFilter.for_entity = [];
            }

            if (plotFilterEnabled) {
                updatedFilter.for_entity.push("plot");
            }
            if (!updatedFilter.for_entity?.length) {
                delete updatedFilter.for_entity;
            }

            changeFilter(updatedFilter);
        },
        [filter]
    );

    const handleChangePlotSwitch = isSwitchOn => {
        setPage(1);

        let updatedFilter = {...filter};
        if (!updatedFilter.for_entity?.length) {
            updatedFilter.for_entity = [];
        }
        if (isSwitchOn) {
            updatedFilter.for_entity = [...updatedFilter.for_entity, "plot"];
        } else {
            updatedFilter.for_entity = updatedFilter.for_entity.filter(
                v => v !== "plot"
            );
        }
        if (!updatedFilter.for_entity?.length) {
            delete updatedFilter.for_entity;
        }

        changeFilter(updatedFilter);
    };

    const handleClearAllFilters = () => {
        formMethods.reset({
            type: "",
            for_entity: [],
            for_entity_plot: false,
        });
        if (onClear) {
            onClear();
        }
        if (Object.entries(filter).length > 0) resetFilter();
    };

    return (
        <FormProvider {...formMethods}>
            <Grid container columnSpacing={1} alignItems="center" mt={1}>
                <Grid item xs={3}>
                    <FormSelect
                        name="type"
                        label="Tipo titular"
                        options={holderTypeDomain}
                        onChangeHandler={option =>
                            handleChange({
                                type: option,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormSelectMultiple
                        name="for_entity"
                        label="Procedimento"
                        options={linkedEntitiesDomain}
                        onChangeHandler={option => {
                            handleChange({
                                for_entity: option,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={3} md={1.5}>
                    <FormSwitchInput
                        name="for_entity_plot"
                        label="Com parcela"
                        onChangeHandler={option => {
                            handleChangePlotSwitch(option.target.checked);
                        }}
                    />
                </Grid>

                <Grid item xs={0.5}>
                    <FormClearButtonSmall handleClear={handleClearAllFilters} />
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default HolderFilterForm;
