import {useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "base/file/components";

const ViewApprovedPermitDocumentsSubPage = () => {
    const context = useOutletContext();
    const permit = context[0];

    const basePath = "/permits/approved/list";

    return <ViewDocumentsSubPage entity={permit} basePath={basePath} />;
};

export default ViewApprovedPermitDocumentsSubPage;
