import {theme} from "Theme";
import {Tag} from "base/shared/components";
import {PermitTypeTags} from "permit/presentational";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ParkIcon from "@mui/icons-material/Park";

const TreeChip = ({element = null}) => {
    const titleTags = (
        <PermitTypeTags
            types={[element.felling && "felling", element.cutting && "cutting"]}
            justifyContent="unset"
            short
        />
    );

    return (
        <Tag
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                ml: 0,
                pr: 1.5,
                backgroundColor: "primary.light",
            }}
        >
            <Stack mr={1} color="primary.dark">
                <ParkIcon />
            </Stack>
            <div>
                <Typography
                    component="span"
                    fontSize={theme.typography.fontSize - 1}
                    color="white"
                >
                    {element.code}
                </Typography>
                <Typography lineHeight={1.25} color="white">
                    <strong>{element.species_label}</strong> | {element.quality}
                </Typography>
            </div>
            <Stack ml={1}>{titleTags}</Stack>
        </Tag>
    );
};

export default TreeChip;
