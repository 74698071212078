import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

import {useConfigModule} from "base/ui/module/provider";
import {LocationProvider} from "snimf/location/provider";
import {PermitApplicationService} from "permitapplication/service";

import {PageLayout} from "base/ui/main";
import {PermitApplicationSubPageMenu} from "permitapplication/menu";

const ViewPermitApplicationPage = () => {
    const {id} = useParams();

    const [permit, setPermit] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();

    const {setModuleBasePath, isLoading, setIsLoading} = useConfigModule();

    useEffect(() => {
        setIsLoading(true);
        setPermit(null);
        setModuleBasePath(`/applications/list/${id}`);
        PermitApplicationService.get(id)
            .then(data => {
                setPermit(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setError(error);
                setIsLoading(false);
            });
    }, [id, location.state?.lastRefreshDate]);

    return (
        <LocationProvider>
            <PageLayout
                menu={<PermitApplicationSubPageMenu permit={permit} />}
                context={[permit]}
                isLoading={isLoading}
                error={error}
                subPage
            />
        </LocationProvider>
    );
};

export default ViewPermitApplicationPage;
