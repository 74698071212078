import {ViewMaterialsTable} from "material/presentational";
import {ViewMaterialsContent} from ".";
import {Spinner} from "base/shared/components";
import Stack from "@mui/material/Stack";

const ViewMaterialsSection = ({
    permit,
    displayFees = true,
    hideNewButton = false,
    disabled = false,
}) => {
    return !permit ? (
        <Spinner key="spinner" />
    ) : (
        <Stack spacing={1}>
            <ViewMaterialsTable
                key="table"
                permitId={permit?.id}
                materials={permit.material_set}
                displayFees={displayFees}
                disabled={hideNewButton || disabled}
            />
            <ViewMaterialsContent
                key="details"
                permitId={permit?.id}
                materials={permit.material_set}
                displayFees={displayFees}
                disabled={disabled}
            />
        </Stack>
    );
};

export default ViewMaterialsSection;
