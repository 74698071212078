import {useState} from "react";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadPDFButton = ({
    handleGeneratePDF,
    text = "",
    color = "primary",
    disabled = false,
}) => {
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        handleGeneratePDF().then(result => {
            setLoading(false);
        });
    };

    const button = (
        <Button
            aria-label="download-PDF"
            onClick={handleClick}
            variant="contained"
            startIcon={<PrintOutlinedIcon />}
            disabled={disabled || loading}
            sx={{width: "fit-content"}}
            color={color}
        >
            {text || "PDF"}
            {loading && <CircularProgress size={22} sx={{ml: 1, mt: 1}} />}
        </Button>
    );

    return disabled ? button : <Tooltip title="Download em PDF">{button}</Tooltip>;
};

export default DownloadPDFButton;
