import {useState} from "react";

import {PlantationService} from "plantation/service";
import {plantation_view_adapter} from "plantation/model";
import {holder_view_adapter} from "holder/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {SectionCard, SectionCardHeaderAction} from "base/ui/section/components";
import {PlantationForm} from "plantation/presentational/form";
import {HolderBasicDataSection} from "holder/presentational";
import {
    HolderForm,
    HolderInitialDataFormFields,
    HolderFormSearch,
} from "holder/presentational/form";
import {FormContainer} from "base/form/components";
import {NoDataContainer} from "base/shared/components";
import {EntityAddButtonGroup} from "base/entity/components/presentational";
import EditIcon from "@mui/icons-material/Edit";

const ViewOrUpdatePlantationHolderContent = ({plantation, allowEdit = true}) => {
    const navigate = useNavigateWithReload();
    const holder = plantation.holder;

    const [selectedHolder, setSelectedHolder] = useState(null);
    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleSelectHolder = holder => {
        setSelectedHolder(holder);
    };

    const handleCreateFormSubmit = holder => {
        console.log("handleCreateFormSubmit", holder);
        PlantationService.createHolder(plantation.id, holder_view_adapter({...holder}))
            .then(createdHolder => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleEditFormSubmit = () => {
        PlantationService.update(
            plantation_view_adapter({...plantation, holder: selectedHolder})
        )
            .then(updatedPlantation => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const secondaryActions = holder?.id
        ? [
              <SectionCardHeaderAction
                  key="edit"
                  name="edit"
                  text="Modificar"
                  icon={<EditIcon />}
                  onClick={() => {
                      setMode("search");
                  }}
              />,
          ]
        : null;

    const getComponent = mode => {
        if (mode === "view") {
            return holder ? (
                <HolderBasicDataSection holder={holder} />
            ) : (
                <NoDataContainer
                    text="Este plantio ainda não tem requerente."
                    button={
                        allowEdit && (
                            <EntityAddButtonGroup
                                onAdd={() => setMode("new")}
                                onSearch={() => setMode("search")}
                            />
                        )
                    }
                />
            );
        }
        if (mode === "new") {
            return (
                <HolderForm
                    onSubmit={handleCreateFormSubmit}
                    onCancel={() => {
                        setMode("view");
                    }}
                    error={error}
                >
                    <FormContainer>
                        <HolderInitialDataFormFields />
                    </FormContainer>
                </HolderForm>
            );
        }
        if (mode === "search") {
            return (
                <FormContainer>
                    <PlantationForm
                        plantation={plantation}
                        onSubmit={handleEditFormSubmit}
                        onCancel={() => {
                            setMode("view");
                        }}
                        error={error}
                    >
                        <HolderFormSearch onClickSelected={handleSelectHolder} />
                    </PlantationForm>
                </FormContainer>
            );
        }
    };

    return (
        <SectionCard
            title="Requerente"
            secondaryActions={allowEdit ? secondaryActions : null}
        >
            {getComponent(mode)}
        </SectionCard>
    );
};

export default ViewOrUpdatePlantationHolderContent;
