const islandsUtil = {
    getIslandLabel(activeIsland) {
        if (activeIsland === "São Tomé e Príncipe") {
            return "STP";
        }
        if (activeIsland === "São Tomé") {
            return "ST";
        }
        if (activeIsland === "Príncipe") {
            return "RAP";
        }
        return "?";
    },
};

export default islandsUtil;
