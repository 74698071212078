import {SectionCard} from "base/ui/section/components";
import {PermitApplicationBillingFields} from "permitapplication/presentational/form";
import Grid from "@mui/material/Grid";

const ApprovedPermitBillingSection = ({permit}) => {
    return (
        <SectionCard title="Facturação">
            <Grid container mx={1}>
                <PermitApplicationBillingFields permit={permit} />
            </Grid>
        </SectionCard>
    );
};

export default ApprovedPermitBillingSection;
