import {useState} from "react";

import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";
import {holder_view_adapter} from "holder/model";

import {useNavigateWithReload} from "base/navigation/hooks";

import {EntityAddButtonGroup} from "base/entity/components/presentational";
import {NoDataContainer} from "base/shared/components";
import {PermitForm} from "./form";
import {
    HolderForm,
    HolderInitialDataFormFields,
    HolderFormSearch,
} from "holder/presentational/form";
import {HolderBasicDataSection} from "holder/presentational";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {FormContainer} from "base/form/components";

const PermitApplicationHolderSection = ({permit, isStepEditable}) => {
    const navigate = useNavigateWithReload();

    const holder = permit.holder;

    const [selectedHolder, setSelectedHolder] = useState(null);
    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleCreateFormSubmit = holder => {
        PermitApplicationService.createHolder(
            permit.id,
            holder_view_adapter({...holder})
        )
            .then(createdHolder => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleEditFormSubmit = () => {
        PermitApplicationService.update(
            permit_view_adapter({...permit, holder: selectedHolder})
        )
            .then(updatedPermit => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleSelectHolder = holder => {
        setSelectedHolder(holder);
    };

    const getComponent = mode => {
        if (mode === "view") {
            return holder ? (
                <Stack alignItems="center" spacing={1}>
                    <HolderBasicDataSection holder={holder} />
                    <Button
                        onClick={() => setMode("search")}
                        variant="outlined"
                        disabled={!isStepEditable}
                    >
                        Modificar
                    </Button>
                </Stack>
            ) : (
                <NoDataContainer
                    text="Esta autorização ainda não tem titular."
                    button={
                        <EntityAddButtonGroup
                            onAdd={() => setMode("new")}
                            onSearch={() => setMode("search")}
                            disabled={!isStepEditable}
                        />
                    }
                />
            );
        }
        if (mode === "new") {
            return (
                <FormContainer>
                    <HolderForm
                        holder={holder}
                        onSubmit={handleCreateFormSubmit}
                        onCancel={() => {
                            setMode("view");
                        }}
                        error={error}
                    >
                        <HolderInitialDataFormFields />
                    </HolderForm>
                </FormContainer>
            );
        }
        if (mode === "search") {
            return (
                <FormContainer>
                    <PermitForm
                        permit={permit}
                        onSubmit={handleEditFormSubmit}
                        error={error}
                        onCancel={() => {
                            setMode("view");
                        }}
                    >
                        <HolderFormSearch onClickSelected={handleSelectHolder} />
                    </PermitForm>
                </FormContainer>
            );
        }
    };

    return getComponent(mode);
};

export default PermitApplicationHolderSection;
