import Stack from "@mui/material/Stack";
import {MapBaseLayout, useMapDialog} from "base/map/layout";
import {useNavigateWithReload} from "base/navigation/hooks";
import {SectionCard} from "base/ui/section/components";
import {PlotMapProvider} from "plot/map";

const PlotMapSection = ({plot}) => {
    const {button, getDialog} = useMapDialog();
    const navigate = useNavigateWithReload();

    return (
        <SectionCard title="Mapa">
            <PlotMapProvider plot={plot}>
                <MapBaseLayout height="300px" rightBarOptions={{show: false}} />
            </PlotMapProvider>
            <Stack direction="row" justifyContent="center" sx={{mt: 3}}>
                {button}
            </Stack>
            {getDialog(
                <PlotMapProvider plot={plot}>
                    <MapBaseLayout height="100%" />
                </PlotMapProvider>,
                dirty => {
                    if (dirty) {
                        navigate("", true);
                    }
                }
            )}
        </SectionCard>
    );
};

export default PlotMapSection;
