import {useWatch} from "react-hook-form";
import {FileUploadField} from "base/file/components";
import Stack from "@mui/material/Stack";

const PermitApplicationDocFormFields = ({disabled = false}) => {
    const maxSize = 5000000;

    const is_the_plot_owner = useWatch({
        name: "is_the_plot_owner",
    });

    return (
        <Stack spacing={1}>
            <FileUploadField
                name="application_doc"
                label="Folha de solicitação preenchida e firmada pelo solicitante *"
                maxSize={maxSize}
                disabled={disabled}
            />
            <FileUploadField
                name="personal_id_doc"
                label="BI/Cartão de residência"
                subLabel="No caso de o solicitante ser um particular"
                maxSize={maxSize}
                disabled={disabled}
            />
            <FileUploadField
                name="tax_id_doc"
                label="Cartão Fiscal"
                subLabel="No caso de o solicitante ser uma empresa"
                maxSize={maxSize}
                disabled={disabled}
            />
            <FileUploadField
                name="plot_ownership_doc"
                label="Título de terras *"
                maxSize={maxSize}
                disabled={disabled}
            />
            {!is_the_plot_owner ? (
                <FileUploadField
                    name="plot_use_authorization"
                    label="Declaração de autorização de posse/aproveitamento de terra *"
                    subLabel="No caso de o proprietário cede a posse a outra pessoa"
                    maxSize={maxSize}
                    disabled={disabled}
                />
            ) : null}
        </Stack>
    );
};

export default PermitApplicationDocFormFields;
