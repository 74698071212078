import {useState} from "react";

import {PlotService} from "plot/service";
import {plot_view_adapter} from "plot/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {EntityCommentsSection} from "base/entity/components/presentational/sections";
import {SectionCard, SectionCardHeaderAction} from "base/ui/section/components";
import {FormContainer, FormCommentsField} from "base/form/components";
import {
    PlotForm,
    PlotGeneralDataFormFields,
    PlotLocationFormFields,
    PlotOwnershipDataFormFields,
} from "plot/presentational/form";
import {
    PlotGeneralDataSection,
    PlotLocationSection,
    PlotOwnershipDataSection,
} from "plot/presentational";

import EditIcon from "@mui/icons-material/Edit";

const ViewOrUpdatePlotContent = ({plot, section, label, allowEdit = true}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleFormSubmit = plot => {
        PlotService.update(plot_view_adapter({...plot}))
            .then(updatedPlot => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const getDataComponent = section => {
        if (section === "generaldata") {
            return <PlotGeneralDataSection plot={plot} />;
        }
        if (section === "ownership") {
            return <PlotOwnershipDataSection plot={plot} />;
        }
        if (section === "comments") {
            return <EntityCommentsSection comments={plot?.comments} />;
        }
        if (section === "location") {
            return <PlotLocationSection plot={plot} />;
        }
    };

    const getFormComponent = section => {
        if (section === "generaldata") {
            return <PlotGeneralDataFormFields />;
        }
        if (section === "ownership") {
            return <PlotOwnershipDataFormFields />;
        }
        if (section === "comments") {
            return <FormCommentsField placeholder="Observações" displayLabel={false} />;
        }
        if (section === "location") {
            return <PlotLocationFormFields />;
        }
    };

    const getComponent = mode => {
        if (mode === "view") {
            return getDataComponent(section);
        }
        if (mode === "edit") {
            return (
                <FormContainer>
                    <PlotForm
                        plot={plot}
                        onSubmit={handleFormSubmit}
                        onCancel={() => {
                            setMode("view");
                        }}
                        error={error}
                    >
                        {getFormComponent(section)}
                    </PlotForm>
                </FormContainer>
            );
        }
    };

    const actions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                setMode("edit");
            }}
        />,
    ];

    return (
        <SectionCard title={label} secondaryActions={allowEdit ? actions : null}>
            {getComponent(mode)}
        </SectionCard>
    );
};

export default ViewOrUpdatePlotContent;
