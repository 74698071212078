import {PERMIT_APPLICATION_STATUS} from "permitapplication/data";
import {usePermitApplicationProcessUrl} from "permitapplication/hooks";
import {DateUtil} from "base/format/utilities";
import {PaperContainer} from "base/ui/containers";
import {PermitTypeTags} from "permit/presentational";
import {TextLink} from "base/navigation/components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";

const PermitApplicationSummary = ({permit, basePath}) => {
    const {getProcessPath} = usePermitApplicationProcessUrl(permit, basePath);
    const statusPath = getProcessPath(permit, basePath);

    return (
        <PaperContainer
            sx={{
                width: "100%",
                flexWrap: "nowrap",
                py: 2,
                backgroundColor: "primary.lighter",
                color: "primary.dark",
            }}
        >
            <Grid item container xs={2.5}>
                <Typography variant="overline" mr={1}>
                    Data de solicitação:
                </Typography>
                <Typography variant="overline" fontWeight={500}>
                    {DateUtil.formatDate(permit?.application_date)}
                </Typography>
            </Grid>
            {permit?.holder?.name ? (
                <Grid item container xs pl={1}>
                    <Typography variant="overline" mr={1}>
                        Titular:
                    </Typography>
                    <Typography variant="overline" fontWeight={500}>
                        {permit?.holder?.name}
                    </Typography>
                </Grid>
            ) : null}
            <Grid item container xs flexWrap="nowrap" alignItems="center">
                <Divider orientation="vertical" variant="middle" flexItem />
                {permit?.status === PERMIT_APPLICATION_STATUS.not_approved ? (
                    <Typography
                        variant="overline"
                        fontWeight={500}
                        color="error.dark"
                        pl={2}
                    >
                        {permit?.status}
                    </Typography>
                ) : (
                    <Badge color="warning" variant="dot">
                        <TextLink
                            text={permit?.status}
                            to={statusPath}
                            textStyle={{
                                pl: 2,
                                fontSize: "0.75rem",
                                letterSpacing: "0.08333em",
                                textTransform: "uppercase",
                                fontWeight: "500",
                                color: "primary.dark",
                            }}
                        />
                    </Badge>
                )}
            </Grid>
            <Grid item container xs justifyContent="flex-end">
                {permit?.types?.length ? (
                    <PermitTypeTags types={permit?.types} />
                ) : null}
            </Grid>
        </PaperContainer>
    );
};

export default PermitApplicationSummary;
