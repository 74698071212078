import {useDomain} from "snimf/domain/provider";
import {useAdministrativeDivisions} from "snimf/location/provider";
import {FormInputText, FormSelect} from "base/form/components";
import {HolderFormSearch} from "holder/presentational/form";
import Grid from "@mui/material/Grid";

const PlotInitialDataFormFields = () => {
    const {landOwnershipDomain} = useDomain();
    const {userDistricts} = useAdministrativeDivisions();
    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormInputText name="number" label="Número" />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="land_ownership"
                    label="Propriedade da terra"
                    options={landOwnershipDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="district"
                    label="Distrito"
                    options={userDistricts}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="locality"
                    label="Localidade"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>

            <Grid item xs={12}>
                <HolderFormSearch
                    label="Proprietário/a"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
        </Grid>
    );
};

export default PlotInitialDataFormFields;
