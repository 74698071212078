import {
    createContact,
    createContacts,
    contact_api_adapter,
    contacts_api_adapter,
} from "contact/model";
import {createEntityService} from "base/entity/service";

const basePath = "/api/app/contacts";

const entityService = createEntityService(
    basePath,
    createContact,
    createContacts,
    contact_api_adapter,
    contacts_api_adapter
);

const ContactService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(contact) {
        return entityService.create(contact);
    },

    update(contact) {
        return entityService.update(contact);
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default ContactService;
