import {DateUtil} from "base/format/utilities";
import {PERMIT_APPLICATION_STATUS} from "permitapplication/data";
import {TextLink} from "base/navigation/components";
import {PermitTypeTags} from "permit/presentational";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export function usePermitsSummaryTable() {
    const tableColumns = [
        {
            id: "code",
            label: "Código",
            width: 20,
            formatFunction: item => {
                const statusPath =
                    item.status === PERMIT_APPLICATION_STATUS.approved
                        ? "approved"
                        : "applications";

                return (
                    <TextLink
                        text={item?.code}
                        to={`/permits/${statusPath}/list/${item.id}/summary`}
                        textStyle={{fontSize: "inherit"}}
                    />
                );
            },
        },
        {
            id: "on_date",
            label: "Data aprovação",
            width: 20,
            formatFunction: item => DateUtil.formatDate(item.on_date),
        },
        {
            id: "district",
            label: "Distrito",
            width: 20,
        },
        {
            id: "is_valid",
            label: "Em vigor",
            width: 20,
            formatFunction: item =>
                item.is_valid ? (
                    <CheckCircleOutlinedIcon color="success" />
                ) : (
                    <CancelOutlinedIcon color="action" />
                ),
        },
        {
            id: "types",
            label: "Guias",
            width: 20,
            formatFunction: item => {
                return <PermitTypeTags types={item?.types} />;
            },
            disableSorting: true,
        },
    ];

    return {tableColumns};
}
