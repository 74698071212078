import {Navigate, Route} from "react-router-dom";
import {permitApplicationRoutes} from "permitapplication/module";
import {
    ViewApprovedPermitBillingSubPage,
    ViewApprovedPermitDocumentsSubPage,
    ViewApprovedPermitDocumentPanel,
    ViewApprovedPermitInspectionSubPage,
    ListApprovedPermitsPage,
    ViewApprovedPermitPlotSubPage,
    ViewApprovedPermitSummarySubPage,
    ViewApprovedPermitTypesCutting,
    ViewApprovedPermitTypesClearing,
    ViewApprovedPermitTypesFelling,
    ViewApprovedPermitTypesOverview,
    ViewApprovedPermitTypesSubPage,
    ViewApprovedPermitTypesTransport,
    ViewApprovedPermitPage,
    ViewApprovedPermitHolderSubPage,
} from "permit/container";

const permitRoutes = [
    permitApplicationRoutes,
    <Route key="permit-approved-manage" path="approved/list">
        <Route
            key="permit-approved-list"
            path=""
            element={<ListApprovedPermitsPage />}
        />
        <Route
            key="permit-approved-detail"
            path=":id"
            element={<ViewApprovedPermitPage />}
        >
            <Route
                key="permit-approved-summary"
                path="summary"
                element={<ViewApprovedPermitSummarySubPage />}
            />
            <Route
                key="permit-approved-holder"
                path="holder"
                element={<ViewApprovedPermitHolderSubPage />}
            />
            <Route
                key="permit-approved-plot"
                path="plot"
                element={<ViewApprovedPermitPlotSubPage />}
            />
            <Route
                key="permit-approved-inspection"
                path="inspection"
                element={<ViewApprovedPermitInspectionSubPage />}
            />
            <Route
                key="permit-approved-types"
                path="types"
                element={<ViewApprovedPermitTypesSubPage />}
            >
                <Route
                    key="permit-approved-types-overview"
                    path="overview"
                    element={<ViewApprovedPermitTypesOverview />}
                />
                <Route
                    key="permit-approved-types-felling"
                    path="felling"
                    element={<ViewApprovedPermitTypesFelling />}
                />
                <Route
                    key="permit-approved-types-cutting"
                    path="cutting"
                    element={<ViewApprovedPermitTypesCutting />}
                />
                <Route
                    key="permit-approved-types-clearing"
                    path="clearing"
                    element={<ViewApprovedPermitTypesClearing />}
                />

                <Route
                    key="permit-approved-types-transport"
                    path="transport"
                    element={<ViewApprovedPermitTypesTransport />}
                />
            </Route>
            <Route
                key="permit-approved-billing"
                path="billing"
                element={<ViewApprovedPermitBillingSubPage />}
            />
            {/* <Route
                key="permit-approved-violations"
                path="violations"
                element={<ViewApprovedPermitViolationsSubPage />}
            /> */}
            <Route
                key="permit-approved-documents"
                path="documents/*"
                element={<ViewApprovedPermitDocumentsSubPage />}
            >
                <Route
                    key="permit-approved-documents-view"
                    path="detail/*"
                    element={<ViewApprovedPermitDocumentPanel />}
                />
            </Route>
            <Route index element={<Navigate to="summary" replace />} />
        </Route>
    </Route>,
    <Route
        key="redirect-permit-index"
        index
        element={<Navigate to="list" replace />}
    />,
];

export default permitRoutes;
