import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";

const ToggleFilterAccordionButton = ({
    onClick,
    isExpanded,
    buttonText = "Mais filtros",
}) => {
    const filterButtonStyle = {
        textAlign: "left",
        lineHeight: 1.25,
        color: "text.secondary",
        marginBottom: 0.5,
    };

    return (
        <Button onClick={onClick} startIcon={<FilterListIcon />} sx={filterButtonStyle}>
            {!isExpanded ? buttonText : "Ocultar filtros"}
        </Button>
    );
};

export default ToggleFilterAccordionButton;
