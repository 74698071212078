import {ViewOrUpdatePlantDataContent} from "plant/container";
import Stack from "@mui/system/Stack";

const ViewPlantationPlantsContent = ({plantationId, plants, allowEdit = true}) => {
    return plants.length ? (
        <Stack spacing={1}>
            {plants.map(plant => (
                <div key={plant.code} id={`${plant.code}`}>
                    <ViewOrUpdatePlantDataContent
                        plantationId={plantationId}
                        plant={plant}
                        allowEdit={allowEdit}
                    />
                </div>
            ))}
        </Stack>
    ) : null;
};

export default ViewPlantationPlantsContent;
