import {usePermitApplicationProcess} from "./PermitApplicationProcessHook";
import {usePermitApplicationSteps} from "./PermitApplicationStepsHook";

const usePermitApplicationProcessUrl = (permit, basePath) => {
    const {isStepEditable} = usePermitApplicationProcess(permit);

    const {steps: applicationSteps} = usePermitApplicationSteps(
        permit,
        isStepEditable,
        "application"
    );
    const {steps: paymentSteps} = usePermitApplicationSteps(
        permit,
        isStepEditable,
        "payment"
    );

    const getStatusProcess = (processSteps, permitStatus) => {
        return processSteps.some(item =>
            Object.values(item).some(value => value.toString().includes(permitStatus))
        );
    };

    const getPathForProcess = (processSteps, defaultPath) => {
        const hasStatusInProcess = getStatusProcess(processSteps, permit?.status);
        const isApplicationPath = defaultPath === "application";

        if (hasStatusInProcess) {
            const path = findPathInSteps(processSteps, permit);
            return `${basePath}/${defaultPath}/${path}`;
        } else {
            if (isApplicationPath) {
                const lastApplicationPath =
                    processSteps.slice(-1)[0]?.path || defaultPath;
                return `${basePath}/${defaultPath}/${lastApplicationPath}`;
            } else {
                const firstPaymentPath = processSteps?.[0]?.path || defaultPath;
                return `${basePath}/${defaultPath}/${firstPaymentPath}`;
            }
        }
    };

    const getApplicationProcessPath = () => {
        return getPathForProcess(applicationSteps, "application");
    };

    const getPaymentProcessPath = () => {
        return getPathForProcess(paymentSteps, "payment");
    };

    const findPathInSteps = (processSteps, updatedPermit) => {
        return (
            processSteps.find(item => item.status === updatedPermit?.status)?.path ||
            updatedPermit?.status
        );
    };

    const getProcessPath = (updatedPermit, basePath) => {
        const isInApplicationSteps = getStatusProcess(
            applicationSteps,
            updatedPermit?.status
        );
        const isInPaymentSteps = getStatusProcess(paymentSteps, updatedPermit?.status);

        if (isInApplicationSteps) {
            const path = findPathInSteps(applicationSteps, updatedPermit);
            return `${basePath}/application/${path}`;
        } else if (isInPaymentSteps) {
            const path = findPathInSteps(paymentSteps, updatedPermit);
            return `${basePath}/payment/${path}`;
        }
    };

    return {
        getApplicationProcessPath,
        getPaymentProcessPath,
        getProcessPath,
        findPathInSteps,
    };
};

export {usePermitApplicationProcessUrl};
