import {useDomain} from "snimf/domain/provider";
import {useHolderFormFields} from "holder/hooks";

import {FormInputText, FormSelect} from "base/form/components";
import Grid from "@mui/material/Grid";

const HolderGeneralDataFormFields = () => {
    const {holderTypeDomain, genderDomain} = useDomain();
    const {genderLabel, isBusiness} = useHolderFormFields();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormInputText
                    name="name"
                    label="Nome"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="type"
                    label="Tipo"
                    options={holderTypeDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="address"
                    label="Morada"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="gender"
                    label={genderLabel}
                    options={genderDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            {isBusiness ? (
                <Grid item xs={6}>
                    <FormInputText name="representative" label="Representante" />
                </Grid>
            ) : null}
        </Grid>
    );
};

export default HolderGeneralDataFormFields;
