import {useState, createContext, useContext} from "react";
import {useNavigateWithReload} from "base/navigation/hooks";

import {AuthService} from "base/api/service";
import {ROLES} from "../config";

let AuthContext = createContext(null);

export default function AuthProvider({children}) {
    const navigate = useNavigateWithReload();

    const [user, setUser] = useState(() => {
        return AuthService.getUsername();
    });

    const login = (username, password) => {
        return AuthService.login(username, password).then(userData => {
            console.log(userData);
            setUser(AuthService.getUsername());
        });
    };

    const logout = () => {
        return AuthService.logout().then(() => {
            setUser(null);
        });
    };

    const hasRole = role => {
        if (user?.is_superuser) {
            return true;
        }
        return user?.roles?.includes(role);
    };

    const hasPermission = (permission, context = null) => {
        // Comprueba si está seleccionada la isla del usuario
        const hasIslandAccess = user.active_islands.includes(user.island);

        // El admin tiene todos los permisos
        if (hasRole("Gestão de SNIMF")) return true;

        // BORRAR - Solo el admin puede borrar entidades.
        if (permission.startsWith("delete-")) {
            return false;
        }

        // Todo el mundo puede crear|modificar las entidades Titulares|Parcelas|Infracciones
        if (
            // permission.includes("holders") ||
            permission.includes("plots") ||
            permission.includes("violations")
        ) {
            if (context) {
                return context.island === user.island;
            } else return hasIslandAccess;
        }

        // Como la entidad Titular no tiene campo distrito ni isla, de momento permitimos que cualquiera pueda editar/crear
        if (permission.includes("holders")) {
            return true;
        }

        // Solo el departamento SSE puede crear|modificar Plantaciones
        if (permission.includes("plantations")) {
            if (context) {
                return context.island === user.island && hasRole("SSE");
            } else return hasIslandAccess && hasRole("SSE");
        }

        // Solo el departamento S puede crear Procesos de Autorización
        if (["create-permitapplications"].includes(permission)) {
            return hasIslandAccess && hasRole("S");
        }
        // Solo el departamento correspondiente puede editar su parte del Proceso de Autorización
        if (permission === "edit-permitapplications") {
            if (!context || !context.status || !context.status.includes("(")) {
                return false;
            }
            const role = context.status.split("(")[1].replace(")", "");
            return context.island === user.island && hasRole(role);
        }

        throw Error(`El permiso no existe: ${permission}`);
    };

    const activeIslandsSwitch = () => {
        if (user.active_islands.length == 2) {
            return "São Tomé e Príncipe";
        }
        if (user.active_islands.includes("São Tomé")) {
            return "São Tomé";
        }
        if (user.active_islands.includes("Príncipe")) {
            return "Príncipe";
        }
    };

    const toggleIsland = (event, value) => {
        let active_islands;
        if (value === "São Tomé") {
            active_islands = ["São Tomé"];
        } else if (value === "Príncipe") {
            active_islands = ["Príncipe"];
        } else {
            active_islands = ["São Tomé", "Príncipe"];
        }

        AuthService.toggleIsland(active_islands).then(() => navigate(null, true));
        setUser({...user, active_islands: active_islands});
    };

    let value = {
        user,
        login,
        logout,
        hasRole,
        ROLES,
        activeIslandsSwitch,
        toggleIsland,
        hasPermission,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
    return useContext(AuthContext);
}

export {useAuth};
