import {ListProvider} from "base/entity/provider";
import {ModuleLayout} from "base/ui/module/components";
import {ModuleConfigProvider} from "base/ui/module/provider";
import {PermitPageMenu} from "permit/menu";
import {DomainProvider} from "snimf/domain/provider";

const PermitsModule = () => {
    return (
        <ModuleConfigProvider>
            <DomainProvider>
                <ListProvider>
                    <ModuleLayout menu={<PermitPageMenu />} />
                </ListProvider>
            </DomainProvider>
        </ModuleConfigProvider>
    );
};

export default PermitsModule;
