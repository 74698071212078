import {AuthApiService} from "base/api/service";
import {
    createViolation,
    createViolations,
    violation_api_adapter,
    violations_api_adapter,
} from "violation/model";
import {createEntityService} from "base/entity/service";
import {createHolder, holder_api_adapter} from "holder/model";
import {createPlot, plot_api_adapter} from "plot/model";

const basePath = "/api/app/violations";

const entityService = createEntityService(
    basePath,
    createViolation,
    createViolations,
    violation_api_adapter,
    violations_api_adapter
);

const Violationservice = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(violation) {
        return entityService.create(violation);
    },

    update(violation) {
        return entityService.update(violation);
    },

    createHolder(violationId, holder) {
        return AuthApiService.post(`${basePath}/${violationId}/holders/`, holder).then(
            response => createHolder(holder_api_adapter(response))
        );
    },

    createPlot(violationId, plot) {
        return AuthApiService.post(`${basePath}/${violationId}/plots/`, plot).then(
            response => createPlot(plot_api_adapter(response))
        );
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default Violationservice;
