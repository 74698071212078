import {useDomain} from "snimf/domain/provider";
import {
    FormDatePicker,
    FormInputInteger,
    FormInputText,
    FormSelect,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const PlantDataFormFields = () => {
    const {speciesDomain} = useDomain();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormSelect
                    name="species"
                    label="Espécie (nome vulgar)"
                    options={speciesDomain}
                    rules={{required: "Este campo é obrigatório"}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormDatePicker name="sowing_date" label="Data de plantio da semente" />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="scientific_name"
                    label="Nome científico"
                    maxLength={100}
                />
            </Grid>
            <Grid item xs={6}>
                <FormDatePicker name="germination_date" label="Data de germinação" />
            </Grid>
            <Grid item xs={6}>
                <FormInputInteger
                    name="quantity"
                    label="Quantidade de plantas"
                    rules={{required: "Este campo é obrigatório"}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormDatePicker name="transplant_date" label="Data de transplante" />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="source" label="Viveiro de origem" />
            </Grid>
        </Grid>
    );
};

export default PlantDataFormFields;
