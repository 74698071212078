import {useAuth} from "base/user/provider";
import {islandsUtil} from "snimf/location/utilities";

import {AppMenu} from "base/ui/app/components";
import {AccountMenu} from "base/user/components";
import {ActiveIslandSelector, SnimfHelp} from ".";
import MenuItem from "@mui/material/MenuItem";

const SnimfMenu = () => {
    const {user} = useAuth();

    const userIsland = islandsUtil.getIslandLabel(user.island);
    const userRoles = user.roles.join(", ");

    const menuItems = [
        {
            name: "Autorizações",
            to: "permits/applications/list",
            pathname: "permits",
        },
        {
            name: "Infracções",
            to: "violations/all/list",
            pathname: "violations",
        },
        {
            name: "Plantios",
            to: "plantations/all/list",
            pathname: "plantations",
        },
    ];

    const menuSecondaryItems = [
        {
            name: "Titulares",
            to: "holders/all/list",
            pathname: "holders",
        },
        {
            name: "Parcelas",
            to: "plots/all/list",
            pathname: "plots",
        },
    ];

    return (
        <AppMenu menuItems={menuItems} menuSecondaryItems={menuSecondaryItems}>
            <MenuItem sx={{marginLeft: "auto"}}>
                <ActiveIslandSelector />
                <AccountMenu secondaryText={`${userRoles} • ${userIsland}`} />
                <SnimfHelp />
            </MenuItem>
        </AppMenu>
    );
};

export default SnimfMenu;
