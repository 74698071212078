import {Navigate, Route} from "react-router-dom";
import {
    ListPermitApplicationsPage,
    ViewPermitApplicationPage,
    ViewPermitApplicationSummarySubPage,
    ViewPermitApplicationProcessSubPage,
    ViewPermitPaymentProcessSubPage,
    ViewPermitApplicationDocumentsSubPage,
} from "permitapplication/container";
import {
    PermitApplicationDocReviewStep,
    PermitApplicationFeesStep,
    PermitApplicationHolderStep,
    PermitApplicationInspectionStep,
    PermitApplicationInvoiceStep,
    PermitApplicationPaymentStep,
    PermitApplicationPlotStep,
    PermitApplicationReportStep,
    PermitApplicationSignatureStep,
    PermitApplicationTechnicalDataStep,
    PermitApplicationTypesAndDocStep,
} from "permitapplication/presentational";
import {ViewDocumentPanel} from "base/file/components";

const permitApplicationRoutes = [
    <Route key="permit-application-manage" path="applications/list">
        <Route
            key="permit-application-list"
            path=""
            element={<ListPermitApplicationsPage />}
        ></Route>
        <Route
            key="permit-application-detail"
            path=":id"
            element={<ViewPermitApplicationPage />}
        >
            <Route
                key="permit-application-summary"
                path="summary"
                element={<ViewPermitApplicationSummarySubPage />}
            />
            <Route
                key="permit-application-process"
                path="application"
                element={<ViewPermitApplicationProcessSubPage />}
            >
                <Route
                    key="permit-application-process-holder_s"
                    path={"holder_s"}
                    element={<PermitApplicationHolderStep />}
                />
                <Route
                    key="permit-application-process-permit_s"
                    path="permit_s"
                    element={<PermitApplicationTypesAndDocStep />}
                />
                <Route
                    key="permit-application-process-analysis_m"
                    path="analysis_m"
                    element={<PermitApplicationDocReviewStep />}
                />
                <Route
                    key="permit-application-process-plot1_m"
                    path="plot1_m"
                    element={<PermitApplicationPlotStep />}
                />
                <Route
                    key="permit-application-process-visit_m"
                    path="visit_m"
                    element={<PermitApplicationInspectionStep />}
                />
                <Route
                    key="permit-application-process-permit_m"
                    path="permit_m"
                    element={<PermitApplicationTechnicalDataStep />}
                />
                <Route
                    key="permit-application-process-report_m"
                    path="report_m"
                    element={<PermitApplicationReportStep />}
                />
            </Route>
            <Route
                key="permit-payment-process"
                path="payment"
                element={<ViewPermitPaymentProcessSubPage />}
            >
                <Route
                    key="permit-payment-process-payment_e"
                    path="payment_e"
                    element={<PermitApplicationFeesStep />}
                />
                <Route
                    key="permit-payment-process-invoice_s"
                    path="invoice_s"
                    element={<PermitApplicationInvoiceStep />}
                />
                <Route
                    key="permit-payment-process-payment_s"
                    path="payment_s"
                    element={<PermitApplicationPaymentStep />}
                />
                <Route
                    key="permit-payment-process-signature_s"
                    path="signature_s"
                    element={<PermitApplicationSignatureStep />}
                />
            </Route>
            <Route
                key="permit-application-documents"
                path="documents/*"
                element={<ViewPermitApplicationDocumentsSubPage />}
            >
                <Route
                    key="permit-application-documents-view"
                    path="detail/*"
                    element={<ViewDocumentPanel />}
                />
            </Route>
            <Route index element={<Navigate to="summary" replace />} />
        </Route>
    </Route>,
];

export default permitApplicationRoutes;
