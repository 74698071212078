import {useMapLayerConfig} from "base/map/layer";
import {useGeojsonLayer} from "base/map/leaflet/layer";
import {createLayerLegend, createWMSLegendIcon} from "base/map/legend";
import {TreeService} from "tree/service";

const popup = feature => {
    let data = feature.properties;
    let popupContent = `<b>Árvore: ${data["code"] ? data["code"] : "---"}</b>`;
    popupContent += "<ul class='attributes'>";
    popupContent += `<li><i>Especie/a</i>: ${data["species"] || "-"}</li>`;
    popupContent += "</ul>";
    return popupContent;
};

export function createTreeLayer() {
    return useGeojsonLayer({
        type: "point",
        style: {
            type: "point",
            color: "#318131",
            fillColor: "#99520fcc",
            weight: 4,
        },
        popup: popup,
        pane: 9,
    });
}

export function createTreeLegend({label = null}) {
    return createLayerLegend({
        code: "tree",
        label: label || "Árvores",
        icon: createWMSLegendIcon({
            type: "point",
        }),
    });
}

export function creatPermitTreeLayerConfig(permit) {
    const treeLayer = createTreeLayer();
    const treeLegend = createTreeLegend({label: "Árvores da parcela"});

    return useMapLayerConfig({
        load: filter => {
            return TreeService.getFeatures({
                ...filter,
                permit_id: permit.id,
            });
        },
        layer: treeLayer,
        legend: treeLegend,
    });
}
