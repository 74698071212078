import {useState} from "react";

import {PermitApplicationService} from "permitapplication/service";
import {useNavigateWithReload} from "base/navigation/hooks";

import {MaterialForm} from "material/presentational/form";
import {AddNewInlineItemButton, AddNewInlineItemFormBox} from "base/shared/components";

const CreateMaterialDataContent = ({permitId, displayFees}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("button");
    const [error, setError] = useState(null);

    const handleFormSubmit = material => {
        PermitApplicationService.createMaterial(permitId, material)
            .then(createdMaterial => {
                setMode("button");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    return mode === "button" ? (
        <AddNewInlineItemButton
            onClick={() => {
                setMode("create");
            }}
            label="Acrescentar material"
        />
    ) : mode === "create" ? (
        <AddNewInlineItemFormBox label="Acrescentar material">
            <MaterialForm
                permitId={permitId}
                displayFees={displayFees}
                onSubmit={handleFormSubmit}
                onCancel={() => {
                    setMode("button");
                }}
                error={error}
            />
        </AddNewInlineItemFormBox>
    ) : null;
};

export default CreateMaterialDataContent;
