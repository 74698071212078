import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import {ptBR} from "@mui/x-date-pickers/locales";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt";

import {theme} from "Theme";
import {SnimfRoutes} from "snimf/routes";

// This is needed to translate date picker placeholders.
// MUI Date Pickers only supports Brazilian Portuguese for now
const customLocale = ptBR.components.MuiLocalizationProvider.defaultProps.localeText;

function App() {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptLocale}
                localeText={customLocale}
            >
                <CssBaseline>
                    <div className="App" role="application">
                        <SnimfRoutes />
                    </div>
                </CssBaseline>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
