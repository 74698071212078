import {useOutletContext} from "react-router-dom";
import {useEntityPermissions} from "snimf/permissions/hooks";
import {EntityViewSubPage} from "base/entity/components/container";
import {ViewOrUpdateViolationPlotContent} from ".";

const ViewViolationPlotSubPage = () => {
    const context = useOutletContext();
    const violation = context[0];

    const {allowEdit} = useEntityPermissions(violation, "violations");

    let sections = [
        <ViewOrUpdateViolationPlotContent
            key="plot"
            violation={violation}
            allowEdit={allowEdit}
        />,
    ];

    return violation && <EntityViewSubPage sections={sections} />;
};

export default ViewViolationPlotSubPage;
