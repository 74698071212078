import {useDomain} from "snimf/domain/provider";
import {useViolationActionsForm} from "violation/hooks";
import {CURRENCY_SYMBOL} from "base/format/config/i18n";

import {
    FormCheckbox,
    FormInputInteger,
    FormInputText,
    FormSection,
    FormSelect,
    FormSelectMultiple,
} from "base/form/components";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const ViolationActionsFormFields = () => {
    const {violationDestinationDomain, violationActionTakenDomain} = useDomain();
    const {formValues, shouldDisplay} = useViolationActionsForm();

    const confiscationFields = (
        <FormSection title="Confiscação">
            <Grid item xs={6}>
                <FormInputText name="forest_material" label="Material florestal" />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="forest_material_destination"
                    label="Destino do material florestal"
                    options={violationDestinationDomain}
                    disabled={!formValues.forestMaterial}
                    showEmptyOption
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="chainsaw" label="Motoserra" />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="chainsaw_destination"
                    label="Destino da motoserra"
                    options={violationDestinationDomain}
                    disabled={!formValues.chainsaw}
                    showEmptyOption
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="vehicle" label="Veículo" />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="vehicle_destination"
                    label="Destino do veículo"
                    options={violationDestinationDomain}
                    disabled={!formValues.vehicle}
                    showEmptyOption
                />
            </Grid>
            {shouldDisplay.saleFields ? (
                <>
                    <Grid item xs={12}>
                        <Divider sx={{my: 2}} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormInputInteger
                            name="sale_amount"
                            label="Quantia da venda dos materiais"
                            endAdornment={CURRENCY_SYMBOL}
                        />
                        <FormCheckbox
                            name="sale_receipt"
                            label="Comprovativo de depósito"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormInputText name="sale_buyer" label="Nome do comprador" />
                    </Grid>
                </>
            ) : null}
        </FormSection>
    );

    const penaltyFields = (
        <FormSection title="Multa">
            <Grid item xs={6}>
                <FormInputInteger
                    name="penalty_amount"
                    label="Quantia da multa"
                    endAdornment={CURRENCY_SYMBOL}
                />
            </Grid>
            <Grid item container xs={6} alignContent="center">
                <FormCheckbox name="penalty_paid" label="O infractor pagou a multa" />
                <FormCheckbox
                    name="penalty_receipt"
                    label="Comprovativo de depósito"
                    disabled={!formValues.isPenaltyPaid}
                />
            </Grid>
        </FormSection>
    );

    return (
        <Stack spacing={1}>
            <Stack pt={1}>
                <FormSelectMultiple
                    name="actions"
                    label="Acções"
                    options={violationActionTakenDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Stack>
            {shouldDisplay.confiscationFields && confiscationFields}
            {shouldDisplay.penaltyFields && penaltyFields}
        </Stack>
    );
};

export default ViolationActionsFormFields;
