import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {ViolationService} from "violation/service";
import {violation_view_adapter} from "violation/model";
import {ViolationForm, ViolationInitialFormFields} from "violation/presentational/form";
import {FormSection} from "base/form/components";

const CreateViolationForm = ({onCancel = null}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];

    const [error, setError] = useState("");

    const handleFormSubmit = violation => {
        ViolationService.create(violation_view_adapter({...violation}))
            .then(createdViolation => {
                navigate(`${basePath}/${createdViolation.id}/summary`);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleFormCancel = () => {
        onCancel ? onCancel() : navigate(`${basePath}/list`);
    };

    return (
        <ViolationForm
            onSubmit={handleFormSubmit}
            onCancel={handleFormCancel}
            error={error}
        >
            <FormSection>
                <ViolationInitialFormFields />
            </FormSection>
        </ViolationForm>
    );
};

export default CreateViolationForm;
