import {useDomain} from "snimf/domain/provider";

import {useTreeFormFields} from "tree/hooks";
import {
    FormCheckboxGroup,
    FormInputDecimal,
    FormInputInteger,
    FormInputText,
    FormSelect,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const TreeDataInitialFormFields = ({}) => {
    const {speciesDomain} = useDomain();
    const {cutting, permitTypeOptions} = useTreeFormFields();

    return (
        <Grid container direction="row" columnSpacing={1}>
            <Grid item xs={6}>
                <FormSelect
                    name="species"
                    label="Espécie"
                    options={speciesDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="quality" label="Qualidade" disabled />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="height"
                    label="Altura"
                    endAdornment="m"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="diameter"
                    label="Diámetro AP"
                    endAdornment="m"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="volume"
                    label="Volume"
                    endAdornment="m³"
                    disabled
                />
            </Grid>
            {cutting ? (
                <Grid item xs={6}>
                    <FormInputInteger
                        name="number_of_logs"
                        label="Nº de toros"
                        rules={{required: "Este campo é obrigatório."}}
                    />
                </Grid>
            ) : (
                <Grid item xs={6} aria-hidden="true"></Grid>
            )}
            <Grid item xs={6}>
                <FormCheckboxGroup options={permitTypeOptions} required />
            </Grid>
        </Grid>
    );
};

export default TreeDataInitialFormFields;
