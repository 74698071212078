import {FieldUtil} from "base/ui/section/utilities";
import {NumberUtil} from "base/format/utilities";
import Grid from "@mui/material/Grid";

const TreeDataFieldsSummarized = ({tree}) => {
    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Altura",
                    NumberUtil.formatDecimal(tree.height),
                    "m"
                )}
                {FieldUtil.getSectionField(
                    "Volume",
                    NumberUtil.formatDecimal(tree.volume),
                    "m³"
                )}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Diámetro AP",
                    NumberUtil.formatDecimal(tree.diameter),
                    "m"
                )}
                {tree.cutting
                    ? FieldUtil.getSectionField("Nº de toros", tree.number_of_logs)
                    : null}
            </Grid>
        </Grid>
    );
};

export default TreeDataFieldsSummarized;
