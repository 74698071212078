import {DateUtil} from "base/format/utilities";
import {PermitService} from "permit/service";
import {SearchAutocomplete} from "base/search/components";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const PermitSearchAutocomplete = () => {
    const optionComponent = option => {
        return (
            <Stack>
                <Typography>{option.code}</Typography>
                <Typography variant="caption" sx={{ml: 1}}>
                    {option.on_date ? DateUtil.formatDate(option.on_date) : ""}
                </Typography>
            </Stack>
        );
    };

    return (
        <SearchAutocomplete
            name="permit"
            label="Autorização"
            optionLabel="code"
            optionComponent={optionComponent}
            search={PermitService.getBySearchText}
        />
    );
};

export default PermitSearchAutocomplete;
