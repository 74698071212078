import {useState} from "react";

import {ViolationService} from "violation/service";
import {violation_view_adapter} from "violation/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {EntityCommentsSection} from "base/entity/components/presentational/sections";
import {SectionCard, SectionCardHeaderAction} from "base/ui/section/components";
import {FormContainer, FormCommentsField} from "base/form/components";
import {
    ViolationForm,
    ViolationGeneralDataFormFields,
    ViolationTeamFormFields,
} from "violation/presentational/form";
import {
    ViolationGeneralDataSection,
    ViolationTeamSection,
} from "violation/presentational";

import EditIcon from "@mui/icons-material/Edit";

const ViewOrUpdateViolationContent = ({
    violation,
    section,
    label,
    allowEdit = true,
}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleFormSubmit = violation => {
        ViolationService.update(violation_view_adapter({...violation}))
            .then(updatedViolation => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const getDataComponent = section => {
        if (section === "generaldata") {
            return <ViolationGeneralDataSection violation={violation} />;
        }
        if (section === "team") {
            return <ViolationTeamSection violation={violation} />;
        }
        if (section === "comments") {
            return <EntityCommentsSection comments={violation?.comments} />;
        }
    };

    const getFormComponent = section => {
        if (section === "generaldata") {
            return <ViolationGeneralDataFormFields />;
        }
        if (section === "team") {
            return <ViolationTeamFormFields />;
        }
        if (section === "comments") {
            return <FormCommentsField placeholder="Observações" displayLabel={false} />;
        }
    };

    const getComponent = mode => {
        if (mode === "view") {
            return getDataComponent(section);
        }
        if (mode === "edit") {
            return (
                <FormContainer>
                    <ViolationForm
                        violation={violation}
                        onSubmit={handleFormSubmit}
                        onCancel={() => {
                            setMode("view");
                        }}
                        error={error}
                    >
                        {getFormComponent(section)}
                    </ViolationForm>
                </FormContainer>
            );
        }
    };

    const actions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                setMode("edit");
            }}
        />,
    ];

    return (
        <SectionCard title={label} secondaryActions={allowEdit ? actions : null}>
            {getComponent(mode)}
        </SectionCard>
    );
};

export default ViewOrUpdateViolationContent;
