import Typography from "@mui/material/Typography";

const SectionLabel = ({label}) => {
    return (
        <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{
                lineHeight: {xs: 1.5, sm: 1.25},
                hyphens: "auto",
            }}
        >
            {label}:
        </Typography>
    );
};

export default SectionLabel;
