import {EntityChangeView, EntityCounter} from "base/entity/components/presentational";
import {EntityFilterForm} from "base/entity/components/form";
import {ToggleFilterAccordionButton} from "base/shared/components";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

const EntityListPageHeader = ({
    views = ["table", "map"],
    entityName,
    filter,
    size,
    isLoading,
    createButton = null,
    onClickToggleAccordion = null,
    isAccordionExpanded,
}) => {
    return (
        filter && (
            <Grid item container columnSpacing={2} justifyContent="space-between">
                <Grid item xs={4} role="form" component="form">
                    <EntityFilterForm />
                </Grid>
                {onClickToggleAccordion ? (
                    <Grid item xs={2} container>
                        <Stack>
                            <ToggleFilterAccordionButton
                                onClick={onClickToggleAccordion}
                                isExpanded={isAccordionExpanded}
                            />
                        </Stack>
                    </Grid>
                ) : null}
                <Grid
                    item
                    container
                    xs={6}
                    columnSpacing={2}
                    direction={{xs: "column", lg: "row"}}
                    justifyContent="flex-end"
                    alignItems={{xs: "flex-end", lg: "flex-start"}}
                >
                    <Grid item display={{xs: "none", lg: "flex"}}>
                        <EntityCounter
                            size={size}
                            entityName={entityName}
                            isLoading={isLoading}
                        />
                    </Grid>
                    {createButton ? <Grid item>{createButton}</Grid> : null}
                    {views.length > 1 ? (
                        <Grid item>
                            <EntityChangeView views={views} />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        )
    );
};
export default EntityListPageHeader;
