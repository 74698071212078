import {useOutletContext} from "react-router-dom";

import {FormDirtyProvider} from "base/form/provider";
import {ViewPermitApplicationWidget} from ".";

const ViewPermitApplicationProcessSubPage = () => {
    const [permit] = useOutletContext();

    return permit ? (
        <FormDirtyProvider>
            <ViewPermitApplicationWidget permit={permit} process="application" />
        </FormDirtyProvider>
    ) : null;
};

export default ViewPermitApplicationProcessSubPage;
