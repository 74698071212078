import {MapBaseLayersProvider} from "base/map/layer";
import {createBasePlotLayerConfig, useSnimfMapConfig} from "snimf/map";
import {createPlotLayerConfig, createSinglePlotLayerConfig} from "plot/map";
import {MapProvider} from "base/map";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const PlotListDetail = ({properties = null, selected = null}) => {
    return (
        <Typography variant="subtitle1" sx={{fontSize: "0.9rem"}}>
            {properties.code}
        </Typography>
    );
};

export const PlotListDetailSecondary = ({properties = null, selected = null}) => {
    return (
        <Stack direction="row" spacing={1}>
            <Typography variant="caption" sx={{fontSize: "0.7rem"}}>
                Titular:
            </Typography>
            <Typography variant="caption" sx={{fontSize: "0.7rem"}}>
                {properties.holder_name}
            </Typography>
        </Stack>
    );
};

const PlotMapProvider = ({children, plot, markAsDirty = null}) => {
    // map config
    const {crs, crsType, tocOptions, mapOptions, baseLayers} = useSnimfMapConfig();

    const singlePlotLayerConfig = createSinglePlotLayerConfig({
        plot: plot,
        list: {
            primary: <PlotListDetail />,
            secondary: <PlotListDetailSecondary />,
        },
        onUpdate: () => {
            markAsDirty();
        },
    });

    const plotLayerConfig = createPlotLayerConfig({
        label: "Outras parcelas",
        excludedPlotId: plot?.id,
    });

    const basePlotLayerConfig = createBasePlotLayerConfig();

    // end map config

    return (
        <MapProvider
            crs={crs}
            crsType={crsType}
            baseLayers={baseLayers}
            tocOptions={tocOptions}
            mapOptions={mapOptions}
        >
            <MapBaseLayersProvider
                layers={[singlePlotLayerConfig]}
                auxLayers={[plotLayerConfig, basePlotLayerConfig]}
            >
                {children}
            </MapBaseLayersProvider>
        </MapProvider>
    );
};
export default PlotMapProvider;
