import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

import {useConfigModule} from "base/ui/module/provider";
import {LocationProvider} from "snimf/location/provider";
import {PlantationService} from "plantation/service";

import {PlantationSubPageMenu} from "plantation/menu";
import {PageLayout} from "base/ui/main";

const ViewPlantationPage = () => {
    const {id} = useParams();

    const [plantation, setPlantation] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();

    const {setModuleBasePath, isLoading, setIsLoading} = useConfigModule();

    useEffect(() => {
        setIsLoading(true);
        setPlantation(null);
        setModuleBasePath(`/plantations/all/list/${id}`);
        PlantationService.get(id)
            .then(data => {
                setPlantation(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setError(error);
                setIsLoading(false);
            });
    }, [id, location.state?.lastRefreshDate]);

    return (
        <LocationProvider>
            <PageLayout
                menu={<PlantationSubPageMenu plantation={plantation} />}
                context={[plantation]}
                isLoading={isLoading}
                error={error}
                subPage
            />
        </LocationProvider>
    );
};

export default ViewPlantationPage;
