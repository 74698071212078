import {FormProvider, useForm} from "react-hook-form";

import {FormUtil} from "base/form/utilities";
import {LocationProvider} from "snimf/location/provider";
import {EntityForm} from "base/entity/components/form";
import {AlertError} from "base/error/components";
import Stack from "@mui/material/Stack";

const PlotForm = ({plot = null, onSubmit, onCancel = null, error = null, children}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(plot?.id),
        number: FormUtil.getFormValue(plot?.number),
        code: FormUtil.getFormValue(plot?.code),
        land_ownership: FormUtil.getFormValue(plot?.land_ownership),
        land_use: FormUtil.getFormValue(plot?.land_use),
        state_concession: FormUtil.getFormValue(plot?.state_concession),
        land_size: FormUtil.getFormValue(plot?.land_size),
        land_cover: FormUtil.getFormValue(plot?.land_cover, []),
        is_area_protected: FormUtil.getFormValue(plot?.is_area_protected),
        official_area: FormUtil.getFormValue(plot?.official_area),
        geom_area: FormUtil.getFormValue(plot?.geom_area),
        island: FormUtil.getFormValue(plot?.island),
        district: FormUtil.getFormValue(plot?.district),
        sub_district: FormUtil.getFormValue(plot?.sub_district),
        locality: FormUtil.getFormValue(plot?.locality),
        comments: FormUtil.getFormValue(plot?.comments),
        geom: FormUtil.getFormValue(plot?.geom),
        holder: FormUtil.getFormValue(plot?.holder),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const handleFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            number: FormUtil.getDataValue(data.number),
            code: FormUtil.getDataValue(data.code),
            land_ownership: FormUtil.getDataValue(data.land_ownership),
            land_use: FormUtil.getDataValue(data.land_use),
            state_concession: FormUtil.getDataValue(data.state_concession),
            land_size: FormUtil.getDataValue(data.land_size),
            land_cover: FormUtil.getDataValue(data.land_cover),
            is_area_protected: FormUtil.getDataValue(data.is_area_protected),
            official_area: FormUtil.getDataValue(data.official_area),
            geom_area: FormUtil.getDataValue(data.geom_area),
            island: FormUtil.getDataValue(data.island),
            district: FormUtil.getDataValue(data.district),
            sub_district: FormUtil.getDataValue(data.sub_district),
            locality: FormUtil.getDataValue(data.locality),
            comments: FormUtil.getDataValue(data.comments),
            geom: FormUtil.getDataValue(data.geom),
            holder: FormUtil.getDataValue(data.holder),
        });
    };

    return (
        <LocationProvider>
            <FormProvider {...formMethods}>
                <Stack spacing={1}>
                    <AlertError error={error} />
                    <EntityForm
                        onSubmit={formMethods.handleSubmit(handleFormSubmit)}
                        onCancel={onCancel}
                    >
                        {children}
                    </EntityForm>
                </Stack>
            </FormProvider>
        </LocationProvider>
    );
};

export default PlotForm;
