import {useAdministrativeDivisions} from "snimf/location/provider";

import {FormDatePicker, FormSelect} from "base/form/components";
import Grid from "@mui/material/Grid";

const PlantationInitialFormFields = () => {
    const {userDistricts} = useAdministrativeDivisions();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormDatePicker
                    name="application_date"
                    label="Data de solicitação"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="district"
                    label="Distrito"
                    options={userDistricts}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
        </Grid>
    );
};

export default PlantationInitialFormFields;
