import {FormProvider, useForm} from "react-hook-form";

import {FormUtil} from "base/form/utilities";

import {PlantDataFormFields} from ".";
import {FormContainer} from "base/form/components";
import {EntityForm} from "base/entity/components/form";
import {AlertError} from "base/error/components";

const PlantForm = ({
    plantationId,
    plant = null,
    onSubmit,
    onCancel = null,
    error = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(plant?.id),
        code: FormUtil.getFormValue(plant?.code),
        species: FormUtil.getFormValue(plant?.species),
        scientific_name: FormUtil.getFormValue(plant?.scientific_name),
        quantity: FormUtil.getFormValue(plant?.quantity),
        source: FormUtil.getFormValue(plant?.source),
        sowing_date: FormUtil.getFormValue(plant?.sowing_date),
        germination_date: FormUtil.getFormValue(plant?.germination_date),
        transplant_date: FormUtil.getFormValue(plant?.transplant_date),
        plantation: plantationId,
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            code: FormUtil.getDataValue(data.code),
            species: FormUtil.getDataValue(data.species),
            scientific_name: FormUtil.getDataValue(data.scientific_name),
            quantity: FormUtil.getDataValue(data.quantity),
            source: FormUtil.getDataValue(data.source),
            sowing_date: FormUtil.getDataValue(data.sowing_date),
            germination_date: FormUtil.getDataValue(data.germination_date),
            transplant_date: FormUtil.getDataValue(data.transplant_date),
            plantation: plantationId,
        });
    };

    return (
        <FormProvider {...formMethods}>
            <AlertError error={error} />
            <FormContainer>
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onCancel}
                >
                    <PlantDataFormFields />
                </EntityForm>
            </FormContainer>
        </FormProvider>
    );
};

export default PlantForm;
