import {useEffect} from "react";
import {useWatch, useFormContext} from "react-hook-form";

function useViolationActionsForm() {
    const {setValue} = useFormContext();

    const actionTaken = useWatch({
        name: "actions",
    });

    const destinations = useWatch({
        name: [
            "forest_material_destination",
            "chainsaw_destination",
            "vehicle_destination",
        ],
    });

    const isPenaltyPaid = useWatch({
        name: "penalty_paid",
    });

    const forestMaterial = useWatch({
        name: "forest_material",
    });

    const chainsaw = useWatch({
        name: "chainsaw",
    });

    const vehicle = useWatch({
        name: "vehicle",
    });

    useEffect(() => {
        if (!isPenaltyPaid) {
            setValue("penalty_receipt", false);
        }
    }, [isPenaltyPaid]);

    const shouldDisplayFields = (array, fields) => {
        if (array.length) return fields.some(field => array?.includes(field));
        else return false;
    };

    const displayConfiscationFields = shouldDisplayFields(actionTaken, [
        "confiscation",
    ]);
    const displayPenaltyFields = shouldDisplayFields(actionTaken, ["penalty"]);
    const displaySaleFields = shouldDisplayFields(destinations, ["Vendido pela DFB"]);

    const formValues = {
        actionTaken,
        destinations,
        isPenaltyPaid,
        forestMaterial,
        chainsaw,
        vehicle,
    };

    const shouldDisplay = {
        confiscationFields: displayConfiscationFields,
        penaltyFields: displayPenaltyFields,
        saleFields: displaySaleFields,
    };

    return {formValues, shouldDisplay};
}

export {useViolationActionsForm};
