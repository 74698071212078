import {theme} from "Theme";
import {FormRadioGroup} from "base/form/components";

const PermitApplicationDecisionFormFields = ({disabled}) => {
    const decisionOptions = [
        {value: "Positivo", label: "Positivo"},
        {value: "Negativo", label: "Negativo"},
    ];

    return (
        <FormRadioGroup
            name="technical_decision"
            label="Parecer técnico"
            options={decisionOptions}
            containerStyle={{display: "flex", alignItems: "center"}}
            labelStyle={{
                color: theme.palette.primary.main,
                fontWeight: 500,
                mt: 1,
                mb: 2,
                textTransform: "uppercase",
            }}
            disabled={disabled}
        />
    );
};

export default PermitApplicationDecisionFormFields;
