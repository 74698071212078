import {FileUtil} from ".";

const FileValidation = {
    validateFile(file, allowedTypes, maxSize) {
        if (!(file instanceof File)) {
            return "O ficheiro selecionado é inválido.";
        }
        if (allowedTypes && !this.validateFileType(file, allowedTypes.mime)) {
            return `O ficheiro tem de ser do tipo ${allowedTypes.tags}.`;
        }
        if (!this.validateFileMaxSize(file, maxSize)) {
            return `O tamanho do ficheiro não pode ser maior do que ${FileUtil.formatBytes(
                maxSize
            )}.`;
        }
        return true;
    },

    validateFileType(file, allowedTypes) {
        return allowedTypes.includes(file.type);
    },

    validateFileMaxSize(file, maxSize) {
        return file.size <= maxSize;
    },
};

export default FileValidation;
