import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {FormInputDecimal} from "base/form/components";
import {DividerWithHeading} from "base/ui/headings/components";
import Grid from "@mui/material/Grid";

const PermitApplicationOtherFeesFormFields = ({permitTypes, disabled = false}) => {
    return (
        <Grid container columnSpacing={4} mt={2}>
            <Grid item container direction="column" xs={6}>
                <DividerWithHeading label="Desbravamento" />
                <FormInputDecimal
                    name="clearing_area"
                    label="Área a desbravar"
                    endAdornment="ha"
                    disabled
                />
                <FormInputDecimal
                    name="clearing_fee"
                    label="Taxa de desbravamento *"
                    endAdornment={`${CURRENCY_SYMBOL}/ha`}
                    disabled={!permitTypes?.includes("clearing")}
                />
                <FormInputDecimal
                    name="clearing_price"
                    label="Valor pago de desbravamento"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled
                />
            </Grid>
            <Grid item container direction="column" xs={6}>
                <DividerWithHeading label="Outras taxas" />
                <FormInputDecimal
                    name="fuel_fee"
                    label="Combustível para deslocação"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled={disabled}
                />
                <FormInputDecimal
                    name="printing_fee"
                    label="Impresão de documentos"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled={disabled}
                />
                <FormInputDecimal
                    name="restock_fee"
                    label="Plantas"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default PermitApplicationOtherFeesFormFields;
