import {theme} from "Theme";
import Typography from "@mui/material/Typography";

const FileLabel = ({label, subLabel = ""}) => {
    return (
        <Typography variant="subtitle2" lineHeight={1.5}>
            {label}
            {subLabel ? (
                <Typography
                    variant="caption"
                    component="span"
                    sx={{
                        marginLeft: "8px",
                        color: theme.palette.text.secondary,
                        fontWeight: "normal",
                    }}
                >
                    — {subLabel}
                </Typography>
            ) : null}
        </Typography>
    );
};

export default FileLabel;
