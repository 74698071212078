import {FileUploadField} from "base/file/components";

const PermitApplicationPaymentFormFields = ({disabled}) => {
    const maxSize = 5000000;

    return (
        <FileUploadField
            name="payment_receipt"
            label="Comprovativo de pagamento *"
            maxSize={maxSize}
            disabled={disabled}
        />
    );
};

export default PermitApplicationPaymentFormFields;
