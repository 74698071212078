import {FieldUtil} from "base/ui/section/utilities";
import Grid from "@mui/material/Grid";

const PlotBasicDataSection = ({plot}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Código",
                    plot?.code,
                    null,
                    null,
                    `/plots/all/list/${plot?.id}/summary`
                )}
                {FieldUtil.getSectionField(
                    "Proprietário/a",
                    plot?.holder?.name,
                    null,
                    null,
                    `/holders/all/list/${plot?.holder?.id}/summary`
                )}
                {FieldUtil.getSectionField("Distrito", plot?.district)}
                {FieldUtil.getSectionCheckBoxField(
                    "Zona de protecção",
                    plot?.is_area_protected
                )}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Número", plot?.number)}
                {FieldUtil.getSectionField(
                    "Propriedade da terra",
                    plot?.land_ownership
                )}
                {FieldUtil.getSectionField("Localidade", plot?.locality)}
            </Grid>
        </Grid>
    );
};

export default PlotBasicDataSection;
