import {MapBaseLayersProvider, useMapGeojsonLayerConfig} from "base/map/layer";
import {createBasePlotLayerConfig, useSnimfMapConfig} from "snimf/map";
import {
    createPlotLayerConfig,
    createSinglePlotLayerConfig,
    PlotListDetail,
    PlotListDetailSecondary,
} from "plot/map";
import {MapProvider} from "base/map";
import {TreeService} from "tree/service";
import {createTreeLayer, createTreeLegend} from "tree/map";
import {PermitTypeTags} from "permit/presentational";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const TreeListDetail = ({properties = null, selected = null}) => {
    return (
        <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1" sx={{fontSize: "0.9rem"}}>
                {properties.code}
            </Typography>
            <PermitTypeTags
                types={[
                    properties.felling && "felling",
                    properties.cutting && "cutting",
                ]}
                short={true}
            />
        </Stack>
    );
};

const TreeListDetailSecondary = ({properties = null, selected = null}) => {
    return (
        <Stack direction="row" spacing={1}>
            <Typography variant="caption" sx={{fontSize: "0.7rem"}}>
                Especie:
            </Typography>
            <Typography variant="caption" sx={{fontSize: "0.7rem"}}>
                {properties.species}
            </Typography>
        </Stack>
    );
};

const PermitApplicationTechinalDataMapProvider = ({children, permit}) => {
    // map config
    const {crs, crsType, tocOptions, mapOptions, baseLayers} = useSnimfMapConfig();

    const permitPlotLayerConfig = createSinglePlotLayerConfig({
        plot: permit?.plot,
        list: {
            header: "Parcela",
            primary: <PlotListDetail />,
            secondary: <PlotListDetailSecondary />,
        },
    });

    const treeLayer = createTreeLayer();
    const treeLegend = createTreeLegend({label: "Árvores da parcela"});

    const permitTreesLayerConfig = useMapGeojsonLayerConfig({
        load: filter => {
            return TreeService.getFeatures({
                ...filter,
                permit_id: permit.id,
            });
        },
        update: (id, feature) => {
            console.log(`Updating ${id}`, feature);
            return TreeService.updateFeature(id, feature);
        },
        layer: treeLayer,
        legend: treeLegend,
        list: {
            header: "Lista de árvores",
            primary: <TreeListDetail />,
            secondary: <TreeListDetailSecondary />,
        },
    });

    const plotLayerConfig = createPlotLayerConfig({
        label: "Outras parcelas",
        excludedPlotId: permit?.plot ? permit.plot.id : null,
    });

    const basePlotLayerConfig = createBasePlotLayerConfig();

    // end map config

    return (
        <MapProvider
            crs={crs}
            crsType={crsType}
            baseLayers={baseLayers}
            tocOptions={tocOptions}
            mapOptions={mapOptions}
        >
            <MapBaseLayersProvider
                layers={[permitPlotLayerConfig, permitTreesLayerConfig]}
                auxLayers={[plotLayerConfig, basePlotLayerConfig]}
            >
                {children}
            </MapBaseLayersProvider>
        </MapProvider>
    );
};
export default PermitApplicationTechinalDataMapProvider;
