import {useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "base/file/components";

const ViewPlotDocumentsSubPage = () => {
    let plot;
    [plot] = useOutletContext();

    return plot && <ViewDocumentsSubPage entity={plot} basePath={"/plots/all/list"} />;
};

export default ViewPlotDocumentsSubPage;
