import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity/components/container";
import {ApprovedPermitInspectionGeneralDataSection} from "permit/presentational";

const ViewApprovedPermitInspectionSubPage = () => {
    const context = useOutletContext();
    const permit = context[0];

    let sections = [<ApprovedPermitInspectionGeneralDataSection permit={permit} />];

    return permit && <EntityViewSubPage sections={sections} />;
};

export default ViewApprovedPermitInspectionSubPage;
