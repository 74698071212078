import {useOutletContext} from "react-router-dom";

import {EntityViewSubPage} from "base/entity/components/container";

import {HolderBasicDataSection} from "holder/presentational";
import {ContactsSection} from "contact/presentational";
import {SectionCard} from "base/ui/section/components";

const ViewApprovedPermitHolderSubPage = () => {
    const context = useOutletContext();
    const permit = context[0];

    let sections = [
        <SectionCard title="Titular da autorização" key="holder">
            <HolderBasicDataSection holder={permit?.holder} />
        </SectionCard>,
        <SectionCard title="Contactos associados" key="contacts">
            <ContactsSection permit={permit} allowEdit={false} />
        </SectionCard>,
    ];

    return permit && <EntityViewSubPage sections={sections} />;
};

export default ViewApprovedPermitHolderSubPage;
