import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const AlertDetailNotFound = ({...props}) => {
    return (
        <Stack maxWidth="80%">
            <Alert severity="warning" sx={{py: 4}}>
                <AlertTitle>Página não encontrada.</AlertTitle>
                Tente limpar os filtros activos ou navegar para outra página.
            </Alert>
        </Stack>
    );
};

export default AlertDetailNotFound;
