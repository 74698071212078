import {useEffect, useState} from "react";
import {NoDataContainer, Spinner} from "base/shared/components";
import {SimpleTable} from "base/table/components";
import {SectionCard} from "base/ui/section/components";

const LinkedEntitySection = ({entityId, linkedEntityName, getItems, tableColumns}) => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getItems(entityId)
            .then(response => {
                setItems(response);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }, [entityId]);

    return (
        <SectionCard title={linkedEntityName}>
            {isLoading ? (
                <Spinner small />
            ) : items.length ? (
                <SimpleTable dataRows={items} tableColumns={tableColumns} />
            ) : (
                <NoDataContainer text="Não há elementos associados." />
            )}
        </SectionCard>
    );
};

export default LinkedEntitySection;
