import {
    createPermit,
    createPermits,
    permit_api_adapter,
    permits_api_adapter,
} from "permit/model";
import {createEntityService} from "base/entity/service";
import {AuthApiService} from "base/api/service";
import {createHolder, holder_api_adapter} from "holder/model";
import {createPlot, plot_api_adapter} from "plot/model";
import {createTree, tree_api_adapter, tree_view_adapter} from "tree/model";
import {
    createMaterial,
    material_api_adapter,
    material_view_adapter,
} from "material/model";
import {TreeService} from "tree/service";
import {MaterialService} from "material/service";

const basePath = "/api/app/permitapplications";

const entityService = createEntityService(
    basePath,
    createPermit,
    createPermits,
    permit_api_adapter,
    permits_api_adapter
);

const PermitApplicationService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(provider) {
        return entityService.create(provider);
    },

    createHolder(permitId, holder) {
        return AuthApiService.post(`${basePath}/${permitId}/holders/`, holder).then(
            response => createHolder(holder_api_adapter(response))
        );
    },

    createPlot(permitId, plot) {
        return AuthApiService.post(`${basePath}/${permitId}/plots/`, plot).then(
            response => createPlot(plot_api_adapter(response))
        );
    },

    createTree(permitId, tree) {
        const _tree = {
            ...tree_view_adapter({...tree}),
            permit: permitId,
        };
        return TreeService.create(_tree).then(response =>
            createTree(tree_api_adapter(response))
        );
    },

    createMaterial(permitId, material) {
        const _material = {
            ...material_view_adapter({...material}),
            permit: permitId,
        };
        return MaterialService.create(_material).then(response =>
            createMaterial(material_api_adapter(response))
        );
    },

    update(provider) {
        return entityService.update(provider);
    },

    updateStatus(permitId, code) {
        return AuthApiService.put(`${basePath}/${permitId}/status/`, code).then(
            response => {
                return createPermit(permit_api_adapter(response));
            }
        );
    },

    downloadInvoice(permitId) {
        return AuthApiService.get(`${basePath}/${permitId}/printinvoice/`).then(
            response => {
                return response;
            }
        );
    },

    downloadPermit(permitId) {
        return AuthApiService.get(`${basePath}/${permitId}/printpermit/`).then(
            response => {
                return response;
            }
        );
    },

    delete(id) {
        return entityService.delete(id);
    },
    getFieldStatus(id) {
        return AuthApiService.get(`${basePath}/${id}/fieldstatus/`).then(
            response => response.result
        );
    },
    fieldExport(id) {
        return AuthApiService.put(`${basePath}/${id}/fieldexport/`).then(
            response => response.result
        );
    },
    fieldImport(id, forced = false) {
        return AuthApiService.put(`${basePath}/${id}/fieldimport/`, {forced}).then(
            response => response.result
        );
    },
};

export default PermitApplicationService;
