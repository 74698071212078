import Typography from "@mui/material/Typography";
import {DateUtil} from "base/format/utilities";
import {TextLink} from "base/navigation/components";
import {PermitTypeTags} from "permit/presentational";
import {theme} from "Theme";
import {PERMIT_APPLICATION_STATUS} from "./permitApplicationStatus";

export function usePermitApplicationsTable() {
    const tableColumns = [
        {
            id: "code",
            label: "Número",
            width: 10,
            formatFunction: item => (
                <TextLink
                    text={item?.code}
                    to={`/permits/applications/list/${item.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
        },
        {
            id: "status",
            label: "Estado",
            width: 25,
            formatFunction: item => (
                <Typography
                    color={
                        item.status === PERMIT_APPLICATION_STATUS.not_approved
                            ? theme.palette.error.dark
                            : "inherit"
                    }
                    fontSize={theme.typography.fontSize}
                >
                    {item.status}
                </Typography>
            ),
        },
        {
            id: "application_date",
            label: "Data de solicitação",
            width: 15,
            formatFunction: item => DateUtil.formatDate(item.application_date),
        },
        {
            id: "holder__name",
            label: "Titular",
            width: 20,
            formatFunction: item => (
                <TextLink
                    text={item?.holder?.name}
                    to={`/holders/all/list/${item.holder?.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
        },
        {
            id: "district",
            label: "Distrito",
            width: 10,
        },
        {
            id: "types",
            label: "Guias",
            width: 20,
            formatFunction: item => {
                return <PermitTypeTags types={item?.types} />;
            },
            disableSorting: true,
        },
    ];

    return {tableColumns};
}
