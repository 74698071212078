import {useOutletContext} from "react-router-dom";
import {useEntityPermissions} from "snimf/permissions/hooks";
import {EntityViewSubPage} from "base/entity/components/container";
import {ViewOrUpdatePlotContent} from ".";
import {PlotMapSection} from "plot/presentational";

const ViewPlotLocationSubPage = () => {
    const context = useOutletContext();
    const plot = context[0];

    const {allowEdit} = useEntityPermissions(plot, "plots");

    const sectionsDetails = [{name: "location", label: "Localização"}];

    const sections = [
        ...sectionsDetails.map(section => (
            <ViewOrUpdatePlotContent
                key={section.name}
                section={section.name}
                label={section.label}
                plot={plot}
                allowEdit={allowEdit}
            />
        )),
        <PlotMapSection key="plot-map-section" plot={plot} editablePlot={allowEdit} />,
    ];

    return plot && <EntityViewSubPage sections={sections} />;
};

export default ViewPlotLocationSubPage;
