import {FieldUtil} from "base/ui/section/utilities";

import Grid from "@mui/material/Grid";

const ViolationTeamSection = ({violation}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Equipa técnica DFB",
                    violation.inspection_team
                )}
                {FieldUtil.getSectionField("Agentes", violation.officers)}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Motorista", violation.driver)}
            </Grid>
        </Grid>
    );
};

export default ViolationTeamSection;
