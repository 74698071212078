import {useOutletContext} from "react-router-dom";

import {usePermitTreesTable} from "tree/data";
import {useTransportMaterialTable} from "material/data";
import {SectionCard} from "base/ui/section/components";
import {SimpleTable} from "base/table/components";
import Stack from "@mui/material/Stack";

const ViewApprovedPermitTypesOverview = () => {
    const {permit} = useOutletContext();
    const {tableColumns: treesTableColumns} = usePermitTreesTable();
    const {tableColumns: materialsTableColumns} = useTransportMaterialTable();

    return (
        <Stack spacing={1}>
            {permit?.tree_set.length ? (
                <SectionCard title="Árvores na autorização">
                    <SimpleTable
                        dataRows={permit.tree_set}
                        tableColumns={treesTableColumns}
                    />
                </SectionCard>
            ) : null}

            {permit?.material_set.length ? (
                <SectionCard title="Materiais para transporte">
                    <SimpleTable
                        dataRows={permit.material_set}
                        tableColumns={materialsTableColumns}
                    />
                </SectionCard>
            ) : null}
        </Stack>
    );
};

export default ViewApprovedPermitTypesOverview;
