import {useAdministrativeDivisions} from "../provider";

export const useDistrictIsland = () => {
    const {districts} = useAdministrativeDivisions();

    const getDistrictIsland = district => {
        const districtData = districts.find(item => item.value === district);
        if (districtData) return districtData.island_code;
        return null;
    };

    return {getDistrictIsland};
};
