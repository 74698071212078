import {useAdministrativeDivisions} from "snimf/location/provider";
import {
    FormDatePicker,
    FormInputDecimal,
    FormInputInteger,
    FormInputText,
    FormSelect,
} from "base/form/components";

import Grid from "@mui/material/Grid";

const PlantationGeneralDataFormFields = () => {
    const {userDistricts} = useAdministrativeDivisions();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormInputText name="code" label="Código" disabled />
            </Grid>
            <Grid item xs={6}>
                <FormDatePicker
                    name="application_date"
                    label="Data de solicitação"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect name="district" label="Distrito" options={userDistricts} />
            </Grid>
            <Grid item xs={6}>
                <FormDatePicker name="on_date" label="Data de colocação do visto" />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="area"
                    label="Superficie plantada"
                    endAdornment="ha"
                />
            </Grid>
            <Grid item xs={6}>
                <FormDatePicker
                    name="delivery_date"
                    label="Data de entrega de plantas ao requerente"
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputInteger
                    name="quantity"
                    label="Quantidade total plantada"
                    endAdornment="plantas"
                    disabled
                />
            </Grid>
        </Grid>
    );
};

export default PlantationGeneralDataFormFields;
