import {useOutletContext} from "react-router-dom";
import {useEntityPermissions} from "snimf/permissions/hooks";
import {EntityViewSubPage} from "base/entity/components/container";
import {ViewOrUpdatePlantationHolderContent} from ".";

const ViewPlantationHolderSubPage = () => {
    const context = useOutletContext();
    const plantation = context[0];

    const {allowEdit} = useEntityPermissions(plantation, "plantations");

    let sections = [
        <ViewOrUpdatePlantationHolderContent
            key="holder"
            plantation={plantation}
            allowEdit={allowEdit}
        />,
    ];

    return plantation && <EntityViewSubPage sections={sections} />;
};

export default ViewPlantationHolderSubPage;
