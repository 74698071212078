import {SubSectionCardHeader} from "base/ui/section/components";
import {MaterialChip, MaterialDataFields} from ".";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const MaterialCard = ({material, displayFees = true}) => {
    return (
        <Card sx={{border: 1, borderRadius: 2, borderColor: "grey.300"}} elevation={0}>
            <SubSectionCardHeader titleTags={<MaterialChip element={material} />} />
            <CardContent>
                <MaterialDataFields material={material} displayFees={displayFees} />
            </CardContent>
        </Card>
    );
};

export default MaterialCard;
