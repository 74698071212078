import {createHolder, holder_api_adapter} from "holder/model";
import {createPermit, permit_api_adapter} from "permit/model";
import {createPlot, plot_api_adapter} from "plot/model";

class Violations extends Array {}

const violation_api_adapter = violation => {
    if (violation["permit"])
        violation["permit"] = createPermit(permit_api_adapter(violation["permit"]));
    if (violation["holder"])
        violation["holder"] = createHolder(holder_api_adapter(violation["holder"]));
    if (violation["plot"])
        violation["plot"] = createPlot(plot_api_adapter(violation["plot"]));
    return violation;
};

const violations_api_adapter = violations => {
    return violations.map(violation_api_adapter);
};

const violation_view_adapter = violation => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside violation object
    violation["sale_receipt"] = !!violation["sale_receipt"];
    violation["penalty_paid"] = !!violation["penalty_paid"];
    violation["penalty_receipt"] = !!violation["penalty_receipt"];

    violation["permit_id"] = violation["permit"]?.id || null;
    violation["holder_id"] = violation["holder"]?.id || null;
    violation["plot_id"] = violation["plot"]?.id || null;
    if (!violation["types"]) violation["types"] = [];
    if (!violation["actions"]) violation["actions"] = [];

    delete violation["code"];
    delete violation["status"];
    delete violation["island"];
    delete violation["types_label"];
    delete violation["actions_label"];
    delete violation["permit"];
    delete violation["holder"];
    delete violation["plot"];

    delete violation["folder"];
    delete violation["created_by"];
    delete violation["updated_by"];
    delete violation["created_at"];
    delete violation["updated_at"];

    return violation;
};

const createViolations = (data = []) => {
    const violations = Violations.from(data, violation => createViolation(violation));
    return violations;
};

const createViolation = ({
    id = -1,
    code = "",
    on_date = null,
    district = "",
    status = "",
    forest_material = "",
    chainsaw = "",
    vehicle = "",
    forest_material_destination = "",
    chainsaw_destination = "",
    vehicle_destination = "",
    sale_amount = "",
    sale_receipt = null,
    sale_buyer = "",
    penalty_paid = null,
    penalty_amount = "",
    penalty_receipt = null,
    inspection_team = "",
    driver = "",
    officers = "",
    geom = null,
    comments = "",
    types = null,
    types_label = "",
    actions = null,
    actions_label = "",
    permit = "",
    permit_id = "",
    holder = "",
    holder_id = "",
    plot = "",
    plot_id = "",
    folder = "",
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        code,
        on_date,
        district,
        status,
        forest_material,
        chainsaw,
        vehicle,
        forest_material_destination,
        chainsaw_destination,
        vehicle_destination,
        sale_amount,
        sale_receipt,
        sale_buyer,
        penalty_paid,
        penalty_amount,
        penalty_receipt,
        inspection_team,
        driver,
        officers,
        geom,
        comments,
        types,
        types_label,
        actions,
        actions_label,
        permit,
        permit_id,
        holder,
        holder_id,
        plot,
        plot_id,
        folder,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createViolation as default,
    createViolations,
    violation_api_adapter,
    violations_api_adapter,
    violation_view_adapter,
};
