import {FormProvider, useForm} from "react-hook-form";

import {FormUtil} from "base/form/utilities";

import {ContactFormDataFields} from ".";
import {FormContainer} from "base/form/components";
import {EntityForm} from "base/entity/components/form";
import {AlertError} from "base/error/components";

const ContactForm = ({
    permitId,
    contact = null,
    onSubmit,
    onCancel = null,
    error = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(contact?.id),
        name: FormUtil.getFormValue(contact?.name),
        phone: FormUtil.getFormValue(contact?.phone),
        email: FormUtil.getFormValue(contact?.email),
        comments: FormUtil.getFormValue(contact?.comments),
        permit: FormUtil.getFormValue(contact?.permit),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            name: FormUtil.getDataValue(data.name),
            phone: FormUtil.getDataValue(data.phone),
            email: FormUtil.getDataValue(data.email),
            comments: FormUtil.getDataValue(data.comments),
            permit: permitId,
        });
    };

    return (
        <FormProvider {...formMethods}>
            <AlertError error={error} />
            <FormContainer>
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onCancel}
                >
                    <ContactFormDataFields />
                </EntityForm>
            </FormContainer>
        </FormProvider>
    );
};

export default ContactForm;
