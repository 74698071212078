import {useButtonDelay} from "base/shared/utilities";
import {DialogLayout} from "base/dialog/components";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const RevertProcessStepDialog = ({isDialogOpen, setIsDialogOpen, onRevert}) => {
    const {isButtonDisabled, secondsRemaining} = useButtonDelay(5, isDialogOpen);

    const handleDialog = isOpen => {
        setIsDialogOpen(false);
    };

    const handleConfirm = () => {
        setIsDialogOpen(false);
        onRevert();
    };

    const DialogContent = () => {
        return (
            <Stack spacing={2} mt={1}>
                <Alert severity="warning" sx={{border: 1}}>
                    <AlertTitle>ATENÇÃO</AlertTitle>
                    Essa ação levará o processo a uma etapa anterior que pode estar sob
                    a responsabilidade de outro departamento.
                </Alert>
                <Alert severity="info" variant="outlined">
                    <AlertTitle>Precisa apenas consultar a informação?</AlertTitle>
                    Clique no nome do passo que deseja visualizar.
                </Alert>
            </Stack>
        );
    };

    return (
        <DialogLayout
            dialogTitle={`Deseja revertir o processo para o passo anterior?`}
            dialogContent={<DialogContent />}
            mainActionText={
                isButtonDisabled
                    ? `Activado em ${secondsRemaining} s`
                    : "Desfazer passo"
            }
            secondaryActionText="Cancelar"
            onMainActionClick={handleConfirm}
            onHandleDialog={handleDialog}
            isDialogOpen={isDialogOpen}
            mainActionColor="error"
            isMainActionDisabled={isButtonDisabled}
        />
    );
};

export default RevertProcessStepDialog;
