import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";

import {AuthProvider} from "base/user/provider";
import {AuthRequired} from "base/user/utilities";

import {SnimfApp} from "snimf/ui";
import {LoginPage} from "base/user/components";
import {PermitModule, permitRoutes} from "permit/module";
import {ViolationModule, violationRoutes} from "violation/module";
import {PlantationModule, plantationRoutes} from "plantation/module";
import {HolderModule, holderRoutes} from "holder/module";
import {PlotModule, plotRoutes} from "plot/module";

const defaultRedirectPath = "permits/approved/list";

export default function SnimfRoutes() {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route key="login" path="/login" element={<LoginPage />} />
                    <Route path="" element={<SnimfApp />}>
                        <Route
                            path=""
                            element={<Navigate to={defaultRedirectPath} />}
                        />
                        <Route
                            path="/*"
                            element={<Navigate to={defaultRedirectPath} />}
                        />
                        <Route
                            path="permits"
                            element={
                                <AuthRequired>
                                    <PermitModule />
                                </AuthRequired>
                            }
                        >
                            {permitRoutes}
                        </Route>
                        <Route
                            path="violations"
                            element={
                                <AuthRequired>
                                    <ViolationModule />
                                </AuthRequired>
                            }
                        >
                            {violationRoutes}
                        </Route>
                        <Route
                            path="plantations"
                            element={
                                <AuthRequired>
                                    <PlantationModule />
                                </AuthRequired>
                            }
                        >
                            {plantationRoutes}
                        </Route>
                        <Route
                            path="holders"
                            element={
                                <AuthRequired>
                                    <HolderModule />
                                </AuthRequired>
                            }
                        >
                            {holderRoutes}
                        </Route>
                        <Route
                            path="plots"
                            element={
                                <AuthRequired>
                                    <PlotModule />
                                </AuthRequired>
                            }
                        >
                            {plotRoutes}
                        </Route>
                    </Route>
                </Routes>
            </AuthProvider>
        </Router>
    );
}
