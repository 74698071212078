import {useState, useEffect} from "react";

const useButtonDelay = (initialDelay = 5, isActive = false) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [secondsRemaining, setSecondsRemaining] = useState(initialDelay);

    useEffect(() => {
        if (isActive) {
            setIsButtonDisabled(true);
            setSecondsRemaining(initialDelay);

            const timer = setInterval(() => {
                setSecondsRemaining(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setIsButtonDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isActive, initialDelay]);

    return {
        isButtonDisabled,
        secondsRemaining,
    };
};

export {useButtonDelay};
