import {useState, useEffect, useMemo} from "react";
import {PermitApplicationService} from "permitapplication/service";
import {useNavigateWithReload} from "base/navigation/hooks";

const usePermitFieldStatus = permit => {
    const [fieldStatus, setFieldStatus] = useState(null);
    const [isForcedImportEnabled, setIsForcedImportEnabled] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigateWithReload();

    useEffect(() => {
        // TO-DO: For testing only. Remove.
        // setFieldStatus("not_exported");
        // setFieldStatus("exported_not_changed");
        // setFieldStatus("exported_and_changed");
        // setFieldStatus("imported");
        // setFieldStatus("forced_imported");
        // setFieldStatus("loading");
        // setIsLoading(true);
        if (permit) {
            PermitApplicationService.getFieldStatus(permit.id)
                .then(data => {
                    setFieldStatus(data);
                    setError(null);
                })
                .catch(handleError)
                .finally(() => setIsLoading(false));
        }
    }, [permit?.id]);

    const handleExportSubmit = () => {
        return PermitApplicationService.fieldExport(permit.id)
            .then(data => {
                setError(null);
                setFieldStatus(data);
                navigate("", true);
            })
            .catch(handleError)
            .finally(() => setIsDialogOpen(false));
    };

    const handleForcedImportSubmit = () => {
        return PermitApplicationService.fieldImport(permit.id, true)
            .then(data => {
                setError(null);
                setFieldStatus(data);
                navigate("", true);
            })
            .catch(handleError)
            .finally(() => setIsDialogOpen(false));
    };

    const handleImportSubmit = () => {
        return PermitApplicationService.fieldImport(permit.id)
            .then(data => {
                setError(null);
                setFieldStatus(data);
                navigate("", true);
            })
            .catch(handleError)
            .finally(() => setIsDialogOpen(false));
    };

    const enableForcedImport = code => {
        setIsForcedImportEnabled(code === permit.code);
    };

    const handleError = error => {
        console.error(error);
        setError(error);
    };

    const nonRevertibleStatuses = useMemo(
        () => [
            "exported_not_changed",
            "exported_and_changed",
            "imported",
            "forced_imported",
        ],
        []
    );
    const isProcessRevertible = useMemo(
        () => !nonRevertibleStatuses.includes(fieldStatus),
        [nonRevertibleStatuses, fieldStatus]
    );

    return {
        fieldStatus: fieldStatus,
        forcedImport: isForcedImportEnabled,
        handleExportSubmit,
        handleForcedImportSubmit,
        handleImportSubmit,
        enableForcedImport,
        setIsDialogOpen,
        isDialogOpen,
        isProcessRevertible,
        isLoading,
        error,
    };
};

export {usePermitFieldStatus};
