import {FormSelectMultiple} from "base/form/components";
import {useDomain} from "snimf/domain/provider";

const PermitApplicationTypesFormField = ({disabled = false}) => {
    const {permitTypeDomain} = useDomain();

    return (
        <FormSelectMultiple
            name="types"
            label="Guias da autorização"
            options={permitTypeDomain}
            rules={{required: "Este campo é obrigatório."}}
            disabled={disabled}
        />
    );
};

export default PermitApplicationTypesFormField;
