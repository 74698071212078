import Alert from "@mui/material/Alert";

const StepperAlert = ({notices}) => {
    return notices?.length ? (
        <Alert severity="warning">
            <ul style={{marginBottom: 0, marginTop: 0}}>
                {notices.map((field, index) => (
                    <li key={index}>{field.label}</li>
                ))}
            </ul>
        </Alert>
    ) : null;
};

export default StepperAlert;
