import {useOutletContext} from "react-router-dom";

import {PermitApplicationHolderSection} from ".";
import {ContactsSection} from "contact/presentational";
import {FormContainer} from "base/form/components";
import {SectionCard} from "base/ui/section/components";
import Stack from "@mui/material/Stack";

const PermitApplicationHolderStep = () => {
    const {permit, isStepEditable} = useOutletContext();

    return (
        <Stack spacing={1}>
            <FormContainer>
                <SectionCard>
                    <PermitApplicationHolderSection
                        permit={permit}
                        isStepEditable={isStepEditable}
                    />
                </SectionCard>
            </FormContainer>
            <FormContainer>
                <SectionCard>
                    <ContactsSection permit={permit} allowEdit={isStepEditable} />
                </SectionCard>
            </FormContainer>
        </Stack>
    );
};

export default PermitApplicationHolderStep;
