// import {SectionDomainField, SectionField} from "../components";
import Checkbox from "@mui/material/Checkbox";
import {SectionField} from "../components";

const FieldUtil = {
    getValue(value, unit) {
        if (value && unit) {
            return `${value} ${unit}`;
        } else if (value && !unit) {
            return `${value}`;
        } else return "—";
    },

    getSectionField(
        label,
        value,
        unit = "",
        tooltipText = null,
        linkPath = null,
        isNumber = false
    ) {
        const valueCustomStyle = !value ? {fontStyle: "italic"} : {};

        return (
            <SectionField
                key={label}
                label={label}
                value={value || "—"}
                unit={value ? unit : null}
                tooltipText={tooltipText}
                linkPath={value ? linkPath : ""}
                textAlign={isNumber ? "right" : "unset"}
                valueCustomStyle={valueCustomStyle}
            />
        );
    },

    getSectionCheckBoxField(label, value) {
        return (
            <SectionField
                key={label}
                label={label}
                value={<Checkbox checked={value} disabled />}
                hideValueBox
            />
        );
    },

    // getSectionDomainField(label, value, domain) {
    //     // Remember to include <DomainProvider> in some place of parents components tree
    //     return <SectionDomainField label={label} value={value} fieldDomain={domain} />;
    // },
};

export default FieldUtil;
