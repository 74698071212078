import {useEffect, useState} from "react";
import {PermitService} from "permit/service";
import {useViolationsSummaryTable} from "violation/data";
import {SectionCard} from "base/ui/section/components";
import {SimpleTable} from "base/table/components";
import {NoDataContainer} from "base/shared/components";

const ApprovedPermitViolationsSection = ({permitId}) => {
    const {tableColumns} = useViolationsSummaryTable();
    const [violations, setViolations] = useState([]);

    useEffect(() => {
        PermitService.getViolations(permitId)
            .then(response => {
                setViolations(response);
            })
            .catch(error => console.log(error));
    }, [permitId]);

    return (
        <SectionCard title="Infracções">
            {violations.length ? (
                <SimpleTable dataRows={violations} tableColumns={tableColumns} />
            ) : (
                <NoDataContainer text="Esta autorização não tem Infracções." />
            )}
        </SectionCard>
    );
};

export default ApprovedPermitViolationsSection;
