import {ListProvider} from "base/entity/provider";
import {ModuleLayout} from "base/ui/module/components";
import {ModuleConfigProvider} from "base/ui/module/provider";
import {DomainProvider} from "snimf/domain/provider";
import {ViolationPageMenu} from "violation/menu";

const ViolationsModule = () => {
    return (
        <ModuleConfigProvider>
            <DomainProvider>
                <ListProvider>
                    <ModuleLayout menu={<ViolationPageMenu />} />
                </ListProvider>
            </DomainProvider>
        </ModuleConfigProvider>
    );
};

export default ViolationsModule;
