import {cloneElement} from "react";

import MapView from "../MapView";
import MapBaseRightBar from "./MapBaseRightBar";

import Stack from "@mui/material/Stack";

const MapBaseLayout = ({
    width = "100%",
    height = "600px",
    rightBar = null,
    rightBarOptions: {show: showRightBar = true} = {},
}) => {
    return (
        <Stack direction="row" sx={{width, height}}>
            <MapView />
            {rightBar ? (
                cloneElement(rightBar)
            ) : (
                <MapBaseRightBar show={showRightBar} />
            )}
        </Stack>
    );
};

export default MapBaseLayout;
