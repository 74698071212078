export const PERMIT_APPLICATION_STATUS = {
    holder_s: "Pendente dados titular (S)",
    permit_s: "Pendente preenchimento do pedido de autorização (S)",
    // incomplete_s: "Documentação incompleta (S)",
    analysis_m: "Analise documentação (MFCE-M)",
    plot1_m: "Pendente dados parcela (MFCE-M)",
    visit_m: "Pendente vistoria terreno (MFCE-M)",
    // plot2_m: "Pendente completar dados parcela (MFCE-M)",
    permit_m: "Pendente dados técnicos (MFCE-M)",
    report_m: "Pendente parecer técnico (MFCE-M)",
    payment_e: "Pendente valor de pagamento (MFCE-E)",
    invoice_s: "Pendente factura (S)",
    payment_s: "Pendente pagamento (S)",
    signature_s: "Pendente firma Direcção (S)",
    approved: "Aprovada",
    not_approved: "Não aprovada",
};
