import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {HolderService} from "holder/service";
import {holder_view_adapter} from "holder/model";
import {HolderForm, HolderInitialDataFormFields} from "holder/presentational/form";
import {FormSection} from "base/form/components";

const CreateHolderForm = ({onCancel = null}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];

    const [error, setError] = useState("");

    const handleFormSubmit = holder => {
        HolderService.create(holder_view_adapter({...holder}))
            .then(createdHolder => {
                navigate(`${basePath}/${createdHolder.id}/summary`);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleFormCancel = () => {
        onCancel ? onCancel() : navigate(`${basePath}/list`);
    };

    return (
        <HolderForm
            onSubmit={handleFormSubmit}
            onCancel={handleFormCancel}
            error={error}
        >
            <FormSection>
                <HolderInitialDataFormFields />
            </FormSection>
        </HolderForm>
    );
};

export default CreateHolderForm;
