import {useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "base/file/components";

const ViewPermitApplicationDocumentsSubPage = () => {
    let permit;
    [permit] = useOutletContext();

    return (
        permit && (
            <ViewDocumentsSubPage
                entity={permit}
                basePath={"/permits/applications/list"}
                disabled
            />
        )
    );
};

export default ViewPermitApplicationDocumentsSubPage;
