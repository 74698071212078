import {NumberUtil} from "base/format/utilities";
import {PermitTypeTags} from "permit/presentational";

export function usePermitTreesTable(displayFees) {
    const columnsForFees = [
        {
            id: "felling_price",
            label: "Valor abate",
            width: 10,
            textAlign: "right",
            formatFunction: item => NumberUtil.formatCurrency(item.felling_price),
        },
        {
            id: "cutting_price",
            label: "Valor serragem",
            width: 10,
            textAlign: "right",
            formatFunction: item => NumberUtil.formatCurrency(item.cutting_price),
        },
    ];

    const columnsWithoutFees = [
        {
            id: "types",
            label: "Guias",
            width: 10,
            textAlign: "right",
            formatFunction: item => (
                <PermitTypeTags
                    types={[item.felling && "felling", item.cutting && "cutting"]}
                    short
                />
            ),
        },
    ];

    const baseColumns = [
        {
            id: "code",
            label: "Código",
            width: 15,
        },
        {
            id: "species",
            label: "Espécie",
            width: 15,
        },
        {
            id: "quality",
            label: "Qualidade",
            width: 10,
        },
        {
            id: "height",
            label: "Altura",
            width: 10,
            textAlign: "right",
            formatFunction: item =>
                item.height ? `${NumberUtil.formatDecimal(item.height)} m` : null,
        },
        {
            id: "diameter",
            label: "Diámetro AP",
            width: 10,
            textAlign: "right",
            formatFunction: item =>
                item.diameter ? `${NumberUtil.formatDecimal(item.diameter)} m` : null,
        },
        {
            id: "volume",
            label: "Volume",
            width: 10,
            textAlign: "right",
            formatFunction: item =>
                item.volume ? `${NumberUtil.formatDecimal(item.volume)} m³` : null,
        },
        {
            id: "number_of_logs",
            label: "Nº toros",
            width: 10,
            textAlign: "right",
        },
    ];

    const tableColumns = [
        ...baseColumns,
        ...(displayFees ? columnsForFees : columnsWithoutFees),
    ].filter(column => column !== null);

    return {tableColumns};
}
