import {useState} from "react";
import {useLocation, useOutletContext} from "react-router-dom";

import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";
import {FileUpload} from "base/file/utilities";
import {useNavigateWithReload} from "base/navigation/hooks";

import {FormCheckbox, FormContainer} from "base/form/components";
import {
    PermitApplicationTypesFormField,
    PermitApplicationDocFormFields,
    PermitForm,
} from "./form";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

const PermitApplicationTypesAndDocStep = () => {
    const {permit, isStepEditable} = useOutletContext();
    const [error, setError] = useState(null);

    const navigate = useNavigateWithReload();
    const basePath = useLocation();

    const fileFields = [
        "application_doc",
        "personal_id_doc",
        "plot_ownership_doc",
        "plot_use_authorization",
        "tax_id_doc",
    ];

    const handleFormSubmit = (updatedPermit, callback = null) => {
        const objectContainsFiles = Object.values(updatedPermit).some(
            value => value instanceof File
        );

        if (!objectContainsFiles) {
            updatePermitApplication(updatedPermit).then(() => {
                FileUpload.callCallback(callback, true);
                navigate(basePath, true);
            });
        } else {
            FileUpload.uploadFiles(updatedPermit, permit, fileFields)
                .then(fileUploadResults => {
                    FileUpload.updateFormWithFileIds(
                        updatedPermit,
                        fileUploadResults,
                        fileFields
                    );
                    return updatePermitApplication(updatedPermit);
                })
                .then(() => {
                    FileUpload.callCallback(callback, true);
                    navigate(basePath, true);
                })
                .catch(fileError => {
                    FileUpload.callCallback(callback, false);
                    console.log({fileError});
                    setError(fileError.error);
                });
        }
    };

    const updatePermitApplication = updatedPermit => {
        return PermitApplicationService.update(
            permit_view_adapter(updatedPermit)
        ).catch(error => {
            console.error(error);
            setError(error);
        });
    };

    return (
        <PermitForm permit={permit} onSubmit={handleFormSubmit} error={error}>
            <Stack spacing={1}>
                <FormContainer>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={8}>
                            <PermitApplicationTypesFormField
                                disabled={!isStepEditable}
                            />
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end">
                            <FormCheckbox
                                name="is_the_plot_owner"
                                label="O titular é propriétario/a da parcela"
                                disabled={!isStepEditable}
                            />
                        </Grid>
                    </Grid>
                </FormContainer>
                <FormContainer>
                    <PermitApplicationDocFormFields disabled={!isStepEditable} />
                </FormContainer>
            </Stack>
        </PermitForm>
    );
};

export default PermitApplicationTypesAndDocStep;
