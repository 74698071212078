class Trees extends Array {}

const tree_api_adapter = tree => {
    return tree;
};

const trees_api_adapter = trees => {
    return trees.map(tree_api_adapter);
};

const tree_view_adapter = tree => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside tree object

    tree["felling"] = !!tree["felling"];
    tree["cutting"] = !!tree["cutting"];
    if (!tree["cutting"]) tree["number_of_logs"] = 0;

    delete tree["created_by"];
    delete tree["updated_by"];
    delete tree["created_at"];
    delete tree["updated_at"];

    return tree;
};

const createTrees = (data = []) => {
    const trees = Trees.from(data, tree => createTree(tree));
    return trees;
};

const createTree = ({
    id = -1,
    code = "",
    species = "",
    quality = "",
    felling = null,
    cutting = null,
    height = null,
    diameter = null,
    volume = null,
    felling_fee = null,
    felling_price = null,
    number_of_logs = null,
    cutting_fee = null,
    cutting_price = null,
    geom = null,
    permit = null,
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        code,
        species,
        quality,
        felling,
        cutting,
        height,
        diameter,
        volume,
        felling_fee,
        felling_price,
        number_of_logs,
        cutting_fee,
        cutting_price,
        geom,
        permit,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createTree as default,
    createTrees,
    tree_api_adapter,
    trees_api_adapter,
    tree_view_adapter,
};
