import {FieldUtil} from "base/ui/section/utilities";
import {SectionLabel, SectionValueBox} from "base/ui/section/components";
import Grid from "@mui/material/Grid";

const ContactData = ({contact}) => {
    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Telefone", contact.phone)}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("E-mail", contact.email)}
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={2.5}>
                    <SectionLabel label="Observações" />
                </Grid>
                <SectionValueBox value={contact.comments} size={9.5} />
            </Grid>
        </Grid>
    );
};

export default ContactData;
