import {MapBaseLayersProvider} from "base/map/layer";
import {createBasePlotLayerConfig, useSnimfMapConfig} from "snimf/map";
import {createPlotLayerConfig} from "plot/map";
import {MapProvider} from "base/map";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const PlotsMapProvider = ({children}) => {
    // map config
    const {crs, crsType, tocOptions, mapOptions, baseLayers} = useSnimfMapConfig();

    const plotLayerConfig = createPlotLayerConfig({label: "Parcelas", fitBounds: true});

    const basePlotLayerConfig = createBasePlotLayerConfig();

    // end map config

    return (
        <MapProvider
            crs={crs}
            crsType={crsType}
            baseLayers={baseLayers}
            tocOptions={tocOptions}
            mapOptions={mapOptions}
        >
            <MapBaseLayersProvider
                layers={[plotLayerConfig]}
                auxLayers={[basePlotLayerConfig]}
            >
                {children}
            </MapBaseLayersProvider>
        </MapProvider>
    );
};
export default PlotsMapProvider;
