import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil} from "base/format/utilities";
import Grid from "@mui/material/Grid";

const PlantDataFields = ({plant}) => {
    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Nome científico", plant.scientific_name)}
                {FieldUtil.getSectionField("Quantidade de plantas", plant.quantity)}
                {FieldUtil.getSectionField("Viveiro de origem", plant.source)}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Data de plantio da semente",
                    DateUtil.formatDate(plant.sowing_date)
                )}
                {FieldUtil.getSectionField(
                    "Data de germinação",
                    DateUtil.formatDate(plant.germination_date)
                )}
                {FieldUtil.getSectionField(
                    "Data de transplante",
                    DateUtil.formatDate(plant.transplant_date)
                )}
            </Grid>
        </Grid>
    );
};

export default PlantDataFields;
