import {ViewDocumentPanel} from "base/file/components";
import {Navigate, Route} from "react-router-dom";
import {
    ListPlantationsPage,
    ViewPlantationDocumentsSubPage,
    ViewPlantationHolderSubPage,
    ViewPlantationPage,
    ViewPlantationPlantsSubPage,
    ViewPlantationPlotSubPage,
    ViewPlantationSummarySubPage,
} from "plantation/container";

const plantationRoutes = [
    <Route key="plantation-manage" path="all/list">
        <Route key="plantations-list" path="" element={<ListPlantationsPage />} />
        <Route key="plantation-detail" path=":id" element={<ViewPlantationPage />}>
            <Route
                key="plantation-summary"
                path="summary"
                element={<ViewPlantationSummarySubPage />}
            />
            <Route
                key="plantation-holder"
                path="holder"
                element={<ViewPlantationHolderSubPage />}
            />
            <Route
                key="plantation-plot"
                path="plot"
                element={<ViewPlantationPlotSubPage />}
            />
            <Route
                key="plantation-plants"
                path="plants"
                element={<ViewPlantationPlantsSubPage />}
            />
            <Route
                key="plantation-documents"
                path="documents/*"
                element={<ViewPlantationDocumentsSubPage />}
            >
                <Route
                    key="plantation-documents-view"
                    path="detail/*"
                    element={<ViewDocumentPanel />}
                />
            </Route>
            <Route index element={<Navigate to="summary" replace />} />
        </Route>
    </Route>,

    <Route
        key="redirect-plantation-index"
        index
        element={<Navigate to="list" replace />}
    />,
];

export default plantationRoutes;
