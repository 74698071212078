import {useState} from "react";

import {ContactService} from "contact/service";
import {contact_view_adapter} from "contact/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {ContactForm} from "contact/presentational/form";
import {AddNewInlineItemButton, AddNewInlineItemFormBox} from "base/shared/components";

const CreateContactDataContent = ({permitId}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("button");
    const [error, setError] = useState(null);

    const handleFormSubmit = contact => {
        ContactService.create(contact_view_adapter(contact))
            .then(createdContact => {
                setMode("button");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    return mode === "button" ? (
        <AddNewInlineItemButton
            onClick={() => {
                setMode("create");
            }}
            label="Acrescentar contacto"
        />
    ) : mode === "create" ? (
        <AddNewInlineItemFormBox label="Acrescentar contacto">
            <ContactForm
                permitId={permitId}
                onSubmit={handleFormSubmit}
                onCancel={() => {
                    setMode("button");
                }}
                error={error}
            />
        </AddNewInlineItemFormBox>
    ) : null;
};

export default CreateContactDataContent;
