import {useState} from "react";
import {PlotSummary} from "..";
import {PlotSearchAutocomplete} from ".";
import Grid from "@mui/material/Grid";

const PlotFormSearch = ({onClickSelected = null}) => {
    const [existingPlot, setExistingPlot] = useState(null);

    const handleSelectExistingPlot = plot => {
        if (onClickSelected) {
            onClickSelected(plot);
        }
        setExistingPlot(plot);
    };

    return (
        <Grid container rowSpacing={1} mt={1}>
            <Grid item xs={12}>
                <PlotSearchAutocomplete onSelect={handleSelectExistingPlot} />
            </Grid>
            <Grid item xs={12}>
                {existingPlot && <PlotSummary plot={existingPlot} />}
            </Grid>
        </Grid>
    );
};

export default PlotFormSearch;
