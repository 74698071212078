import {usePermitApplicationProcessUrl} from "permitapplication/hooks";
import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil} from "base/format/utilities";

import {PermitTypeTags} from "permit/presentational";
import {SectionCard} from "base/ui/section/components";
import Grid from "@mui/material/Grid";

const PermitApplicationGeneralDataSection = ({permit}) => {
    const basePath = `/permits/applications/list/${permit?.id}`;

    const {getProcessPath} = usePermitApplicationProcessUrl(permit, basePath);
    const processPath = getProcessPath(permit, basePath);

    return (
        <SectionCard title="Informações gerais">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {FieldUtil.getSectionField("Código", permit.code)}
                    {FieldUtil.getSectionField(
                        "Estado",
                        permit.status,
                        null,
                        null,
                        processPath
                    )}
                </Grid>
                <Grid item xs={6}>
                    {FieldUtil.getSectionField(
                        "Data de solicitação",
                        DateUtil.formatDate(permit.application_date)
                    )}
                    {FieldUtil.getSectionField(
                        "Guias",
                        permit.types.length ? (
                            <PermitTypeTags types={permit.types} />
                        ) : null
                    )}
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default PermitApplicationGeneralDataSection;
