import {FieldUtil} from "base/ui/section/utilities";
import Grid from "@mui/material/Grid";

const PlotGeneralDataSection = ({plot}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Número", plot?.number)}
                {FieldUtil.getSectionField(
                    "Área do título de terras",
                    plot?.official_area,
                    "m²"
                )}
                {FieldUtil.getSectionField(
                    "Classificação por tamanho",
                    plot?.land_size
                )}
                {FieldUtil.getSectionCheckBoxField(
                    "Zona de protecção",
                    plot?.is_area_protected
                )}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Código", plot?.code)}
                {FieldUtil.getSectionField("Área da geometria", plot?.geom_area, "m²")}
                {FieldUtil.getSectionField(
                    "Cobertura do solo",
                    plot?.land_cover?.length ? plot.land_cover.join(", ") : null
                )}
            </Grid>
        </Grid>
    );
};

export default PlotGeneralDataSection;
