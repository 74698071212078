import {FormProvider, useForm} from "react-hook-form";

import {FormUtil} from "base/form/utilities";
import {EntityForm} from "base/entity/components/form";
import {AlertError} from "base/error/components";
import Stack from "@mui/material/Stack";

const HolderForm = ({
    holder = null,
    onSubmit,
    onCancel = null,
    error = null,
    children,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(holder?.id),
        type: FormUtil.getFormValue(holder?.type),
        name: FormUtil.getFormValue(holder?.name),
        address: FormUtil.getFormValue(holder?.address),
        representative: FormUtil.getFormValue(holder?.representative),
        gender: FormUtil.getFormValue(holder?.gender),
        doc_type: FormUtil.getFormValue(holder?.doc_type),
        doc_number: FormUtil.getFormValue(holder?.doc_number),
        doc_expiration_date: FormUtil.getFormValue(holder?.doc_expiration_date),
        comments: FormUtil.getFormValue(holder?.comments),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const {
        formState: {isDirty},
    } = formMethods;

    const handleFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            type: FormUtil.getDataValue(data.type),
            name: FormUtil.getDataValue(data.name),
            address: FormUtil.getDataValue(data.address),
            representative: FormUtil.getDataValue(data.representative),
            gender: FormUtil.getDataValue(data.gender),
            doc_type: FormUtil.getDataValue(data.doc_type),
            doc_number: FormUtil.getDataValue(data.doc_number),
            doc_expiration_date: FormUtil.getDataValue(data.doc_expiration_date),
            comments: FormUtil.getDataValue(data.comments),
        });
    };

    return (
        <FormProvider {...formMethods}>
            <Stack spacing={1}>
                <AlertError error={error} />
                <EntityForm
                    onSubmit={formMethods.handleSubmit(handleFormSubmit)}
                    onCancel={onCancel}
                    isFormDirty={isDirty}
                >
                    {children}
                </EntityForm>
            </Stack>
        </FormProvider>
    );
};

export default HolderForm;
