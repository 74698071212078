import {HolderService} from "holder/service";
import {SearchAutocomplete} from "base/search/components";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const HolderSearchAutocomplete = ({
    onChangeHandler = null,
    label = "Titular",
    rules = {},
}) => {
    const optionComponent = option => {
        return (
            <Stack>
                <Typography>{option.name}</Typography>
                <Typography variant="caption" sx={{ml: 1}}>
                    {option.type}
                </Typography>
            </Stack>
        );
    };

    return (
        <SearchAutocomplete
            name="holder"
            label={label}
            optionLabel="name"
            optionComponent={optionComponent}
            search={HolderService.getBySearchText}
            onChangeHandler={onChangeHandler}
            rules={rules}
        />
    );
};

export default HolderSearchAutocomplete;
