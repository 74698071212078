import {useOutletContext} from "react-router-dom";

import {EntityViewSubPage} from "base/entity/components/container";
import {ViewOrUpdateViolationActionsContent} from ".";
import {useEntityPermissions} from "snimf/permissions/hooks";

const ViewViolationActionsSubPage = () => {
    const context = useOutletContext();
    const violation = context[0];

    const {allowEdit} = useEntityPermissions(violation, "violations");

    let sections = [
        <ViewOrUpdateViolationActionsContent
            key="actions"
            violation={violation}
            allowEdit={allowEdit}
        />,
    ];

    return violation && <EntityViewSubPage sections={sections} />;
};

export default ViewViolationActionsSubPage;
