import {createEntityService} from "base/entity/service";
import {AuthApiService} from "base/api/service";
import {createPlot, createPlots, plot_api_adapter, plots_api_adapter} from "plot/model";
import {createPermits, permits_api_adapter} from "permit/model";
import {createViolations, violations_api_adapter} from "violation/model";
import {createPlantations, plantations_api_adapter} from "plantation/model";

const basePath = "/api/app/plots";

const entityService = createEntityService(
    basePath,
    createPlot,
    createPlots,
    plot_api_adapter,
    plots_api_adapter
);

const PlotService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeature(id) {
        return entityService.getFeature(id);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    getPermits(holderId) {
        return AuthApiService.get(`${basePath}/${holderId}/permits`).then(response => {
            return createPermits(permits_api_adapter(response));
        });
    },

    getViolations(holderId) {
        return AuthApiService.get(`${basePath}/${holderId}/violations`).then(
            response => {
                return createViolations(violations_api_adapter(response));
            }
        );
    },

    getPlantations(holderId) {
        return AuthApiService.get(`${basePath}/${holderId}/plantations`).then(
            response => {
                return createPlantations(plantations_api_adapter(response));
            }
        );
    },

    get(id) {
        return entityService.get(id);
    },

    create(provider) {
        return entityService.create(provider);
    },

    update(provider) {
        return entityService.update(provider);
    },

    updateFeature(id, feature) {
        return entityService.updateFeature(id, feature);
    },

    canBeDeleted(id) {
        return entityService.canBeDeleted(id).then(response => {
            if (response.result === "success") {
                return [true, ""];
            }
            return [false, response.detail];
        });
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default PlotService;
