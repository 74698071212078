import {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useAuth} from "base/user/provider";
import {PermitService} from "permit/service";

import {
    ApprovedPermitGeneralDataSection,
    ApprovedPermitViolationsSection,
} from "permit/presentational";
import {EntityViewSubPage} from "base/entity/components/container";
import {
    EntityAuditSection,
    EntityCommentsSection,
} from "base/entity/components/presentational/sections";
import {
    DeleteEntityButton,
    DeleteEntityDialog,
    DeleteItemDialog,
} from "base/delete/components";

const ViewApprovedPermitSummarySubPage = () => {
    const context = useOutletContext();
    const permit = context[0];

    const auth = useAuth();
    const navigate = useNavigate();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletionError, setDeletionError] = useState(null);
    const [canBeDeletedMsg, setCanBeDeletedMsg] = useState("");

    const allowDelete = auth.hasPermission("delete-permits");

    useEffect(() => {
        setDeletionError(null);
    }, []);

    const handleCanBeDeleted = () => {
        PermitService.canBeDeleted(permit.id)
            .then(result => {
                if (result[0]) {
                    handleDelete();
                } else {
                    setCanBeDeletedMsg(result[1]);
                    setDeletionError(result[1]);
                }
            })
            .catch(error => {
                setCanBeDeletedMsg(error);
                setDeletionError(error);
            });
    };

    const handleDelete = () => {
        PermitService.delete(permit.id)
            .then(() => {
                navigate("/permits/approved/list");
            })
            .catch(error => {
                setCanBeDeletedMsg(error);
                setDeletionError(error);
            });
    };

    const sections = [
        <ApprovedPermitGeneralDataSection key="generaldata" permit={permit} />,
        <ApprovedPermitViolationsSection key="violations" permitId={permit?.id} />,
        <EntityCommentsSection
            key="comments"
            comments={permit?.comments}
            displayCard
        />,
        <EntityAuditSection key="audit" entity={permit} />,
    ];

    return (
        permit && (
            <EntityViewSubPage sections={sections}>
                {allowDelete ? (
                    <>
                        <DeleteEntityButton
                            openDialog={() => setIsDeleteDialogOpen(true)}
                            entityName={"autorização"}
                        />
                        <DeleteItemDialog
                            isDialogOpen={isDeleteDialogOpen}
                            setIsDialogOpen={setIsDeleteDialogOpen}
                            deletionError={""}
                            onDelete={handleCanBeDeleted}
                        />
                        <DeleteEntityDialog
                            msg={canBeDeletedMsg}
                            setMsg={setCanBeDeletedMsg}
                            deletionError={deletionError}
                            onDelete={handleDelete}
                        />
                    </>
                ) : null}
            </EntityViewSubPage>
        )
    );
};

export default ViewApprovedPermitSummarySubPage;
