import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

import {useConfigModule} from "base/ui/module/provider";
import {LocationProvider} from "snimf/location/provider";
import {ViolationService} from "violation/service";

import {ViolationSubPageMenu} from "violation/menu";
import {PageLayout} from "base/ui/main";

const ViewViolationPage = () => {
    const {id} = useParams();

    const [violation, setViolation] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();

    const {setModuleBasePath, isLoading, setIsLoading} = useConfigModule();

    useEffect(() => {
        setIsLoading(true);
        setViolation(null);
        setModuleBasePath(`/violations/all/list/${id}`);
        ViolationService.get(id)
            .then(data => {
                setViolation(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setError(error);
                setIsLoading(false);
            });
    }, [id, location.state?.lastRefreshDate]);

    return (
        <LocationProvider>
            <PageLayout
                menu={<ViolationSubPageMenu violation={violation} />}
                context={[violation]}
                isLoading={isLoading}
                error={error}
                subPage
            />
        </LocationProvider>
    );
};

export default ViewViolationPage;
