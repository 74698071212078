import {FormProvider, useForm} from "react-hook-form";

import {LocationProvider} from "snimf/location/provider";
import {FormUtil} from "base/form/utilities";

import {EntityForm} from "base/entity/components/form";
import {AlertError} from "base/error/components";
import Stack from "@mui/material/Stack";

const ViolationForm = ({
    violation = null,
    onSubmit,
    onCancel = null,
    error = null,
    children,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(violation?.id),
        code: FormUtil.getFormValue(violation?.code),
        on_date: FormUtil.getFormValue(violation?.on_date),
        district: FormUtil.getFormValue(violation?.district),
        status: FormUtil.getFormValue(violation?.status),
        forest_material: FormUtil.getFormValue(violation?.forest_material),
        chainsaw: FormUtil.getFormValue(violation?.chainsaw),
        vehicle: FormUtil.getFormValue(violation?.vehicle),
        forest_material_destination: FormUtil.getFormValue(
            violation?.forest_material_destination
        ),
        chainsaw_destination: FormUtil.getFormValue(violation?.chainsaw_destination),
        vehicle_destination: FormUtil.getFormValue(violation?.vehicle_destination),
        sale_amount: FormUtil.getFormValue(violation?.sale_amount),
        sale_receipt: FormUtil.getFormValue(violation?.sale_receipt),
        sale_buyer: FormUtil.getFormValue(violation?.sale_buyer),
        penalty_paid: FormUtil.getFormValue(violation?.penalty_paid),
        penalty_amount: FormUtil.getFormValue(violation?.penalty_amount),
        penalty_receipt: FormUtil.getFormValue(violation?.penalty_receipt),
        inspection_team: FormUtil.getFormValue(violation?.inspection_team),
        driver: FormUtil.getFormValue(violation?.driver),
        officers: FormUtil.getFormValue(violation?.officers),
        comments: FormUtil.getFormValue(violation?.comments),
        geom: FormUtil.getFormValue(violation?.geom),
        types: FormUtil.getFormValue(violation?.types, []),
        actions: FormUtil.getFormValue(violation?.actions, []),
        permit: FormUtil.getFormValue(violation?.permit),
        holder: FormUtil.getFormValue(violation?.holder),
        plot: FormUtil.getFormValue(violation?.plot),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const handleFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            code: FormUtil.getDataValue(data.code),
            on_date: FormUtil.getDataValue(data.on_date),
            district: FormUtil.getDataValue(data.district),
            status: FormUtil.getDataValue(data.status),
            forest_material: FormUtil.getDataValue(data.forest_material),
            chainsaw: FormUtil.getDataValue(data.chainsaw),
            vehicle: FormUtil.getDataValue(data.vehicle),
            forest_material_destination: FormUtil.getDataValue(
                data.forest_material_destination
            ),
            chainsaw_destination: FormUtil.getDataValue(data.chainsaw_destination),
            vehicle_destination: FormUtil.getDataValue(data.vehicle_destination),
            sale_amount: FormUtil.getDataValue(data.sale_amount),
            sale_receipt: FormUtil.getDataValue(data.sale_receipt),
            sale_buyer: FormUtil.getDataValue(data.sale_buyer),
            penalty_paid: FormUtil.getDataValue(data.penalty_paid),
            penalty_amount: FormUtil.getDataValue(data.penalty_amount),
            penalty_receipt: FormUtil.getDataValue(data.penalty_receipt),
            inspection_team: FormUtil.getDataValue(data.inspection_team),
            driver: FormUtil.getDataValue(data.driver),
            officers: FormUtil.getDataValue(data.officers),
            comments: FormUtil.getDataValue(data.comments),
            geom: FormUtil.getDataValue(data.geom),
            types: FormUtil.getDataValue(data.types),
            actions: FormUtil.getDataValue(data.actions),
            permit: FormUtil.getDataValue(data.permit),
            holder: FormUtil.getDataValue(data.holder),
            plot: FormUtil.getDataValue(data.plot),
        });
    };

    return (
        <LocationProvider>
            <FormProvider {...formMethods}>
                <Stack spacing={1}>
                    <AlertError error={error} />
                    <EntityForm
                        onSubmit={formMethods.handleSubmit(handleFormSubmit)}
                        onCancel={onCancel}
                    >
                        {children}
                    </EntityForm>
                </Stack>
            </FormProvider>
        </LocationProvider>
    );
};

export default ViolationForm;
