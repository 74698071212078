import {FieldUtil} from "base/ui/section/utilities";

import Grid from "@mui/material/Grid";

const HolderGeneralDataSection = ({holder, linkInName = false}) => {
    const isBusiness = holder?.type === "Empresa";
    const genderLabel = isBusiness ? "Gênero do representante" : "Gênero";

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {linkInName
                    ? FieldUtil.getSectionField(
                          "Nome",
                          holder?.name,
                          null,
                          null,
                          `/holders/all/list/${holder?.id}/summary`
                      )
                    : FieldUtil.getSectionField("Nome", holder.name)}
                {FieldUtil.getSectionField("Morada", holder.address)}
                {isBusiness
                    ? FieldUtil.getSectionField("Representante", holder.representative)
                    : null}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Tipo", holder.type)}
                {FieldUtil.getSectionField(genderLabel, holder.gender)}
            </Grid>
        </Grid>
    );
};

export default HolderGeneralDataSection;
