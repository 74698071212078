import {
    PermitApplicationClearingFormFields,
    PermitApplicationCuttingFormFields,
    PermitApplicationFellingFormFields,
    PermitApplicationTransportFormFields,
    PermitForm,
} from "./form";
import {DividerWithHeading} from "base/ui/headings/components";
import Stack from "@mui/material/Stack";

const PermitApplicationTypesContent = ({
    permit,
    isStepEditable,
    onSubmitPermitApplication,
    error = null,
}) => {
    const sections = [
        {
            label: "Abate",
            content: <PermitApplicationFellingFormFields disabled={!isStepEditable} />,
            hide: !permit?.types?.includes("felling"),
        },
        {
            label: "Serragem",
            content: <PermitApplicationCuttingFormFields disabled={!isStepEditable} />,
            hide: !permit?.types?.includes("cutting"),
        },
        {
            label: "Desbravamento",
            content: <PermitApplicationClearingFormFields disabled={!isStepEditable} />,
            hide: !permit?.types?.includes("clearing"),
        },
        {
            label: "Transporte",
            content: (
                <PermitApplicationTransportFormFields disabled={!isStepEditable} />
            ),
            hide: !permit?.types?.includes("transport"),
        },
    ];

    return (
        <PermitForm permit={permit} onSubmit={onSubmitPermitApplication} error={error}>
            {sections.map((section, index) =>
                !section.hide ? (
                    <Stack key={index} mt={3}>
                        <DividerWithHeading label={section.label} />
                        {section.content}
                    </Stack>
                ) : null
            )}
        </PermitForm>
    );
};

export default PermitApplicationTypesContent;
