import {
    createMaterial,
    createMaterials,
    material_api_adapter,
    materials_api_adapter,
} from "material/model";
import {createEntityService} from "base/entity/service";

const basePath = "/api/app/materials";

const entityService = createEntityService(
    basePath,
    createMaterial,
    createMaterials,
    material_api_adapter,
    materials_api_adapter
);

const MaterialService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(provider) {
        return entityService.create(provider);
    },

    update(provider) {
        return entityService.update(provider);
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default MaterialService;
