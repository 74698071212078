import {MaterialDataFeesFormFields, MaterialDataInitialFormFields} from ".";
import Grid from "@mui/material/Grid";

const MaterialDataFormFields = ({material, displayFees}) => {
    return (
        <Grid container direction="row" columnSpacing={1}>
            {displayFees ? (
                <MaterialDataFeesFormFields material={material} />
            ) : (
                <MaterialDataInitialFormFields />
            )}
        </Grid>
    );
};

export default MaterialDataFormFields;
