import {AuthApiService} from "base/api/service";

const locationBasePath = "/api/app/locations/";

const LocationService = {
    getAdministrativeDivisions() {
        return AuthApiService.get(locationBasePath).then(response => {
            const administrativeDivisions = {
                islands: [
                    ...new Map(
                        response.map(item => [
                            item["island"],
                            {value: item.island, label: item.island},
                        ])
                    ).values(),
                ],
                districts: [
                    ...new Map(
                        response.map(item => [
                            `${item["island"]}${item["district"]}`,
                            {
                                value: item.district,
                                label: item.district,
                                island_code: item.island,
                            },
                        ])
                    ).values(),
                ],
                subdistricts: response.map(item => ({
                    value: item.name,
                    label: item.name,
                    island_code: item.island,
                    district_code: item.district,
                })),
            };

            administrativeDivisions.islands.sort((a, b) =>
                a.label.localeCompare(b.label)
            );
            administrativeDivisions.districts.sort((a, b) =>
                a.label.localeCompare(b.label)
            );
            administrativeDivisions.subdistricts.sort((a, b) =>
                a.label.localeCompare(b.label)
            );
            return administrativeDivisions;
        });
    },
};

export default LocationService;
