import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {PlantationService} from "plantation/service";
import {plantation_view_adapter} from "plantation/model";
import {
    PlantationForm,
    PlantationInitialFormFields,
} from "plantation/presentational/form";
import {FormSection} from "base/form/components";

const CreatePlantationForm = ({onCancel = null}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];

    const [error, setError] = useState("");

    const handleFormSubmit = plantation => {
        PlantationService.create(plantation_view_adapter({...plantation}))
            .then(createdPlantation => {
                navigate(`${basePath}/${createdPlantation.id}/summary`);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleFormCancel = () => {
        onCancel ? onCancel() : navigate(`${basePath}/list`);
    };

    return (
        <PlantationForm
            onSubmit={handleFormSubmit}
            onCancel={handleFormCancel}
            error={error}
        >
            <FormSection>
                <PlantationInitialFormFields />
            </FormSection>
        </PlantationForm>
    );
};

export default CreatePlantationForm;
