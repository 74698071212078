import {usePermitTreesTable} from "tree/data";
import {CreateTreeDataContent} from "tree/container";
import {SectionCard} from "base/ui/section/components";
import {SimpleTable} from "base/table/components";
import {NoDataContainer} from "base/shared/components";
import Stack from "@mui/system/Stack";

const ViewTreesTable = ({permitId, trees, displayFees, disabled}) => {
    const {tableColumns} = usePermitTreesTable(displayFees);

    return (
        <SectionCard>
            <Stack spacing={3} mt={2}>
                {trees.length ? (
                    <SimpleTable dataRows={trees} tableColumns={tableColumns} />
                ) : (
                    <NoDataContainer text="Esta solicitação ainda não tem árvores." />
                )}

                {!disabled ? (
                    <CreateTreeDataContent
                        permitId={permitId}
                        displayFees={displayFees}
                    />
                ) : null}
            </Stack>
        </SectionCard>
    );
};

export default ViewTreesTable;
