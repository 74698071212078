import {useState} from "react";
import {useLocation, useOutletContext} from "react-router-dom";
import {useNavigateWithReload} from "base/navigation/hooks";
import {FileUpload} from "base/file/utilities";
import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";

import {
    PermitApplicationReportFormFields,
    PermitApplicationDecisionFormFields,
    PermitForm,
} from "./form";
import {FormContainer} from "base/form/components";
import Stack from "@mui/material/Stack";

const PermitApplicationReportStep = () => {
    const {permit, isStepEditable} = useOutletContext();
    const [error, setError] = useState(null);

    const navigate = useNavigateWithReload();
    const basePath = useLocation();

    const fileFields = ["technical_report"];

    const handleFormSubmit = (updatedPermit, callback = null) => {
        const objectContainsFiles = Object.values(updatedPermit).some(
            value => value instanceof File
        );

        if (!objectContainsFiles) {
            updatePermitApplication(updatedPermit).then(() => {
                FileUpload.callCallback(callback, true);
                navigate(basePath, true);
            });
        } else {
            FileUpload.uploadFiles(updatedPermit, permit, fileFields)
                .then(fileUploadResults => {
                    FileUpload.updateFormWithFileIds(
                        updatedPermit,
                        fileUploadResults,
                        fileFields
                    );
                    return updatePermitApplication(updatedPermit);
                })
                .then(() => {
                    FileUpload.callCallback(callback, true);
                    navigate(basePath, true);
                })
                .catch(fileError => {
                    FileUpload.callCallback(callback, false);
                    console.log({fileError});
                    setError(fileError.error);
                });
        }
    };

    const updatePermitApplication = updatedPermit => {
        return PermitApplicationService.update(
            permit_view_adapter(updatedPermit)
        ).catch(error => {
            console.error(error);
            setError(error);
        });
    };

    return (
        <PermitForm permit={permit} onSubmit={handleFormSubmit} error={error}>
            <Stack spacing={1}>
                <FormContainer>
                    <PermitApplicationReportFormFields disabled={!isStepEditable} />
                </FormContainer>
                <FormContainer>
                    <PermitApplicationDecisionFormFields disabled={!isStepEditable} />
                </FormContainer>
            </Stack>
        </PermitForm>
    );
};

export default PermitApplicationReportStep;
