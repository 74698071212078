import {PlotLandFormCombo} from ".";
import {HolderSearchAutocomplete} from "holder/presentational";
import Grid from "@mui/material/Grid";

const PlotOwnershipDataFormFields = () => {
    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={12}>
                <HolderSearchAutocomplete
                    label="Proprietário/a"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={12}>
                <PlotLandFormCombo />
            </Grid>
        </Grid>
    );
};

export default PlotOwnershipDataFormFields;
