/**
 * (c) Wong Hoi Sing Edison <hswong3i@pantarei-design.com>
 * (c) 2023. iMap <info@imap.es>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import L from "leaflet";
import "./ResetView.css";
import "./redo-solid.svg";

const ResetView = L.Control.extend({
    options: {
        position: "topleft",
        title: "Reset view",
        latlng: null,
        zoom: null,
    },

    onAdd: function (map) {
        this._map = map;

        this._container = L.DomUtil.create(
            "div",
            "leaflet-control-resetview leaflet-bar leaflet-control"
        );
        this._link = L.DomUtil.create(
            "a",
            "leaflet-bar-part leaflet-bar-part-single",
            this._container
        );
        this._link.title = this.options.title;
        this._link.href = "#";
        this._link.setAttribute("role", "button");
        this._icon = L.DomUtil.create(
            "span",
            "leaflet-control-resetview-icon",
            this._link
        );

        L.DomEvent.on(this._link, "click", this._resetView, this);

        return this._container;
    },

    onRemove: function (map) {
        L.DomEvent.off(this._link, "click", this._resetView, this);
    },

    _resetView: function (e) {
        this._map.setView(this.options.latlng, this.options.zoom);
    },
});

export default ResetView;
