import {FormInputText} from "base/form/components";
import Grid from "@mui/material/Grid";

const ContactFormDataFields = () => {
    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormInputText
                    name="name"
                    label="Nome"
                    rules={{required: "Este campo é obrigatório"}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="phone" label="Telefone" maxLength={20} />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="email" label="E-mail" />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="comments" label="Observações" />
            </Grid>
        </Grid>
    );
};

export default ContactFormDataFields;
