import {TabContainer} from "base/ui/tab/components";
import {ViewTreesSection} from "tree/container";
import {ViewMaterialsSection} from "material/container";
import {PermitApplicationTypesContent} from ".";

const PermitApplicationTechnicalDataTabs = ({
    permit,
    isStepEditable,
    onSubmitPermitApplication,
    error = null,
}) => {
    const tabs = [
        {
            label: "Guias",
            hash: "types",
            content: (
                <PermitApplicationTypesContent
                    permit={permit}
                    isStepEditable={isStepEditable}
                    onSubmitPermitApplication={onSubmitPermitApplication}
                    error={error}
                />
            ),
            disabled: false,
        },
        {
            label: "Árvores",
            hash: "trees",
            content: (
                <ViewTreesSection
                    permit={permit}
                    displayFees={false}
                    disabled={!isStepEditable}
                />
            ),
            disabled:
                !permit?.types?.includes("felling") &&
                !permit?.types?.includes("cutting"),
        },
        {
            label: "Materiais",
            hash: "materials",
            content: (
                <ViewMaterialsSection
                    permit={permit}
                    displayFees={false}
                    disabled={!isStepEditable}
                />
            ),
            disabled: !permit?.types?.includes("transport"),
        },
    ];

    return <TabContainer tabs={tabs} />;
};

export default PermitApplicationTechnicalDataTabs;
