import {useState, useEffect, useCallback} from "react";
import {useLocation} from "react-router-dom";

import {useAuth} from "base/user/provider";
import {useDistrictIsland} from "snimf/location/hooks";

import {PERMIT_APPLICATION_STATUS} from "permitapplication/data";
import {RouterUtil} from "base/navigation/utilities";

const usePermitApplicationProcess = permit => {
    const [processCurrentStep, setProcessCurrentStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState({});
    const [isStepEditable, setIsStepEditable] = useState(false);
    const auth = useAuth();
    const {getDistrictIsland} = useDistrictIsland();

    const location = useLocation();
    const lastSlug = RouterUtil.getLastUrlSegment(location);
    const permitIsland = permit ? getDistrictIsland(permit.district) : null;

    useEffect(() => {
        const statusFromSlug = PERMIT_APPLICATION_STATUS[lastSlug];
        const hasPermission = auth.hasPermission("edit-permitapplications", {
            status: permit?.status || "()",
            island: permitIsland,
        });
        const inCurrentStep = permit?.status === statusFromSlug;
        const isEditable = hasPermission && inCurrentStep;
        setIsStepEditable(isEditable);
    }, [permit, lastSlug]);

    useEffect(() => {
        updateCompletedSteps();
    }, [processCurrentStep]);

    const updateCompletedSteps = useCallback(() => {
        setCompletedSteps(prevCompleted => {
            // IMPROVE LOGIC
            let approvedSteps = {...prevCompleted};
            if (processCurrentStep !== -1) {
                for (let i = 0; i < processCurrentStep; i++) {
                    approvedSteps[i] = true;
                }
            }
            return approvedSteps;
        });
    }, [processCurrentStep]);

    const updateCurrentStep = permitStatus => {
        const statusList = Object.values(PERMIT_APPLICATION_STATUS);

        let stepIndex = statusList.findIndex(status => status === permitStatus);

        if (stepIndex === -1) stepIndex = 0;

        setProcessCurrentStep(stepIndex);
    };

    return {
        completedSteps,
        updateCurrentStep,
        setIsStepEditable,
        isStepEditable,
    };
};

export {usePermitApplicationProcess};
