import {useOutletContext} from "react-router-dom";
import {useEntityPermissions} from "snimf/permissions/hooks";
import {ViewDocumentsSubPage} from "base/file/components";

const ViewViolationDocumentsSubPage = () => {
    let violation;
    [violation] = useOutletContext();

    const {allowEdit} = useEntityPermissions(violation, "violations");

    return (
        violation && (
            <ViewDocumentsSubPage
                entity={violation}
                basePath={"/violations/all/list"}
                disabled={!allowEdit}
            />
        )
    );
};

export default ViewViolationDocumentsSubPage;
