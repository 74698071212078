import {useState} from "react";
import {theme} from "Theme";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionLayout = ({accordionTitle, defaultExpanded = false, children}) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <MuiAccordion
            defaultExpanded={defaultExpanded}
            expanded={expanded}
            onChange={handleChange}
            disableGutters
            elevation={0}
            square
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                "&:before": {
                    display: "none",
                },
                "&:not(:last-child)": {
                    borderBottom: 0,
                },
                mb: 2,
            }}
        >
            <MuiAccordionSummary
                aria-controls="accordion-content"
                id="accordion-header"
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    ml: 1,
                    pr: 1,
                    pl: 0,
                    backgroundColor: theme.palette.primary.lighter,
                    "& .MuiAccordionSummary-content": {
                        ml: theme.spacing(1),
                    },
                    borderRadius: expanded ? "5px 5px 0 0" : "5px",
                }}
            >
                {typeof accordionTitle === "function" ? (
                    accordionTitle()
                ) : (
                    <Typography
                        variant="overline"
                        component="h3"
                        sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "primary.dark",
                        }}
                    >
                        {accordionTitle}
                    </Typography>
                )}
            </MuiAccordionSummary>
            <AccordionDetails
                sx={{
                    ml: 1,
                    pr: 1,
                    border: `1px solid ${theme.palette.primary.lighter}`,
                    borderRadius: "0 0 5px 5px",
                }}
            >
                {children}
            </AccordionDetails>
        </MuiAccordion>
    );
};

export default AccordionLayout;
