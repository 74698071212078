import {TabContainer} from "base/ui/tab/components";
import {
    PermitApplicationOtherFeesFormFields,
    PermitApplicationBillingFields,
    PermitForm,
} from "./form";
import {ViewTreesSection} from "tree/container";
import {ViewMaterialsSection} from "material/container";
import {PermitApplicationBillingDataSummary} from ".";
import {PaperContainer} from "base/ui/containers";

const PermitApplicationFeesDataTabs = ({
    permit,
    isStepEditable,
    error = null,
    onFormSubmit,
}) => {
    const tabs = [
        {
            label: "Taxas Árvores",
            hash: "trees",
            content: (
                <ViewTreesSection
                    permit={permit}
                    disabled={!isStepEditable}
                    hideNewButton
                />
            ),
            disabled:
                !permit?.types?.includes("felling") &&
                !permit?.types?.includes("cutting"),
        },
        {
            label: "Taxas Materiais",
            hash: "materials",
            content: (
                <ViewMaterialsSection
                    permit={permit}
                    disabled={!isStepEditable}
                    hideNewButton
                />
            ),
            disabled: !permit?.types?.includes("transport"),
        },
        {
            label: "Outras taxas",
            hash: "other",
            content: (
                <PermitForm permit={permit} onSubmit={onFormSubmit} error={error}>
                    <PermitApplicationOtherFeesFormFields
                        permitTypes={permit?.types}
                        disabled={!isStepEditable}
                    />
                </PermitForm>
            ),
            disabled: false,
            separator: true,
        },
        {
            label: "Valores pagos",
            hash: "payment",
            content: (
                <PaperContainer>
                    <PermitApplicationBillingFields permit={permit} />
                </PaperContainer>
            ),
        },
    ];

    return (
        <TabContainer
            tabs={tabs}
            info={<PermitApplicationBillingDataSummary permit={permit} />}
        />
    );
};

export default PermitApplicationFeesDataTabs;
