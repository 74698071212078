import {NumberUtil} from "base/format/utilities";
import {TextLink} from "base/navigation/components";

export function useCuttingTreesTable() {
    const tableColumns = [
        {
            id: "code",
            label: "Código",
            width: 15,
            formatFunction: item => (
                <TextLink
                    text={item?.code}
                    to={`#${item.code}`}
                    textStyle={{fontSize: "inherit"}}
                    isHashLink
                />
            ),
        },
        {
            id: "species",
            label: "Espécie",
            width: 15,
        },
        {
            id: "quality",
            label: "Qualidade",
            width: 15,
        },
        {
            id: "number_of_logs",
            label: "Nº toros",
            width: 10,
            textAlign: "right",
            formatFunction: item => NumberUtil.formatInteger(item.number_of_logs),
        },
        {
            id: "cutting_fee",
            label: "Taxa serragem",
            textAlign: "right",
            formatFunction: item =>
                item.cutting_fee
                    ? `${NumberUtil.formatCurrency(item.cutting_fee)}/toro`
                    : null,
            width: 10,
        },
        {
            id: "cutting_price",
            label: "Valor pago",
            textAlign: "right",
            formatFunction: item => NumberUtil.formatCurrency(item.cutting_price),
            width: 10,
        },
    ];

    return {tableColumns};
}
