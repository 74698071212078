import {SubPageMenu, SubPageMenuListItemButton} from "base/ui/menu";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import FolderOpenIconOutlined from "@mui/icons-material/FolderOpenOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";

const ViolationSubPageMenu = ({violation}) => {
    const basePath = `/violations/all/list/${violation?.id}`;

    const secondary = (
        <>
            <strong>{violation?.holder?.name || "Sem infractor/a"}</strong>
            <br />
            {violation?.district || "Sem distrito"}
        </>
    );

    return (
        <SubPageMenu
            headerTitle="Infracção"
            headerText={`${violation?.code}`}
            headerSecondary={secondary}
        >
            <SubPageMenuListItemButton
                key="violation-summary"
                to={`${basePath}/summary`}
                text="Resumo"
                icon={<InfoOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="violation-holder"
                to={`${basePath}/holder`}
                text="Infractor/a"
                icon={<ContactPageOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="violation-plot"
                to={`${basePath}/plot`}
                text="Parcela"
                icon={<PlaceOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="violation-actions"
                to={`${basePath}/actions`}
                text="Resposta"
                icon={<LocalPoliceOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="violation-documents"
                to={`${basePath}/documents`}
                text="Documentos"
                icon={<FolderOpenIconOutlined />}
            />
        </SubPageMenu>
    );
};

export default ViolationSubPageMenu;
