import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

import {useConfigModule} from "base/ui/module/provider";
import {LocationProvider} from "snimf/location/provider";
import {PlotService} from "plot/service";

import {PlotSubPageMenu} from "plot/menu";
import {PageLayout} from "base/ui/main";

const ViewPlotPage = () => {
    const {id} = useParams();

    const [plot, setPlot] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();

    const {setModuleBasePath, isLoading, setIsLoading} = useConfigModule();

    useEffect(() => {
        setIsLoading(true);
        setPlot(null);
        setModuleBasePath(`/plots/all/list/${id}`);
        PlotService.get(id)
            .then(data => {
                setPlot(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setError(error);
                setIsLoading(false);
            });
    }, [id, location.state?.lastRefreshDate]);

    return (
        <LocationProvider>
            <PageLayout
                menu={<PlotSubPageMenu plot={plot} />}
                context={[plot]}
                isLoading={isLoading}
                error={error}
                subPage
            />
        </LocationProvider>
    );
};

export default ViewPlotPage;
