import {FilterUtil} from "base/filter/utilities";
import {useState, createContext, useContext, useEffect} from "react";

let ModuleConfigContext = createContext(null);

export default function ModuleConfigProvider({children}) {
    const [moduleFilter, setModuleFilterInternal] = useState({});
    const [moduleBasePath, setModuleBasePath] = useState(null);
    const [moduleName, setModuleName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const changeFilter = propertiesChanged => {
        const cleanedFilter = FilterUtil.cleanFilter({
            ...moduleFilter,
            ...propertiesChanged,
        });
        if (!FilterUtil.equalsFilter(moduleFilter, cleanedFilter)) {
            setModuleFilterInternal({...cleanedFilter});
            console.log("filter module changed", {cleanedFilter});
        }
    };

    const setModuleFilterValue = (property, value) => {
        const newProperty = {};
        newProperty[property] = value;
        console.log("changing modlue filter property", {newProperty});
        changeFilter(newProperty);
    };

    useEffect(() => {
        if (moduleBasePath) {
            setModuleName(moduleBasePath.split("/")[1]);
        }
    }, [moduleBasePath]);

    let value = {
        moduleName,
        moduleFilter,
        setModuleFilterValue,
        moduleBasePath,
        setModuleBasePath,
        isLoading,
        setIsLoading,
    };

    return (
        <ModuleConfigContext.Provider value={value}>
            {children}
        </ModuleConfigContext.Provider>
    );
}

function useConfigModule() {
    return useContext(ModuleConfigContext);
}

export {useConfigModule};
