import {theme} from "Theme";
import {DialogLayout} from "base/dialog/components";

const EntityCreateDialog = ({formTitle, creationForm, isDialogOpen}) => {
    return (
        <DialogLayout
            dialogTitle={formTitle}
            dialogContent={creationForm}
            isDialogOpen={isDialogOpen}
            style={{backgroundColor: theme.palette.pageBackground.secondary}}
            fullWidth
            maxWidth="md"
        />
    );
};

export default EntityCreateDialog;
