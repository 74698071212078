import {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useFormContext, useWatch} from "react-hook-form";

export const useTreeFormFields = () => {
    const {permit} = useOutletContext();

    const {setValue} = useFormContext();

    const [felling, cutting] = useWatch({
        name: ["felling", "cutting"],
    });

    const disableFellingCheckBox = !permit?.types.includes("felling");
    const disableCuttingCheckBox = !permit?.types.includes("cutting");

    useEffect(() => {
        if (disableFellingCheckBox) {
            setValue("felling", false);
            setValue("cutting", true);
        }
        if (disableCuttingCheckBox) {
            setValue("cutting", false);
            setValue("felling", true);
        }
    }, [disableFellingCheckBox, disableCuttingCheckBox]);

    const permitTypeOptions = [
        {
            name: "felling",
            label: "Abate",
            disabled: disableFellingCheckBox,
        },
        {
            name: "cutting",
            label: "Serragem",
            disabled: disableCuttingCheckBox,
        },
    ];

    return {felling, cutting, permitTypeOptions};
};
