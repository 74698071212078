import {NumberUtil} from "base/format/utilities";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

const PermitApplicationBillingDataSummary = ({permit}) => {
    const totalDue = permit?.total_due
        ? NumberUtil.formatCurrency(permit?.total_due)
        : 0;

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Chip
                label={
                    <Typography fontSize={12}>
                        Valor de pagamento:{" "}
                        <Typography component="span" fontWeight={600} fontSize={12}>
                            {totalDue}
                        </Typography>
                    </Typography>
                }
                variant="outlined"
                color="primary"
            />
        </Stack>
    );
};

export default PermitApplicationBillingDataSummary;
