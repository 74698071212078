import {useEffect, useState} from "react";
import {useController, useFormContext} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

const FormSelect = ({
    name: propsName,
    label = "",
    options,
    rules = {},
    onChangeHandler = null,
    showEmptyOption = true,
    multiple = false,
    disabled = false,
}) => {
    const {control, trigger} = useFormContext();
    const {
        field,
        fieldState: {error},
    } = useController({
        name: propsName,
        control,
        rules: {
            ...rules,
        },
    });

    const placeholderValue = "placeholder";
    const valueIsSet = field.value || field.value === false;

    const [value, setValue] = useState(valueIsSet ? field.value : placeholderValue);

    const inputLabel = rules && rules["required"] ? `${label} *` : label;

    const emptyOption = {
        value: "",
        label: "‌‌", // This is not an empty character. It's U+200C unicode character.
    };

    useEffect(() => {
        if (options && options.length === 1) {
            const singleOptionValue = options[0].value;
            setValue(singleOptionValue);
            field.onChange(singleOptionValue);
            if (onChangeHandler) {
                onChangeHandler(singleOptionValue);
            }
            trigger(propsName);
        }
    }, [options]);

    return (
        <FormControl fullWidth error={Boolean(error)} margin="dense">
            <InputLabel id={`${field.name}-label`} shrink>
                {inputLabel}
            </InputLabel>
            <Select
                labelId={`${field.name}-label`}
                name={field.name}
                inputRef={field.ref}
                value={field.value}
                label={inputLabel}
                onChange={event => {
                    event.preventDefault();
                    setValue(event.target.value);
                    field.onChange(event);
                    if (onChangeHandler) {
                        onChangeHandler(event.target.value);
                    }
                    trigger(propsName);
                }}
                disabled={disabled}
                multiple={multiple}
                notched
                placeholder="Selecione..."
            >
                {value === placeholderValue && !showEmptyOption ? (
                    <MenuItem
                        value={placeholderValue}
                        sx={{
                            fontStyle: "italic",
                        }}
                        disabled
                    >
                        {options
                            ? "Selecionar uma opção da lista"
                            : "Não há opções disponíveis"}
                    </MenuItem>
                ) : null}
                {(showEmptyOption && options
                    ? [emptyOption, ...options]
                    : options
                )?.map(({label, value}) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            {error?.message ? <FormHelperText>{error?.message}</FormHelperText> : null}
        </FormControl>
    );
};

export default FormSelect;
