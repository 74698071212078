import {useState} from "react";
import {Outlet} from "react-router-dom";

import {
    FOOTER_HEIGHT,
    PAGE_MENU_DRAWER_WIDTH,
    SUBPAGE_MENU_DRAWER_WIDTH,
} from "base/ui/app/config/measurements";
import {SidebarPanelDrawer} from "base/ui/sidebar";
import {ContentContainer} from ".";
import {AlertDetailNotFound} from "base/error/components";
import {Spinner} from "base/shared/components";
import Box from "@mui/material/Box";

const PageLayout = ({
    children = null,
    menu = null,
    context = [],
    subPage = false,
    disablePadding = false,
    isLoading = false,
    error = null,
    style = {},
}) => {
    const [isSidebarPanelOpen, setSidebarPanelOpen] = useState(false);

    const drawerWidth = subPage ? SUBPAGE_MENU_DRAWER_WIDTH : PAGE_MENU_DRAWER_WIDTH;

    const renderContent = () => {
        if (isLoading) {
            return <Spinner />;
        }
        if (error) {
            return <AlertDetailNotFound />;
        }
        return (
            children || (
                <Outlet
                    context={[...context, isSidebarPanelOpen, setSidebarPanelOpen]}
                />
            )
        );
    };

    return (
        <ContentContainer open={isSidebarPanelOpen} style={{...style}}>
            {!error ? menu : null}
            {
                <>
                    <Box
                        role="page-container"
                        sx={{
                            p: subPage && !disablePadding ? 1 : "unset",
                            marginLeft: menu ? `${drawerWidth}px` : "",
                        }}
                        style={{minHeight: `calc(100vh - ${FOOTER_HEIGHT}px`}}
                    >
                        {renderContent()}
                    </Box>
                    {children && (
                        <SidebarPanelDrawer isSidebarPanelOpen={isSidebarPanelOpen}>
                            <Outlet context={[...context, setSidebarPanelOpen]} />
                        </SidebarPanelDrawer>
                    )}
                </>
            }
        </ContentContainer>
    );
};

export default PageLayout;
