import {useEffect, useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {useDomain} from "snimf/domain/provider";

function useViolationDocTypes() {
    const {violationTypeDomain: allTypes} = useDomain();
    const {setValue} = useFormContext();
    const [violationTypes, setViolationTypes] = useState([]);

    const permit = useWatch({
        name: "permit",
    });

    const selectedTypes = useWatch({
        name: "types",
    });

    useEffect(() => {
        setViolationTypes(getViolationTypes());
        deselectTypes();
    }, [permit]);

    const deselectTypes = () => {
        if (!permit && selectedTypes.length) {
            const filteredTypes = selectedTypes.filter(
                type => !type.startsWith("doc_")
            );
            setValue("types", filteredTypes);
        }
    };

    const getViolationTypes = () => {
        if (permit) {
            return allTypes;
        } else {
            return allTypes.map(type =>
                type.value.startsWith("doc_") ? {...type, disabled: true} : type
            );
        }
    };

    return {deselectTypes, violationTypes};
}

export {useViolationDocTypes};
