import {useState} from "react";
import {useLocation, useOutletContext} from "react-router-dom";

import {FileUpload, useDownload} from "base/file/utilities";
import {useNavigateWithReload} from "base/navigation/hooks";
import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";

import {PermitApplicationPaymentFormFields, PermitForm} from "./form";
import {FormContainer, FormDatePickerCombo} from "base/form/components";
import {DownloadPDFButton} from "base/pdf/presentational";
import {DividerWithHeading} from "base/ui/headings/components";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

const typeDates = [
    {
        startDateName: "felling_start_date",
        endDateName: "felling_end_date",
        label: "Abate",
    },
    {
        startDateName: "cutting_start_date",
        endDateName: "cutting_end_date",
        label: "Serragem",
    },
    {
        startDateName: "clearing_start_date",
        endDateName: "clearing_end_date",
        label: "Desbravamento",
    },
    {
        startDateName: "transport_start_date",
        endDateName: "transport_end_date",
        label: "Transporte",
    },
];

const PermitApplicationPaymentStep = () => {
    const download = useDownload();
    const navigate = useNavigateWithReload();

    const {permit, isStepEditable} = useOutletContext();
    const [error, setError] = useState(null);

    const basePath = useLocation();
    const fileFields = ["payment_receipt"];

    const handleDownload = () => {
        if (permit) {
            return PermitApplicationService.downloadPermit(permit.id).then(response => {
                return download(response)
                    .then(() => navigate("", true))
                    .catch(error => setError(error));
            });
        } else {
            return Promise.reject("Nenhuma autorização foi selecionada.");
        }
    };

    const handleFormSubmit = (updatedPermit, callback = null) => {
        const objectContainsFiles = Object.values(updatedPermit).some(
            value => value instanceof File
        );

        if (!objectContainsFiles) {
            updatePermitApplication(updatedPermit).then(() => {
                FileUpload.callCallback(callback, true);
                navigate(basePath, true);
            });
        } else {
            FileUpload.uploadFiles(updatedPermit, permit, fileFields)
                .then(fileUploadResults => {
                    FileUpload.updateFormWithFileIds(
                        updatedPermit,
                        fileUploadResults,
                        fileFields
                    );
                    return updatePermitApplication(updatedPermit);
                })
                .then(() => {
                    FileUpload.callCallback(callback, true);
                    navigate(basePath, true);
                })
                .catch(fileError => {
                    FileUpload.callCallback(callback, false);
                    console.log({fileError});
                    setError(fileError.error);
                });
        }
    };

    const updatePermitApplication = updatedPermit => {
        return PermitApplicationService.update(
            permit_view_adapter(updatedPermit)
        ).catch(error => {
            console.error(error);
            setError(error);
        });
    };

    const displayedTypes = typeDates.filter(type =>
        permit?.types_label?.includes(type.label)
    );

    return permit ? (
        <Stack spacing={3}>
            <PermitForm permit={permit} onSubmit={handleFormSubmit} error={error}>
                <Stack spacing={2}>
                    <FormContainer label="Comprovativo de pagamento">
                        <PermitApplicationPaymentFormFields
                            disabled={!isStepEditable}
                        />
                    </FormContainer>
                    <FormContainer>
                        <Grid container columnSpacing={6} rowSpacing={2}>
                            {displayedTypes.map(type => (
                                <Grid item xs={12} md={6} key={type.label}>
                                    <DividerWithHeading
                                        label={`Validade da guia de ${type.label}`}
                                    />
                                    <Stack direction="row" spacing={1}>
                                        <FormDatePickerCombo
                                            startDateLabel="Data de início"
                                            startDateName={type.startDateName}
                                            endDateLabel="Data de caducidade"
                                            endDateName={type.endDateName}
                                            disabled={!isStepEditable}
                                            required
                                        />
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </FormContainer>
                </Stack>
            </PermitForm>
            <Stack alignItems="center" pb={2}>
                <DownloadPDFButton
                    handleGeneratePDF={handleDownload}
                    text="Imprimir autorização"
                    color="secondary"
                    disabled={!permit?.payment_receipt}
                />
            </Stack>
        </Stack>
    ) : null;
};

export default PermitApplicationPaymentStep;
