import {Outlet} from "react-router-dom";
import {StepperUtil} from "base/stepper/utilities";
import {PERMIT_APPLICATION_STATUS, getRequiredFields} from "permitapplication/data";

const usePermitApplicationSteps = (permit, isStepEditable, process) => {
    const getUncompleteFields = StepperUtil.getUncompleteFields;
    const isStepUntouched = StepperUtil.isStepUntouched;

    let steps = [];
    let allSteps = [];

    const contextForOutlet = {
        permit,
        isStepEditable,
    };

    if (permit) {
        const requiredFields = getRequiredFields(permit);

        const applicationProcessSteps = [
            {
                order: 0,
                label: "Titular e contactos",
                sublabel: "Secretaria",
                path: "holder_s",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.holder_s,
                uncompleteFields: getUncompleteFields(requiredFields.holder_s),
                isStepUntouched: isStepUntouched(requiredFields.holder_s),
            },
            {
                order: 1,
                label: "Guias e documentação",
                sublabel: "Secretaria",
                path: "permit_s",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.permit_s,
                uncompleteFields: getUncompleteFields(requiredFields.permit_s),
                isStepUntouched: isStepUntouched(requiredFields.permit_s),
            },
            {
                order: 2,
                label: "Análise documentação",
                sublabel: "MFCE-M",
                path: "analysis_m",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.analysis_m,
                uncompleteFields: getUncompleteFields(requiredFields.analysis_m),
                isStepUntouched: isStepUntouched(requiredFields.analysis_m),
            },
            {
                order: 3,
                label: "Parcela",
                sublabel: "MFCE-M",
                path: "plot1_m",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.plot1_m,
                uncompleteFields: getUncompleteFields(requiredFields.plot1_m),
                isStepUntouched: isStepUntouched(requiredFields.plot1_m),
            },
            {
                order: 4,
                label: "Vistoria",
                sublabel: "MFCE-M",
                path: "visit_m",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.visit_m,
                uncompleteFields: getUncompleteFields(requiredFields.visit_m),
                isStepUntouched: isStepUntouched(requiredFields.visit_m),
            },
            {
                order: 5,
                label: "Dados técnicos",
                sublabel: "MFCE-M",
                path: "permit_m",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.permit_m,
                uncompleteFields: getUncompleteFields(requiredFields.permit_m),
                isStepUntouched: isStepUntouched(requiredFields.permit_m),
            },
            {
                order: 6,
                label: "Parecer técnico",
                sublabel: "MFCE-M",
                path: "report_m",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.report_m,
                uncompleteFields: getUncompleteFields(requiredFields.report_m),
                isStepUntouched: isStepUntouched(requiredFields.report_m),
            },
        ];

        const paymentProcessSteps = [
            {
                order: 7,
                label: "Valor de pagamento",
                sublabel: "MFCE-E",
                path: "payment_e",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.payment_e,
                uncompleteFields: getUncompleteFields(requiredFields.payment_e),
                isStepUntouched: isStepUntouched(requiredFields.payment_e),
                forceBackButton: true,
            },
            {
                order: 8,
                label: "Factura",
                sublabel: "Secretaria",
                path: "invoice_s",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.invoice_s,
                uncompleteFields: getUncompleteFields(requiredFields.invoice_s),
                isStepUntouched: isStepUntouched(requiredFields.invoice_s),
            },
            {
                order: 9,
                label: "Pagamento e datas",
                sublabel: "Secretaria",
                path: "payment_s",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.payment_s,
                uncompleteFields: getUncompleteFields(requiredFields.payment_s),
                isStepUntouched: isStepUntouched(requiredFields.payment_s),
            },
            {
                order: 10,
                label: "Firma Direcção",
                sublabel: "Secretaria",
                path: "signature_s",
                content: <Outlet context={contextForOutlet} />,
                status: PERMIT_APPLICATION_STATUS.signature_s,
                uncompleteFields: getUncompleteFields(requiredFields.signature_s),
                isStepUntouched: isStepUntouched(requiredFields.signature_s),
            },
        ];

        allSteps = [...applicationProcessSteps, ...paymentProcessSteps];

        if (process === "application") steps = applicationProcessSteps;
        if (process === "payment") steps = paymentProcessSteps;
        else if (!process) steps = allSteps;
    }

    return {
        steps,
        allSteps,
    };
};

export {usePermitApplicationSteps};
