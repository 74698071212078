import {theme} from "Theme";
import {getFieldContentConfig} from "permitapplication/data";
import {usePermitFieldStatus} from "permitapplication/hooks";

import {DialogLayout} from "base/dialog/components";
import {AlertError} from "base/error/components";
import {Spinner} from "base/shared/components";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

export const PermitApplicationFieldSection = ({permit, fieldStatus}) => {
    const {
        forcedImport,
        isDialogOpen,
        error,
        isLoading,
        setIsDialogOpen,
        handleExportSubmit,
        handleForcedImportSubmit,
        handleImportSubmit,
        enableForcedImport,
    } = usePermitFieldStatus(permit);

    const {
        statusTitle,
        statusInfo,
        statusAlert,
        textButton,
        alertSeverity,
        isButtonDisabled,
        onConfirm,
        input,
    } =
        getFieldContentConfig(
            fieldStatus,
            forcedImport,
            enableForcedImport,
            handleExportSubmit,
            handleForcedImportSubmit,
            handleImportSubmit
        ) || {};

    return (
        <>
            <Stack spacing={3}>
                <AlertError error={error} />
                <Stack spacing={2} alignItems="center" px={4} pt={2}>
                    {isLoading || fieldStatus === null ? (
                        <Spinner />
                    ) : (
                        <>
                            {statusTitle && (
                                <Typography
                                    sx={{
                                        color: theme.palette.primary.main,
                                        fontWeight: 500,
                                        textTransform: "uppercase",
                                    }}
                                    style={{marginBottom: "16px"}}
                                >
                                    {statusTitle}
                                </Typography>
                            )}
                            {statusInfo || null}
                            {statusAlert && (
                                <Alert
                                    severity={alertSeverity || "warning"}
                                    variant="outlined"
                                >
                                    {statusAlert}
                                </Alert>
                            )}
                            {input}
                            {textButton && (
                                <Button
                                    variant="contained"
                                    onClick={() => setIsDialogOpen(true)}
                                    disabled={isButtonDisabled}
                                    sx={{width: "fit-content"}}
                                    style={{marginTop: "32px"}}
                                >
                                    {textButton}
                                </Button>
                            )}
                        </>
                    )}
                </Stack>
            </Stack>
            <DialogLayout
                dialogTitle={`Tem certeza de que deseja ${textButton?.toLowerCase()}?`}
                dialogContentText={statusInfo}
                onMainActionClick={onConfirm}
                onHandleDialog={() => setIsDialogOpen(false)}
                isDialogOpen={isDialogOpen}
                mainActionColor="warning"
                maxWidth="md"
            />
        </>
    );
};
