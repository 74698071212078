import {useOutletContext} from "react-router-dom";

import {PlotService} from "plot/service";
import {usePermitsSummaryTable} from "permit/data";
import {useViolationsSummaryTable} from "violation/data";
import {usePlantationsSummaryTable} from "plantation/data";

import {EntityViewSubPage} from "base/entity/components/container";
import {LinkedEntitySection} from "base/entity/components/presentational";

const ViewPlotProceduresSubPage = () => {
    const {tableColumns: permitTableColumns} = usePermitsSummaryTable();
    const {tableColumns: violationsTableColumns} = useViolationsSummaryTable();
    const {tableColumns: plantationsTableColumns} = usePlantationsSummaryTable();

    const context = useOutletContext();
    const plot = context[0];

    const entitiesData = [
        {
            entityName: "Autorizações",
            getItems: PlotService.getPermits,
            tableColumns: permitTableColumns,
        },
        {
            entityName: "Infracções",
            getItems: PlotService.getViolations,
            tableColumns: violationsTableColumns,
        },
        {
            entityName: "Plantios",
            getItems: PlotService.getPlantations,
            tableColumns: plantationsTableColumns,
        },
    ];

    const sections = [
        ...entitiesData.map((section, index) => (
            <LinkedEntitySection
                key={index}
                entityId={plot?.id}
                linkedEntityName={section.entityName}
                getItems={section.getItems}
                tableColumns={section.tableColumns}
            />
        )),
    ];

    return plot && <EntityViewSubPage sections={sections} />;
};

export default ViewPlotProceduresSubPage;
