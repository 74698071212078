import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil, NumberUtil} from "base/format/utilities";

import Grid from "@mui/material/Grid";

const PlantationGeneralDataSection = ({plantation}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Código", plantation.code)}
                {FieldUtil.getSectionField("Distrito", plantation.district)}
                {FieldUtil.getSectionField(
                    "Superficie plantada",
                    NumberUtil.formatDecimal(plantation.area),
                    "ha"
                )}
                {FieldUtil.getSectionField(
                    "Quantidade total plantada",
                    plantation.quantity,
                    "plantas"
                )}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Data de solicitação",
                    DateUtil.formatDate(plantation.application_date)
                )}
                {FieldUtil.getSectionField(
                    "Data de colocação do visto",
                    DateUtil.formatDate(plantation.on_date)
                )}
                {FieldUtil.getSectionField(
                    "Data de entrega de plantas ao requerente",
                    DateUtil.formatDate(plantation.delivery_date)
                )}
            </Grid>
        </Grid>
    );
};

export default PlantationGeneralDataSection;
