import {useState} from "react";

import {HolderService} from "holder/service";
import {holder_view_adapter} from "holder/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {EntityCommentsSection} from "base/entity/components/presentational/sections";
import {SectionCard, SectionCardHeaderAction} from "base/ui/section/components";
import {FormContainer, FormCommentsField} from "base/form/components";
import {
    HolderForm,
    HolderGeneralDataFormFields,
    HolderPersonalDocsFormFields,
} from "holder/presentational/form";
import {
    HolderGeneralDataSection,
    HolderPersonalDocsSection,
} from "holder/presentational";

import EditIcon from "@mui/icons-material/Edit";

const ViewOrUpdateHolderContent = ({holder, section, label, edit = true}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleFormSubmit = holder => {
        HolderService.update(holder_view_adapter({...holder}))
            .then(updatedHolder => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const getDataComponent = section => {
        if (section === "generaldata") {
            return <HolderGeneralDataSection holder={holder} />;
        }
        if (section === "personaldocs") {
            return <HolderPersonalDocsSection holder={holder} />;
        }
        if (section === "comments") {
            return <EntityCommentsSection comments={holder?.comments} />;
        }
    };

    const getFormComponent = section => {
        if (section === "generaldata") {
            return <HolderGeneralDataFormFields />;
        }
        if (section === "personaldocs") {
            return <HolderPersonalDocsFormFields />;
        }
        if (section === "comments") {
            return <FormCommentsField placeholder="Observações" displayLabel={false} />;
        }
    };

    const getComponent = mode => {
        if (mode === "view") {
            return getDataComponent(section);
        }
        if (mode === "edit") {
            return (
                <FormContainer>
                    <HolderForm
                        holder={holder}
                        onSubmit={handleFormSubmit}
                        onCancel={() => {
                            setMode("view");
                        }}
                        error={error}
                    >
                        {getFormComponent(section)}
                    </HolderForm>
                </FormContainer>
            );
        }
    };

    const actions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                setMode("edit");
            }}
        />,
    ];

    return (
        <SectionCard title={label} secondaryActions={edit ? actions : null}>
            {getComponent(mode)}
        </SectionCard>
    );
};

export default ViewOrUpdateHolderContent;
