import {FileUploadField} from "base/file/components";

const PermitApplicationReportFormFields = ({disabled}) => {
    const maxSize = 5000000;

    return (
        <FileUploadField
            name="technical_report"
            label="Relatório de vistoria *"
            maxSize={maxSize}
            disabled={disabled}
        />
    );
};

export default PermitApplicationReportFormFields;
