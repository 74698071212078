import {useState} from "react";
import {
    CancelProcessDialog,
    CompleteProcessDialog,
    RevertProcessStepDialog,
    StepperAlert,
    StepperButtons,
} from ".";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import StepButton from "@mui/material/StepButton";

const HorizontalStepper = ({
    steps,
    activeStepIndex,
    completedSteps = {},
    disableNavigation,
    disableCompleteButton,
    disableBackButton = false,
    displayAlert = false,
    completedProcessContent = null,
    onChangeStep = null,
    onCompleteStep = null,
    onRevertStep = null,
    onCancelProcess = null,
    onApproveProcess = null,
}) => {
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
    const [isRevertDialogOpen, setIsRevertDialogOpen] = useState(false);

    const activeStep = activeStepIndex !== -1 ? steps[activeStepIndex] : null;

    const navigationStatus = {
        firstStep: activeStepIndex === 0 && !activeStep.forceBackButton,
        lastStep: activeStepIndex === steps.length - 1,
        disableNext: !!activeStep?.uncompleteFields.length,
        processComplete: activeStepIndex === steps.length,
        disableNavigation: disableNavigation,
        disableCompleteButton: disableCompleteButton,
        disableBackButton: disableBackButton,
    };

    const handleClickStep = step => () => {
        if (onChangeStep) onChangeStep(step);
    };

    const handleClickBack = () => {
        setIsRevertDialogOpen(true);
    };

    const handleClickCancel = () => {
        setIsCancelDialogOpen(true);
    };

    const handleNext = () => {
        if (onApproveProcess) setIsCompleteDialogOpen(true);
        else if (onCompleteStep) onCompleteStep();
    };

    return (
        <Stack spacing={1}>
            <Box sx={{width: "100%"}} component={Paper} px={3} py={2}>
                <Stepper nonLinear activeStep={activeStepIndex}>
                    {steps?.map((step, index) => {
                        const labelProps = {};
                        labelProps.optional = (
                            <Typography variant="overline" lineHeight={1.5}>
                                {step.sublabel}
                            </Typography>
                        );
                        return (
                            <Step
                                key={step.label}
                                completed={completedSteps[step.order || index]}
                            >
                                <StepButton
                                    {...labelProps}
                                    onClick={handleClickStep(step)}
                                    sx={{
                                        "& .MuiStepLabel-labelContainer": {
                                            lineHeight: 0,
                                            textAlign: "left",
                                        },
                                        "& .MuiStepLabel-iconContainer": {
                                            ".Mui-completed": {
                                                color: "primary.light",
                                            },
                                            ".Mui-active": {
                                                color: "primary.dark",
                                            },
                                        },
                                    }}
                                >
                                    {step.label}
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>

            {displayAlert ? (
                <Box sx={{width: "100%"}} component={Paper} px={3} py={1}>
                    <StepperAlert notices={activeStep?.uncompleteFields} />
                </Box>
            ) : null}

            <Box sx={{width: "100%"}} component={Paper} p={3} pb={1}>
                {navigationStatus.processComplete
                    ? completedProcessContent
                    : steps.length && activeStep?.content}
            </Box>

            {navigationStatus.processComplete ? null : (
                <Box sx={{width: "100%"}} component={Paper} p={1}>
                    <StepperButtons
                        navigationStatus={navigationStatus}
                        onClick={{
                            back: handleClickBack,
                            next: handleNext,
                            cancel: handleClickCancel,
                        }}
                    />
                </Box>
            )}
            <RevertProcessStepDialog
                isDialogOpen={isRevertDialogOpen}
                setIsDialogOpen={setIsRevertDialogOpen}
                onRevert={onRevertStep}
            />
            <CancelProcessDialog
                isDialogOpen={isCancelDialogOpen}
                setIsDialogOpen={setIsCancelDialogOpen}
                onCancel={onCancelProcess}
            />
            <CompleteProcessDialog
                isDialogOpen={isCompleteDialogOpen}
                setIsDialogOpen={setIsCompleteDialogOpen}
                onComplete={onApproveProcess}
            />
        </Stack>
    );
};

export default HorizontalStepper;
