import {useState} from "react";
import {useOutletContext} from "react-router-dom";

import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";

import {useNavigateWithReload} from "base/navigation/hooks";

import {PermitApplicationFeesDataTabs} from ".";
import {NoDataContainer} from "base/shared/components";
import {FormContainer} from "base/form/components";
import Box from "@mui/material/Box";

const PermitApplicationFeesStep = () => {
    const {permit, isStepEditable} = useOutletContext();
    const [error, setError] = useState(null);

    const navigate = useNavigateWithReload();

    const handleFormSubmit = updatedPermit => {
        PermitApplicationService.update(permit_view_adapter(updatedPermit))
            .then(updatedPermit => {
                navigate("", true);
            })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    return permit.types?.length ? (
        <FormContainer>
            <PermitApplicationFeesDataTabs
                permit={permit}
                isStepEditable={isStepEditable}
                error={error}
                onFormSubmit={handleFormSubmit}
            />
        </FormContainer>
    ) : (
        <Box my={2}>
            <NoDataContainer text="As guias de esta autorização ainda não foram indicadas." />
        </Box>
    );
};

export default PermitApplicationFeesStep;
