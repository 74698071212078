import {useEffect} from "react";
import {useLeafletMap} from "./leaflet";
import {useMapContext} from "./MapProvider";

const mapDefaultStyle = {
    width: "100%",
    height: "100%",
};

const MapView = () => {
    const {createMap, isMapLoaded, removeMap, setBaseLayer} = useLeafletMap();

    const {mapDOMRef, selectedBaseLayer} = useMapContext();

    useEffect(() => {
        createMap();

        return () => {
            if (isMapLoaded()) {
                removeMap();
            }
        };
    }, []);

    useEffect(() => {
        setBaseLayer(selectedBaseLayer);
    }, [selectedBaseLayer]);

    return <div id="map" style={mapDefaultStyle} ref={mapDOMRef} />;
};
export default MapView;
