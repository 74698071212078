import {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";

import {useAdministrativeDivisions} from "snimf/location/provider";
import {FormInputText, FormSelect} from "base/form/components";

import Grid from "@mui/material/Grid";

const FormLocationCombo = ({
    orientation = "column",
    required = false,
    fullCombo = false,
}) => {
    const {getValues, setValue} = useFormContext();

    const {islands, districts, subdistricts} = useAdministrativeDivisions();
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const [filteredSubdistricts, setFilteredSubdistricts] = useState([]);

    const values = getValues();
    const island = values.island;
    const district = values.district;

    useEffect(() => {
        if (island) {
            const filteredDistricts = districts.filter(d => d.island_code === island);
            console.log(island, filteredDistricts);
            setFilteredDistricts(filteredDistricts);
        }

        if (district) {
            const filteredSubdistricts = subdistricts.filter(
                sd => sd.district_code === district
            );
            setFilteredSubdistricts(filteredSubdistricts);
        }
    }, [island, districts, subdistricts]);

    const onChangeIsland = selectedIsland => {
        setFilteredDistricts(
            districts.filter(district => district.island_code === selectedIsland)
        );
    };

    const onChangeDistrict = selectedDistrict => {
        setFilteredSubdistricts(
            subdistricts.filter(
                subdistrict => subdistrict.district_code === selectedDistrict
            )
        );

        const district = districts.find(
            district => district.value === selectedDistrict
        );
        if (district) {
            setValue("island", district.island_code);
            setValue("district", district.label);
        }
    };

    const onChangeSubdistrict = selectedSubdistrict => {
        const subdistrict = subdistricts.find(
            subdistrict => subdistrict.value === selectedSubdistrict
        );
        if (subdistrict) {
            setValue("sub_district", subdistrict.label);
        }
    };

    const getSelectorsWidth = () => {
        if (orientation === "column") return 12;
        if (orientation === "row" && fullCombo) return 3;
        return 6;
    };

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={12} md={getSelectorsWidth()}>
                <FormSelect
                    name="island"
                    label="Ilha"
                    rules={required ? {required: "Este campo é obrigatório"} : null}
                    options={islands}
                    onChangeHandler={onChangeIsland}
                    showEmptyOption
                />
            </Grid>
            <Grid item xs={12} md={getSelectorsWidth()}>
                <FormSelect
                    name="district"
                    label="Distrito"
                    rules={required ? {required: "Este campo é obrigatório"} : null}
                    options={filteredDistricts}
                    onChangeHandler={onChangeDistrict}
                    showEmptyOption
                    disabled={island === ""}
                />
            </Grid>
            {fullCombo ? (
                <>
                    <Grid item xs={12} md={orientation === "column" ? 12 : 3}>
                        <FormSelect
                            name="sub_district"
                            label="Sub distrito"
                            options={filteredSubdistricts}
                            onChangeHandler={onChangeSubdistrict}
                            showEmptyOption
                            disabled={district === ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={orientation === "column" ? 12 : 3}>
                        <FormInputText
                            name="locality"
                            label="Localidade"
                            rules={
                                required ? {required: "Este campo é obrigatório"} : null
                            }
                            disabled={district === ""}
                        />
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default FormLocationCombo;
