import {useState} from "react";
import {useOutletContext} from "react-router-dom";

import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";
import {plot_view_adapter} from "plot/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {PermitForm} from "./form";
import {FormContainer} from "base/form/components";
import {
    PlotForm,
    PlotFormSearch,
    PlotInitialDataFormFields,
} from "plot/presentational/form";
import {EntityAddButtonGroup} from "base/entity/components/presentational";
import {NoDataContainer} from "base/shared/components";
import {PlotBasicDataSection} from "plot/presentational";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const PermitApplicationPlotStep = () => {
    const {permit, isStepEditable} = useOutletContext();
    const navigate = useNavigateWithReload();

    const plot = permit.plot;

    const [selectedPlot, setSelectedPlot] = useState(null);
    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleCreateFormSubmit = plot => {
        PermitApplicationService.createPlot(permit.id, plot_view_adapter({...plot}))
            .then(createdPlot => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleEditFormSubmit = () => {
        PermitApplicationService.update(
            permit_view_adapter({...permit, plot: selectedPlot})
        )
            .then(updatedPermit => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleSelectPlot = plot => {
        setSelectedPlot(plot);
    };

    const getComponent = mode => {
        if (mode === "view") {
            return plot ? (
                <Stack alignItems="center" spacing={1}>
                    <PlotBasicDataSection plot={plot} />
                    <Button
                        onClick={() => setMode("search")}
                        variant="outlined"
                        disabled={!isStepEditable}
                    >
                        Modificar
                    </Button>
                </Stack>
            ) : (
                <NoDataContainer
                    text="Esta autorização ainda não tem parcela."
                    button={
                        <EntityAddButtonGroup
                            onAdd={() => setMode("new")}
                            onSearch={() => setMode("search")}
                            disabled={!isStepEditable}
                        />
                    }
                />
            );
        }
        if (mode === "new") {
            return (
                <PlotForm
                    plot={plot}
                    onSubmit={handleCreateFormSubmit}
                    onCancel={() => {
                        setMode("view");
                    }}
                    error={error}
                >
                    <FormContainer>
                        <PlotInitialDataFormFields />
                    </FormContainer>
                </PlotForm>
            );
        }
        if (mode === "search") {
            return (
                <PermitForm
                    permit={permit}
                    onSubmit={handleEditFormSubmit}
                    error={error}
                    onCancel={() => {
                        setMode("view");
                    }}
                >
                    <FormContainer>
                        <PlotFormSearch onClickSelected={handleSelectPlot} />
                    </FormContainer>
                </PermitForm>
            );
        }
    };

    return getComponent(mode);
};

export default PermitApplicationPlotStep;
