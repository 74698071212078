import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil} from "base/format/utilities";
import {SectionCard} from "base/ui/section/components";
import Grid from "@mui/material/Grid";

const ApprovedPermitInspectionGeneralDataSection = ({permit}) => {
    return (
        <SectionCard title="Vistoria a terreno">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {FieldUtil.getSectionField(
                        "Data",
                        DateUtil.formatDate(permit?.inspection_date)
                    )}
                </Grid>
                <Grid item xs={6}>
                    {FieldUtil.getSectionField("Equipa", permit?.inspection_team)}
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ApprovedPermitInspectionGeneralDataSection;
