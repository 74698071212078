import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";

const SnimfHelp = () => {
    return (
        <Tooltip title="Manual de uso (PDF)">
            <IconButton
                onClick={() => window.open("/static/help/SNIMF_Manual.pdf", "_blank")}
                sx={{
                    width: 42,
                    height: 42,
                    ml: 1,
                    mr: 1,
                    backgroundColor: "primary.light",
                }}
            >
                <HelpIcon sx={{fontSize: 39, color: "white"}} />
            </IconButton>
        </Tooltip>
    );
};

export default SnimfHelp;
