import {SectionCard, SectionValueBox} from "base/ui/section/components";

const EntityCommentsSection = ({comments, displayCard = false}) => {
    return displayCard ? (
        <SectionCard title="Observações">
            <SectionValueBox value={comments} />
        </SectionCard>
    ) : (
        <SectionValueBox value={comments} />
    );
};

export default EntityCommentsSection;
