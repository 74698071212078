import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const NoDataContainer = ({text, button = null}) => {
    return (
        <Stack alignItems="center" spacing={3}>
            <Typography
                style={{
                    fontSize: 14,
                    fontStyle: "italic",
                }}
            >
                {text}
            </Typography>
            {button}
        </Stack>
    );
};

export default NoDataContainer;
