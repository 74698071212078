import {createHolder, holder_api_adapter} from "holder/model";
import {createPlot, plot_api_adapter} from "plot/model";

class Plantations extends Array {}

const plantation_api_adapter = plantation => {
    if (plantation.holder)
        plantation["holder"] = createHolder(holder_api_adapter(plantation.holder));
    if (plantation.plot)
        plantation["plot"] = createPlot(plot_api_adapter(plantation.plot));

    return plantation;
};

const plantations_api_adapter = plantations => {
    return plantations.map(plantation_api_adapter);
};

const plantation_view_adapter = plantation => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside plantation object
    if (plantation["holder"]) plantation["holder_id"] = plantation["holder"].id;
    if (plantation["plot"]) plantation["plot_id"] = plantation["plot"].id;

    delete plantation["holder"];
    delete plantation["plot"];
    delete plantation["folder"];
    delete plantation["featured_image"];
    delete plantation["created_by"];
    delete plantation["updated_by"];
    delete plantation["created_at"];
    delete plantation["updated_at"];

    return plantation;
};

const createPlantations = (data = []) => {
    const plantations = Plantations.from(data, plantation =>
        createPlantation(plantation)
    );
    return plantations;
};

const createPlantation = ({
    id = -1,
    code = null,
    application_date = null,
    on_date = null,
    delivery_date = "",
    area = "",
    quantity = null,
    island = "",
    district = "",
    comments = "",
    geom = null,
    holder = null,
    holder_id = null,
    plot = null,
    plot_id = null,
    folder = "",
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        code,
        application_date,
        on_date,
        delivery_date,
        area,
        quantity,
        island,
        district,
        comments,
        geom,
        holder,
        holder_id,
        plot,
        plot_id,
        folder,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createPlantation as default,
    createPlantations,
    plantation_api_adapter,
    plantations_api_adapter,
    plantation_view_adapter,
};
