import {TextLink} from "base/navigation/components";

export function useHoldersTable() {
    const tableColumns = [
        {
            id: "name",
            label: "Nome",
            width: 40,
            formatFunction: item => (
                <TextLink
                    text={item?.name}
                    to={`/holders/all/list/${item.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
        },
        {
            id: "doc_number",
            label: "Nº documento",
            width: 20,
        },
        {
            id: "type",
            label: "Tipo",
            width: 20,
        },
        {
            id: "gender",
            label: "Gênero",
            width: 20,
        },
    ];

    return {tableColumns};
}
