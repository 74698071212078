import {useEffect} from "react";
import {useFormContext, useWatch} from "react-hook-form";

function useHolderFormFields() {
    const {setValue} = useFormContext();

    const type = useWatch({
        name: "type",
    });

    const isBusiness = type === "Empresa";
    const genderLabel = isBusiness ? "Gênero do representante" : "Gênero";

    useEffect(() => {
        if (isBusiness) {
            setValue("doc_type", "NIF");
        } else setValue("doc_type", "");
    }, [isBusiness]);

    return {genderLabel, isBusiness};
}

export {useHolderFormFields};
