class Holders extends Array {}

const holder_api_adapter = holder => {
    return holder;
};

const holders_api_adapter = holders => {
    return holders.map(holder_api_adapter);
};

const holder_view_adapter = holder => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside holder object

    delete holder["folder"];
    delete holder["created_by"];
    delete holder["updated_by"];
    delete holder["created_at"];
    delete holder["updated_at"];

    return holder;
};

const createHolders = (data = []) => {
    const holders = Holders.from(data, holder => createHolder(holder));
    return holders;
};

const createHolder = ({
    id = -1,
    type = "",
    name = "",
    address = "",
    representative = "",
    gender = "",
    doc_type = "",
    doc_number = "",
    doc_expiration_date = null,
    comments = "",
    folder = "",
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        type,
        name,
        address,
        representative,
        gender,
        doc_type,
        doc_number,
        doc_expiration_date,
        comments,
        folder,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createHolder as default,
    createHolders,
    holder_api_adapter,
    holders_api_adapter,
    holder_view_adapter,
};
