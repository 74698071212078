import {useController, useFormContext} from "react-hook-form";
import {DateUtil} from "base/format/utilities";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";

const FormDatePicker = ({
    name: propsName,
    label,
    rules = {},
    onChangeHandler = null,
    disabled = false,
    disableFuture = true,
    views = ["year", "month", "day"],
    minDate = new Date(1990, 0, 1),
    maxDate = new Date(2099, 11, 31),
}) => {
    const {control, trigger} = useFormContext();

    const {
        field: {onChange, name, value, ref},
        fieldState: {error},
    } = useController({
        name: propsName,
        control,
        rules: {
            ...rules,
            validate: {
                checkDate: value => {
                    if (value) return validateDate(value);
                },
                ...rules.validate,
            },
        },
    });

    const inputLabel = rules && rules["required"] ? `${label} *` : label;

    const validateDate = value => {
        const dateObject = DateUtil.parseDateFromApi(value);
        if (DateUtil.isInvalidDateObject(dateObject)) {
            return "Data não válida.";
        }

        if (!DateUtil.isInvalidDateObject(dateObject)) {
            const year = dateObject.getFullYear();
            if (year < minDate.getFullYear() || year > maxDate.getFullYear()) {
                return `A data deve estar entre ${DateUtil.formatDate(
                    minDate
                )} e ${DateUtil.formatDate(maxDate)}.`;
            }
        }

        return true;
    };

    const handleChange = value => {
        const formattedDate = DateUtil.formatDateForAPI(value);
        onChange(formattedDate); // data sent back to hook form
        if (onChangeHandler) {
            onChangeHandler(formattedDate);
        }
        trigger(propsName);
    };

    return (
        <FormControl fullWidth error={Boolean(error)} margin="dense">
            <DatePicker
                name={name}
                value={DateUtil.parseDateFromApi(value)}
                label={inputLabel}
                inputRef={ref}
                onChange={value => {
                    handleChange(value);
                }}
                slotProps={{
                    textField: {
                        variant: "outlined",
                        placeholder: "dd/mm/aaaa",
                        InputLabelProps: {shrink: true},
                        helperText: error?.message,
                        error: Boolean(error),
                    },
                }}
                views={views}
                minDate={minDate}
                maxDate={maxDate}
                disableFuture={disableFuture}
                disabled={disabled}
            />
        </FormControl>
    );
};

export default FormDatePicker;
