import {FieldUtil} from "base/ui/section/utilities";
import {NumberUtil} from "base/format/utilities";

import {ViolationActionsChips} from ".";
import {LightHeading} from "base/ui/headings/components";
import {SectionLabel} from "base/ui/section/components";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";

const ViolationActionsSection = ({violation}) => {
    const destinations = [
        violation.forest_material_destination,
        violation.chainsaw_destination,
        violation.vehicle_destination,
    ];

    const displayConfiscationSection = violation?.actions?.includes("confiscation");
    const displayPenaltySection = violation?.actions?.includes("penalty");
    const displaySaleInfo = destinations.includes("Vendido pela DFB");

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider sx={{width: "100%", mb: 2}} />
                <ViolationActionsChips actions={violation?.actions_label} />
                <Divider sx={{width: "100%", my: 2}} />
            </Grid>

            {displayConfiscationSection ? (
                <>
                    <Grid item xs={12} mt={2}>
                        <LightHeading>Confiscação</LightHeading>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Material florestal",
                            violation.forest_material
                        )}
                        {FieldUtil.getSectionField("Motoserra", violation.chainsaw)}
                        {FieldUtil.getSectionField("Veículo", violation.vehicle)}
                        {displaySaleInfo ? (
                            <Box mt={2}>
                                {FieldUtil.getSectionField(
                                    "Quantia da venda dos materiais",
                                    violation.sale_amount
                                )}
                                {FieldUtil.getSectionCheckBoxField(
                                    "Comprovativo de depósito",
                                    violation.sale_receipt
                                )}
                            </Box>
                        ) : null}
                    </Grid>

                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Destino do material florestal",
                            violation.forest_material_destination
                        )}
                        {FieldUtil.getSectionField(
                            "Destino da motoserra",
                            violation.chainsaw_destination
                        )}
                        {FieldUtil.getSectionField(
                            "Destino do veículo",
                            violation.vehicle_destination
                        )}
                        {displaySaleInfo ? (
                            <Box mt={2}>
                                {FieldUtil.getSectionField(
                                    "Nome do comprador",
                                    violation.sale_buyer
                                )}
                            </Box>
                        ) : null}
                    </Grid>
                </>
            ) : null}

            {displayPenaltySection ? (
                <>
                    <Grid item xs={12} mt={2}>
                        <LightHeading>Multa</LightHeading>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Quantia da multa",
                            NumberUtil.formatCurrency(violation.penalty_amount)
                        )}
                    </Grid>
                    <Grid item xs={6} container alignItems="center">
                        <Grid item xs={6}>
                            <Stack direction="row" alignItems="center">
                                <SectionLabel label="O infractor pagou a multa" />
                                <Checkbox checked={violation.penalty_paid} disabled />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" alignItems="center">
                                <SectionLabel label="Comprovativo de depósito" />
                                <Checkbox
                                    checked={violation.penalty_receipt}
                                    disabled
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default ViolationActionsSection;
