import {FieldUtil} from "base/ui/section/utilities";
import Grid from "@mui/material/Grid";

const PlotOwnershipDataSection = ({plot}) => {
    const displayStateConcession = plot?.land_use === "Concessão estatal";

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Proprietário/a",
                    plot?.holder?.name,
                    null,
                    null,
                    `/holders/all/list/${plot?.holder?.id}/summary`
                )}
                {FieldUtil.getSectionField("Uso do solo", plot?.land_use)}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Propriedade da terra",
                    plot?.land_ownership
                )}
                {displayStateConcession
                    ? FieldUtil.getSectionField(
                          "Concessão estatal",
                          plot?.state_concession
                      )
                    : null}
            </Grid>
        </Grid>
    );
};

export default PlotOwnershipDataSection;
