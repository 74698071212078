import {useState, createContext, useContext, useEffect} from "react";
import {useConfigModule} from "base/ui/module/provider";
import {FilterUtil} from "base/filter/utilities";

let ListContext = createContext(null);

export default function ListProvider({children}) {
    const {moduleFilter} = useConfigModule();

    const [elements, setElements] = useState([]);
    const [view, setView] = useState("table");
    const [filter, setFilterInternal] = useState(null);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(0);
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("asc");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("changing module filter", {moduleFilter});
        changeFilter(moduleFilter);
    }, [moduleFilter]);

    const changeFilter = propertiesChanged => {
        const cleanedFilter = FilterUtil.cleanFilter({
            ...filter,
            ...propertiesChanged,
        });
        if (!FilterUtil.equalsFilter(filter, cleanedFilter)) {
            setFilterInternal({...cleanedFilter});
            console.log("filter page changed", {cleanedFilter});
        }
    };

    const setFilterValue = (property, value) => {
        const newProperty = {};
        newProperty[property] = value;
        console.log("changing page filter property", {newProperty});
        changeFilter(newProperty);
    };

    const resetFilter = () => {
        console.log("reset page group filter", filter);
        setFilterInternal({...moduleFilter});
    };

    let value = {
        elements,
        setElements,
        view,
        setView,
        filter,
        setFilterValue,
        changeFilter,
        resetFilter,
        page,
        setPage,
        size,
        setSize,
        sort,
        setSort,
        order,
        setOrder,
        isLoading,
        setIsLoading,
    };

    return <ListContext.Provider value={value}>{children}</ListContext.Provider>;
}

function useListView() {
    return useContext(ListContext);
}

export {useListView as useList};
