class Materials extends Array {}

const material_api_adapter = material => {
    return material;
};

const materials_api_adapter = materials => {
    return materials.map(material_api_adapter);
};

const material_view_adapter = material => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside material object

    delete material["created_by"];
    delete material["updated_by"];
    delete material["created_at"];
    delete material["updated_at"];

    return material;
};

const createMaterials = (data = []) => {
    const materials = Materials.from(data, material => createMaterial(material));
    return materials;
};

const createMaterial = ({
    id = -1,
    code = "",
    species = "",
    quality = "",
    type = "",
    quantity = null,
    length = null,
    fee = null,
    price = null,
    permit = null, // int id of the permit
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        code,
        species,
        quality,
        type,
        quantity,
        length,
        fee,
        price,
        permit,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createMaterial as default,
    createMaterials,
    material_api_adapter,
    materials_api_adapter,
    material_view_adapter,
};
