import {useCallback, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";

import {useList} from "base/entity/provider";
import {useDomain} from "snimf/domain/provider";
import {useAdministrativeDivisions} from "snimf/location/provider";
import {DateUtil} from "base/format/utilities";

import {
    FormClearButtonSmall,
    FormDatePicker,
    FormSelect,
    FormSelectMultiple,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const ViolationFilterForm = ({onClear = null}) => {
    const {filter, changeFilter, resetFilter, setPage} = useList();
    const {violationTypeDomain, violationStatusDomain, landOwnershipDomain} =
        useDomain();

    const {districts} = useAdministrativeDivisions();

    useEffect(() => {
        if (!Object.keys(filter).length) {
            handleClearAllFilters();
        }
    }, [filter]);

    useEffect(() => {
        const attributeValue = {district: ""};
        let updatedFilter = {...filter, ...attributeValue};
        changeFilter(updatedFilter);
    }, [districts]);

    const formMethods = useForm({
        defaultValues: {
            on_date_min: filter?.on_date_min || null,
            on_date_max: filter?.on_date_max || null,
            status: filter?.status || "",
            district: filter?.district || "",
            types: filter?.types || [],
            plot__land_ownership: filter?.plot__land_ownership || "",
        },
    });

    const handleChange = useCallback(
        attributeValue => {
            setPage(1);

            if (
                isInvalidDate(attributeValue, "on_date_min") ||
                isInvalidDate(attributeValue, "on_date_max")
            ) {
                return;
            } else {
                let updatedFilter = {...filter, ...attributeValue};
                changeFilter(updatedFilter);
            }
        },
        [filter]
    );

    const handleClearAllFilters = () => {
        formMethods.reset({
            on_date_min: null,
            on_date_max: null,
            status: "",
            district: "",
            types: [],
            plot__land_ownership: "",
        });
        if (onClear) {
            onClear();
        }
        if (Object.entries(filter).length > 0) resetFilter();
    };

    const isInvalidDate = (attributeValue, key) =>
        key in attributeValue && DateUtil.isInvalidDateObject(attributeValue[key]);

    return (
        <FormProvider {...formMethods}>
            <Grid container columnSpacing={1} alignItems="center" mt={1}>
                <Grid item xs={2}>
                    <FormDatePicker
                        name="on_date_min"
                        label="Infracção após"
                        onChangeHandler={date => handleChange({on_date_min: date})}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormDatePicker
                        name="on_date_max"
                        label="Infracção anterior a"
                        onChangeHandler={date => handleChange({on_date_max: date})}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormSelect
                        name="status"
                        label="Estado"
                        options={violationStatusDomain}
                        onChangeHandler={options => {
                            handleChange({status: options});
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormSelect
                        name="district"
                        label="Distrito"
                        options={districts}
                        onChangeHandler={option =>
                            handleChange({
                                district: option,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <FormSelect
                        name="plot__land_ownership"
                        label="Propriedade da terra"
                        options={landOwnershipDomain}
                        onChangeHandler={option =>
                            handleChange({
                                plot__land_ownership: option,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormSelectMultiple
                        name="types"
                        label="Tipo infracção"
                        options={violationTypeDomain}
                        onChangeHandler={options => {
                            handleChange({types: options});
                        }}
                    />
                </Grid>

                <Grid item xs={0.5}>
                    <FormClearButtonSmall handleClear={handleClearAllFilters} />
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default ViolationFilterForm;
