import {
    FormDatePickerCombo,
    FormInputDecimal,
    FormInputText,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const PermitApplicationClearingFormFields = ({disabled}) => {
    return (
        <Grid container columnSpacing={1}>
            <FormDatePickerCombo
                startDateName={"clearing_start_date"}
                startDateLabel={"Data de início"}
                endDateName={"clearing_end_date"}
                endDateLabel={"Data de caducidade"}
                disabled={disabled}
            />

            <Grid item xs={12} sm={6}>
                <FormInputText
                    name="clearing_beneficiary"
                    label="Beneficiário/a"
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormInputDecimal
                    name="clearing_area"
                    label="Área a desbravar"
                    endAdornment="ha"
                    rules={{required: "Este campo é obligatório"}}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default PermitApplicationClearingFormFields;
