import {theme} from "Theme";
import {Tag} from "base/shared/components";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";

const MaterialChip = ({element = null}) => {
    return (
        <Tag
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                ml: 0,
                pr: 1.5,
                backgroundColor: "primary.dark",
            }}
        >
            <Stack mr={1} color="primary.light">
                <ForestOutlinedIcon />
            </Stack>
            <div>
                <Typography
                    component="span"
                    fontSize={theme.typography.fontSize - 1}
                    color="white"
                >
                    {element.code}
                </Typography>
                <Typography lineHeight={1.25} color="white">
                    <strong>{element.species}</strong> | {element.quality}
                </Typography>
            </div>
        </Tag>
    );
};

export default MaterialChip;
