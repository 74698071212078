import {ViewDocumentPanel} from "base/file/components";
import {Navigate, Route} from "react-router-dom";
import {
    ListHoldersPage,
    ViewHolderDocumentsSubPage,
    ViewHolderPage,
    ViewHolderPlotsSubPage,
    ViewHolderProceduresSubPage,
    ViewHolderSummarySubPage,
} from "holder/container";

const holderRoutes = [
    <Route key="holder-manage" path="all/list">
        <Route key="holders-list" path="" element={<ListHoldersPage />} />
        <Route key="holder-detail" path=":id" element={<ViewHolderPage />}>
            <Route
                key="holder-summary"
                path="summary"
                element={<ViewHolderSummarySubPage />}
            />
            <Route
                key="holder-procedures"
                path="procedures"
                element={<ViewHolderProceduresSubPage />}
            />
            <Route
                key="holder-plots"
                path="plots"
                element={<ViewHolderPlotsSubPage />}
            />
            <Route
                key="holder-documents"
                path="documents/*"
                element={<ViewHolderDocumentsSubPage />}
            >
                <Route
                    key="holder-documents-view"
                    path="detail/*"
                    element={<ViewDocumentPanel />}
                />
            </Route>
            <Route index element={<Navigate to="summary" replace />} />
        </Route>
    </Route>,

    <Route
        key="redirect-holder-index"
        index
        element={<Navigate to="list" replace />}
    />,
];

export default holderRoutes;
