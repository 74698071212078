import {createEntityService} from "base/entity/service";
import {AuthApiService} from "base/api/service";
import {
    createHolder,
    createHolders,
    holder_api_adapter,
    holders_api_adapter,
} from "holder/model";
import {createPlots, plots_api_adapter} from "plot/model";
import {createPermits, permits_api_adapter} from "permit/model";
import {createViolations, violations_api_adapter} from "violation/model";
import {createPlantations, plantations_api_adapter} from "plantation/model";

const basePath = "/api/app/holders";

const entityService = createEntityService(
    basePath,
    createHolder,
    createHolders,
    holder_api_adapter,
    holders_api_adapter
);

const HolderService = {
    get(id) {
        return entityService.get(id);
    },

    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    getPlots(holderId) {
        return AuthApiService.get(`${basePath}/${holderId}/plots`).then(response => {
            return createPlots(plots_api_adapter(response));
        });
    },

    getPermits(holderId) {
        return AuthApiService.get(`${basePath}/${holderId}/permits`).then(response => {
            return createPermits(permits_api_adapter(response));
        });
    },

    getViolations(holderId) {
        return AuthApiService.get(`${basePath}/${holderId}/violations`).then(
            response => {
                return createViolations(violations_api_adapter(response));
            }
        );
    },

    getPlantations(holderId) {
        return AuthApiService.get(`${basePath}/${holderId}/plantations`).then(
            response => {
                return createPlantations(plantations_api_adapter(response));
            }
        );
    },

    create(holder) {
        return entityService.create(holder);
    },

    update(holder) {
        return entityService.update(holder);
    },

    canBeDeleted(id) {
        return entityService.canBeDeleted(id).then(response => {
            if (response.result === "success") {
                return [true, ""];
            }
            return [false, response.detail];
        });
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default HolderService;
