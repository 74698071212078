import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const EntityFormSubmitButton = ({onSubmit, isSubmitting, isFormDirty = true}) => {
    return (
        <Button
            variant="contained"
            onClick={() => onSubmit()}
            disabled={isSubmitting || !isFormDirty}
            startIcon={<SaveOutlinedIcon />}
        >
            Salvar
            {isSubmitting && <CircularProgress size={15} sx={{ml: 1}} />}
        </Button>
    );
};

export default EntityFormSubmitButton;
