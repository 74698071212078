import {PermitApplicationService} from "permitapplication/service";

import {useAuth} from "base/user/provider";
import {useCreateEntity} from "base/entity/hooks";
import {usePermitApplicationsTable} from "permitapplication/data";

import {EntityListPage} from "base/entity/components/container";
import {PermitApplicationFilterForm} from "permitapplication/presentational/form";
import {CreatePermitApplicationForm} from ".";

const ListPermitApplicationsPage = () => {
    const {tableColumns, getCellProps} = usePermitApplicationsTable();
    const auth = useAuth();
    let {
        button: createButton,
        dialog: createDialog,
        handleClickClose: closeDialog,
    } = useCreateEntity(
        null,
        "Nova solicitação",
        <CreatePermitApplicationForm onCancel={() => closeDialog()} />
    );

    if (!auth.hasPermission("create-permitapplications")) {
        createDialog = null;
        createButton = null;
    }

    return (
        <>
            {createDialog}
            <EntityListPage
                views={["table"]}
                entityName={{
                    singular: "Autorização em processo",
                    plural: "Autorizações em processo",
                }}
                service={PermitApplicationService}
                tableColumns={tableColumns}
                getCellProps={getCellProps}
                filterForm={<PermitApplicationFilterForm />}
                createButton={createButton}
                disableNavigation
            />
        </>
    );
};
export default ListPermitApplicationsPage;
