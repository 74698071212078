import {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {FormDatePicker} from ".";
import Grid from "@mui/material/Grid";

const FormDatePickerCombo = ({
    startDateName,
    startDateLabel,
    endDateName,
    endDateLabel,
    required = false,
    disabled = false,
}) => {
    const {watch, clearErrors} = useFormContext();
    const [minEndDate, setMinEndDate] = useState(null);

    const startDate = watch(startDateName);
    const endDate = watch(endDateName);

    const validationErrorMessage = `A ${startDateLabel.toLowerCase()} não pode ser posterior à ${endDateLabel.toLowerCase()}.`;

    useEffect(() => {
        if (startDate) setMinEndDate(new Date(startDate));
    }, [startDate]);

    useEffect(() => {
        if (validateEndDate(endDate)) clearErrors(startDateName);
    }, [endDate]);

    const validateEndDate = endDate => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start && end < start) {
            return validationErrorMessage;
        }
        return true;
    };

    const validateStartDate = startDate => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start && end < start) {
            return validationErrorMessage;
        }
        return true;
    };

    return (
        <>
            <Grid item xs={12} sm={6}>
                <FormDatePicker
                    name={startDateName}
                    label={startDateLabel}
                    disabled={disabled}
                    disableFuture={false}
                    rules={{
                        required: required,
                        validate: {
                            checkEndDate: validateStartDate,
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormDatePicker
                    name={endDateName}
                    label={endDateLabel}
                    disabled={disabled}
                    disableFuture={false}
                    minDate={minEndDate}
                    rules={{
                        required: required,
                        validate: {
                            checkEndDate: validateEndDate,
                        },
                    }}
                />
            </Grid>
        </>
    );
};

export default FormDatePickerCombo;
