import {useOutletContext} from "react-router-dom";
import {useEntityPermissions} from "snimf/permissions/hooks";
import {EntityViewSubPage} from "base/entity/components/container";
import {ViewOrUpdatePlantationPlotContent} from ".";

const ViewPlantationPlotSubPage = () => {
    const context = useOutletContext();
    const plantation = context[0];

    const {allowEdit} = useEntityPermissions(plantation, "plantations");

    let sections = [
        <ViewOrUpdatePlantationPlotContent
            key="plot"
            plantation={plantation}
            allowEdit={allowEdit}
        />,
    ];

    return plantation && <EntityViewSubPage sections={sections} />;
};

export default ViewPlantationPlotSubPage;
