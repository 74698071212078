import {DateUtil} from "base/format/utilities";
import {TextLink} from "base/navigation/components";

export function usePlantsTable() {
    const tableColumns = [
        {
            id: "code",
            label: "Código",
            width: 25,
            formatFunction: item => (
                <TextLink
                    text={item?.code}
                    to={`#${item.code}`}
                    textStyle={{fontSize: "inherit"}}
                    isHashLink
                />
            ),
        },
        {
            id: "species",
            label: "Espécie",
            width: 25,
        },
        {
            id: "transplant_date",
            label: "Data de transplante",
            width: 25,
            formatFunction: item => {
                return DateUtil.formatDate(item.transplant_date);
            },
        },
        {
            id: "quantity",
            label: "Quantidade",
            width: 25,
        },
    ];

    return {tableColumns};
}
