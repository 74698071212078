import {EntityFormSubmitButton} from ".";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const VerticalForm = ({children, onSubmit, onCancel, isSubmitting, isFormDirty}) => {
    return (
        <Box component="form" width="100%">
            {children}
            <Grid container justifyContent="center" spacing={1} sx={{mt: 1}}>
                {onCancel && (
                    <Grid item>
                        <Button
                            onClick={onCancel}
                            variant="outlined"
                            sx={{backgroundColor: "white"}}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <EntityFormSubmitButton
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                        isFormDirty={isFormDirty}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const HorizontalForm = ({children, onSubmit, onCancel, isSubmitting, isFormDirty}) => {
    return (
        <Grid container direction="row" component="form" alignItems="center">
            <Grid item xs={onCancel ? 8 : 9} container>
                {children}
            </Grid>
            <Grid
                item
                xs={onCancel ? 3 : 2}
                container
                direction="row"
                justifyContent="flex-start"
                marginLeft={1}
                spacing={1}
            >
                {onCancel && (
                    <Grid item>
                        <Button
                            onClick={onCancel}
                            variant="outlined"
                            sx={{backgroundColor: "white"}}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <EntityFormSubmitButton
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                        isFormDirty={isFormDirty}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
const EntityForm = ({
    children,
    onSubmit,
    onCancel = null,
    isSubmitting = false,
    isFormDirty = true,
    direction = "column",
}) => {
    return direction === "column" ? (
        <VerticalForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
            isFormDirty={isFormDirty}
        >
            {children}
        </VerticalForm>
    ) : (
        <HorizontalForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isSubmitting={isSubmitting}
            isFormDirty={isFormDirty}
        >
            {children}
        </HorizontalForm>
    );
};

export default EntityForm;
