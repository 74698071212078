import {SubPageMenu, SubPageMenuListItemButton} from "base/ui/menu";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import FolderOpenIconOutlined from "@mui/icons-material/FolderOpenOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import GrassIcon from "@mui/icons-material/Grass";

const PlantationSubPageMenu = ({plantation}) => {
    const basePath = `/plantations/all/list/${plantation?.id}`;

    const secondary = (
        <>
            <strong>{plantation?.holder?.name || "Sem requerente"}</strong>
            <br />
            {plantation?.district || "Sem distrito"}
        </>
    );

    return (
        <SubPageMenu
            headerTitle="Plantio"
            headerText={`${plantation?.code}`}
            headerSecondary={secondary}
        >
            <SubPageMenuListItemButton
                key="plantation-summary"
                to={`${basePath}/summary`}
                text="Resumo"
                icon={<InfoOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="plantation-holder"
                to={`${basePath}/holder`}
                text="Requerente"
                icon={<ContactPageOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="plantation-plot"
                to={`${basePath}/plot`}
                text="Parcela"
                icon={<PlaceOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="plantation-plants"
                to={`${basePath}/plants`}
                text="Plantas"
                icon={<GrassIcon />}
            />
            <SubPageMenuListItemButton
                key="plantation-documents"
                to={`${basePath}/documents`}
                text="Documentos"
                icon={<FolderOpenIconOutlined />}
            />
        </SubPageMenu>
    );
};

export default PlantationSubPageMenu;
