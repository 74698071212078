import {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";

import {useEntityPermissions} from "snimf/permissions/hooks";
import {PlantationService} from "plantation/service";

import {ViewPlantationPlantsContent, ViewPlantationPlantsTable} from ".";
import {EntityViewSubPage} from "base/entity/components/container";
import {Spinner} from "base/shared/components";

const ViewPlantationPlantsSubPage = () => {
    const context = useOutletContext();
    const plantation = context[0];
    const {allowEdit} = useEntityPermissions(plantation, "plantations");

    const [plants, setPlants] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (plantation) {
            PlantationService.getPlants(plantation.id)
                .then(response => {
                    setPlants(response);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [plantation]);

    let sections = isLoading
        ? [<Spinner key="spinner" />]
        : [
              <ViewPlantationPlantsTable
                  key="table"
                  plantationId={plantation?.id}
                  plants={plants}
                  allowEdit={allowEdit}
              />,
              <ViewPlantationPlantsContent
                  key="details"
                  plantationId={plantation?.id}
                  plants={plants}
                  allowEdit={allowEdit}
              />,
          ];

    return plantation && <EntityViewSubPage sections={sections} />;
};

export default ViewPlantationPlantsSubPage;
