import {useOutletContext} from "react-router-dom";
import {HolderService} from "holder/service";
import {useHolderPlotsTable} from "holder/data";

import {EntityViewSubPage} from "base/entity/components/container";
import {LinkedEntitySection} from "base/entity/components/presentational";

const ViewHolderPlotsSubPage = () => {
    const {tableColumns} = useHolderPlotsTable();

    const context = useOutletContext();
    const holder = context[0];

    const sections = [
        <LinkedEntitySection
            key="Parcelas"
            entityId={holder?.id}
            linkedEntityName="Parcelas"
            getItems={HolderService.getPlots}
            tableColumns={tableColumns}
        />,
    ];

    return holder && <EntityViewSubPage sections={sections} />;
};

export default ViewHolderPlotsSubPage;
