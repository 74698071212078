import {useList} from "base/entity/provider";
import {useMapContext} from "base/map";
import {MapBaseLayout} from "base/map/layout";
import {useEffect} from "react";

const mapStyle = {
    width: "100%",
    height: "650px",
};

const EntityListMap = () => {
    const {filter} = useList();
    const {updateMapFilter} = useMapContext();

    useEffect(() => {
        console.log("useEffect EntityListMap", filter);
        updateMapFilter(filter);
    }, [filter]);

    return <MapBaseLayout />;
};

export default EntityListMap;
