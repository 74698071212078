import {FormProvider, useForm} from "react-hook-form";

import {LocationProvider} from "snimf/location/provider";
import {FormUtil} from "base/form/utilities";

import {EntityForm} from "base/entity/components/form";
import {AlertError} from "base/error/components";
import Stack from "@mui/material/Stack";

const PlantationForm = ({
    plantation = null,
    onSubmit,
    onCancel = null,
    error = null,
    children,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(plantation?.id),
        code: FormUtil.getFormValue(plantation?.code),
        application_date: FormUtil.getFormValue(plantation?.application_date),
        on_date: FormUtil.getFormValue(plantation?.on_date),
        delivery_date: FormUtil.getFormValue(plantation?.delivery_date),
        area: FormUtil.getFormValue(plantation?.area),
        quantity: FormUtil.getFormValue(plantation?.quantity),
        district: FormUtil.getFormValue(plantation?.district),
        comments: FormUtil.getFormValue(plantation?.comments),
        geom: FormUtil.getFormValue(plantation?.geom),
        holder: FormUtil.getFormValue(plantation?.holder),
        plot: FormUtil.getFormValue(plantation?.plot),
        plants: FormUtil.getFormValue(plantation?.plants, []),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const handleFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            code: FormUtil.getDataValue(data.code),
            application_date: FormUtil.getDataValue(data.application_date),
            on_date: FormUtil.getDataValue(data.on_date),
            delivery_date: FormUtil.getDataValue(data.delivery_date),
            area: FormUtil.getDataValue(data.area),
            quantity: FormUtil.getDataValue(data.quantity),
            district: FormUtil.getDataValue(data.district),
            comments: FormUtil.getDataValue(data.comments),
            geom: FormUtil.getDataValue(data.geom),
            actions: FormUtil.getDataValue(data.actions),
            permit: FormUtil.getDataValue(data.permit),
            holder: FormUtil.getDataValue(data.holder),
            plot: FormUtil.getDataValue(data.plot),
            plants: FormUtil.getDataValue(data.plants),
        });
    };

    return (
        <LocationProvider>
            <FormProvider {...formMethods}>
                <Stack spacing={1}>
                    <AlertError error={error} />
                    <EntityForm
                        onSubmit={formMethods.handleSubmit(handleFormSubmit)}
                        onCancel={onCancel}
                    >
                        {children}
                    </EntityForm>
                </Stack>
            </FormProvider>
        </LocationProvider>
    );
};

export default PlantationForm;
