import {theme} from "Theme";
import {Tag} from "base/shared/components";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import GrassIcon from "@mui/icons-material/Grass";

const PlantChip = ({element = null}) => {
    return (
        <Tag
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                ml: 0,
                pr: 1.5,
                backgroundColor: "none",
                border: `2px solid ${theme.palette.primary.light}`,
            }}
        >
            <Stack mr={1} color="primary.light">
                <GrassIcon />
            </Stack>
            <div>
                <Typography
                    component="span"
                    fontSize={theme.typography.fontSize - 1}
                    color="primary.dark"
                    gutterBottom
                >
                    {element.code}
                </Typography>
                <Typography lineHeight={1.25} color="primary.dark">
                    <strong>{element.species}</strong>
                </Typography>
            </div>
        </Tag>
    );
};

export default PlantChip;
