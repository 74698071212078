import {TextLink} from "base/navigation/components";

export function usePlotsTable() {
    const tableColumns = [
        {
            id: "code",
            label: "Código",
            width: 15,
            formatFunction: item => (
                <TextLink
                    text={item?.code}
                    to={`/plots/all/list/${item.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
        },
        {
            id: "number",
            label: "Número",
            width: 10,
        },
        {
            id: "district",
            label: "Distrito",
            width: 25,
        },
        {
            id: "holder__name",
            label: "Proprietário/a",
            width: 25,
            formatFunction: item => (
                <TextLink
                    text={item?.holder?.name}
                    to={`/holders/all/list/${item.holder?.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
        },
        {
            id: "land_ownership",
            label: "Propriedade da terra",
            width: 25,
        },
    ];

    return {tableColumns};
}
