import {useOutletContext} from "react-router-dom";

import {MapBaseLayout} from "base/map/layout";
import {EntityViewSubPage} from "base/entity/components/container";
import {SectionCard} from "base/ui/section/components";

import {PlotTreesMapProvider} from "plot/map";
import {PlotBasicDataSection} from "plot/presentational";

import Box from "@mui/material/Box";

const ViewApprovedPermitPlotSubPage = () => {
    const context = useOutletContext();
    const entity = context[0];

    let sections = [
        <SectionCard title="Parcela" key="plot">
            <PlotBasicDataSection plot={entity?.plot} />
        </SectionCard>,
    ];
    if (entity?.plot) {
        sections.push(
            <SectionCard title="Mapa">
                <Box sx={{border: 1, borderColor: "grey.400"}}>
                    <PlotTreesMapProvider permit={entity}>
                        <MapBaseLayout height="300px" rightBarOptions={{show: false}} />
                    </PlotTreesMapProvider>
                </Box>
            </SectionCard>
        );
    }

    return entity && <EntityViewSubPage sections={sections} />;
};

export default ViewApprovedPermitPlotSubPage;
