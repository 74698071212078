import {useState} from "react";

import {PlantationService} from "plantation/service";
import {plantation_view_adapter} from "plantation/model";
import {plot_view_adapter} from "plot/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {SectionCard, SectionCardHeaderAction} from "base/ui/section/components";
import {PlantationForm} from "plantation/presentational/form";
import {
    PlotForm,
    PlotInitialDataFormFields,
    PlotFormSearch,
} from "plot/presentational/form";
import {FormContainer} from "base/form/components";
import {PlotBasicDataSection, PlotMapSection} from "plot/presentational";
import {EntityAddButtonGroup} from "base/entity/components/presentational";
import {NoDataContainer} from "base/shared/components";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";

const ViewOrUpdatePlantationPlotContent = ({plantation, allowEdit = true}) => {
    const navigate = useNavigateWithReload();
    const plot = plantation.plot;

    const [selectedPlot, setSelectedPlot] = useState(null);
    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleSelectPlot = plot => {
        setSelectedPlot(plot);
    };

    const handleCreateFormSubmit = plot => {
        PlantationService.createPlot(plantation.id, plot_view_adapter({...plot}))
            .then(createdPlot => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleEditFormSubmit = () => {
        PlantationService.update(
            plantation_view_adapter({...plantation, plot: selectedPlot})
        )
            .then(updatedPlantation => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const secondaryActions = plot?.id
        ? [
              <SectionCardHeaderAction
                  key="edit"
                  name="edit"
                  text="Modificar"
                  icon={<EditIcon />}
                  onClick={() => {
                      setMode("search");
                  }}
              />,
          ]
        : null;

    const getComponent = mode => {
        if (mode === "view") {
            return plot ? (
                <PlotBasicDataSection plot={plot} />
            ) : (
                <NoDataContainer
                    text="Esta infracção ainda não tem uma parcela associada."
                    button={
                        allowEdit && (
                            <EntityAddButtonGroup
                                onAdd={() => setMode("new")}
                                onSearch={() => setMode("search")}
                            />
                        )
                    }
                />
            );
        }
        if (mode === "new") {
            return (
                <PlotForm
                    onSubmit={handleCreateFormSubmit}
                    onCancel={() => {
                        setMode("view");
                    }}
                    error={error}
                >
                    <FormContainer>
                        <PlotInitialDataFormFields />
                    </FormContainer>
                </PlotForm>
            );
        }
        if (mode === "search") {
            return (
                <FormContainer>
                    <PlantationForm
                        plantation={plantation}
                        onSubmit={handleEditFormSubmit}
                        onCancel={() => {
                            setMode("view");
                        }}
                        error={error}
                    >
                        <PlotFormSearch onClickSelected={handleSelectPlot} />
                    </PlantationForm>
                </FormContainer>
            );
        }
    };

    return (
        <Stack spacing={1}>
            <SectionCard
                title="Parcela"
                secondaryActions={allowEdit ? secondaryActions : null}
            >
                {getComponent(mode)}
            </SectionCard>
            {mode === "view" && plot && (
                <PlotMapSection plot={plot} editablePlot={false} />
            )}
        </Stack>
    );
};

export default ViewOrUpdatePlantationPlotContent;
