import {AlertError} from "base/error/components";
import Grid from "@mui/material/Grid";

const EntityViewSubPage = ({sections = [], children = null, error = null}) => {
    const sectionsToDisplay = sections.map((section, index) => (
        <Grid item xs={12} key={index} id={`section-${index + 1}`}>
            {section}
        </Grid>
    ));

    return (
        <>
            <AlertError error={error} />
            <Grid container spacing={1} justifyContent="flex-end">
                {sections.length ? sectionsToDisplay : null}
                {children ? <Grid item>{children}</Grid> : null}
            </Grid>
        </>
    );
};

export default EntityViewSubPage;
