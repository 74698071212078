import {useState, useEffect} from "react";
import {NumberUtil} from "base/format/utilities";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const EntityCounter = ({size, entityName, isLoading}) => {
    // Use local state based on isLoading to avoid component flickering effect
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        if (isLoading) setShouldRender(true);
    }, [isLoading]);

    if (!shouldRender) {
        return null;
    }

    return !isLoading && (size || size === 0) ? (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "fit-content",
                px: 1,
                border: 1,
                borderRadius: 1,
                color: "grey.700",
                textTransform: "uppercase",
            }}
        >
            <Typography
                component="span"
                variant="h4"
                sx={{
                    mr: 1,
                }}
            >
                {NumberUtil.formatInteger(size)}
            </Typography>
            <Typography variant="body1">
                {size === 1 ? entityName.singular : entityName.plural}
            </Typography>
        </Box>
    ) : null;
};

export default EntityCounter;
