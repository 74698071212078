import {useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "base/file/components";

const ViewHolderDocumentsSubPage = () => {
    let holder;
    [holder] = useOutletContext();

    return (
        holder && (
            <ViewDocumentsSubPage entity={holder} basePath={"/holders/all/list"} />
        )
    );
};

export default ViewHolderDocumentsSubPage;
