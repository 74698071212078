import {useEffect} from "react";
import {useFormContext, useWatch} from "react-hook-form";

function useDocFieldsValue() {
    const {setValue} = useFormContext();

    const [
        application_doc,
        personal_id_doc,
        plot_ownership_doc,
        plot_use_authorization,
        tax_id_doc,
        technical_report,
    ] = useWatch({
        name: [
            "application_doc",
            "personal_id_doc",
            "plot_ownership_doc",
            "plot_use_authorization",
            "tax_id_doc",
            "technical_report",
        ],
    });

    const checkDocumentValidity = (doc, validationField) => {
        if (!doc) {
            setValue(validationField, false);
        }
    };

    useEffect(() => {
        checkDocumentValidity(application_doc, "application_doc_valid");
    }, [application_doc]);

    useEffect(() => {
        checkDocumentValidity(personal_id_doc, "personal_id_doc_valid");
    }, [personal_id_doc]);

    useEffect(() => {
        checkDocumentValidity(plot_ownership_doc, "plot_ownership_doc_valid");
    }, [plot_ownership_doc]);

    useEffect(() => {
        checkDocumentValidity(plot_use_authorization, "plot_use_authorization_valid");
    }, [plot_use_authorization]);

    useEffect(() => {
        checkDocumentValidity(tax_id_doc, "tax_id_doc_valid");
    }, [tax_id_doc]);

    return {
        application_doc,
        personal_id_doc,
        plot_ownership_doc,
        plot_use_authorization,
        tax_id_doc,
        technical_report,
    };
}

export {useDocFieldsValue};
