import {SubPageMenu, SubPageMenuListItemButton} from "base/ui/menu";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import WallpaperIcon from "@mui/icons-material/Wallpaper";

const HolderSubPageMenu = ({holder}) => {
    const basePath = `/holders/all/list/${holder?.id}`;

    return (
        <SubPageMenu
            headerTitle="Titular"
            headerText={`${holder?.name}`}
            headerSecondary={holder?.type || null}
        >
            <SubPageMenuListItemButton
                key="holder-summary"
                to={`${basePath}/summary`}
                text="Resumo"
                icon={<InfoOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="holder-procedures"
                to={`${basePath}/procedures`}
                text="Procedimentos"
                icon={<SummarizeOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="holder-plots"
                to={`${basePath}/plots`}
                text="Parcelas"
                icon={<WallpaperIcon />}
            />
        </SubPageMenu>
    );
};

export default HolderSubPageMenu;
