import {useState} from "react";

import {ViolationService} from "violation/service";
import {violation_view_adapter} from "violation/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {ViolationActionsFormFields, ViolationForm} from "violation/presentational/form";
import {ViolationActionsSection} from "violation/presentational";
import {SectionCard, SectionCardHeaderAction} from "base/ui/section/components";
import {FormContainer} from "base/form/components";
import {EntityAddButton} from "base/entity/components/presentational";
import {NoDataContainer} from "base/shared/components";
import EditIcon from "@mui/icons-material/Edit";

const ViewOrUpdateViolationActionsContent = ({violation, allowEdit = true}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleFormSubmit = violation => {
        ViolationService.update(violation_view_adapter({...violation}))
            .then(updatedViolation => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const getComponent = mode => {
        if (mode === "view") {
            return violation?.actions.length ? (
                <ViolationActionsSection violation={violation} />
            ) : (
                <NoDataContainer
                    text="Ainda não foi definida nenhuma acção para esta infracção."
                    button={
                        allowEdit && <EntityAddButton onClick={() => setMode("edit")} />
                    }
                />
            );
        }
        if (mode === "edit") {
            return (
                <FormContainer>
                    <ViolationForm
                        violation={violation}
                        onSubmit={handleFormSubmit}
                        onCancel={() => {
                            setMode("view");
                        }}
                        error={error}
                    >
                        <ViolationActionsFormFields />
                    </ViolationForm>
                </FormContainer>
            );
        }
    };

    const actions = violation?.actions.length
        ? [
              <SectionCardHeaderAction
                  key="edit"
                  name="edit"
                  text="Modificar"
                  icon={<EditIcon />}
                  onClick={() => {
                      setMode("edit");
                  }}
              />,
          ]
        : null;

    return (
        <SectionCard title="Resposta" secondaryActions={allowEdit ? actions : null}>
            {getComponent(mode)}
        </SectionCard>
    );
};

export default ViewOrUpdateViolationActionsContent;
