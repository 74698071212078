import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {PermitApplicationService} from "permitapplication/service";
import {RouterUtil} from "base/navigation/utilities";
import {useNavigateWithReload} from "base/navigation/hooks";
import {useFormDirtyContext} from "base/form/provider";
import {
    usePermitApplicationProcess,
    usePermitApplicationProcessUrl,
    usePermitApplicationSteps,
    usePermitFieldStatus,
} from "permitapplication/hooks";

import {PermitApplicationSummary} from "permitapplication/presentational";
import {HorizontalStepper} from "base/stepper/components";
import {AlertError} from "base/error/components";
import Stack from "@mui/material/Stack";

const ViewPermitApplicationWidget = ({permit, process}) => {
    const navigate = useNavigateWithReload();
    const location = useLocation();
    const basePath = `/permits/applications/list/${permit.id}`;
    const stepPath = RouterUtil.getLastUrlSegment(location);

    const {completedSteps, updateCurrentStep, isStepEditable} =
        usePermitApplicationProcess(permit);
    const {steps, allSteps} = usePermitApplicationSteps(
        permit,
        isStepEditable,
        process
    );
    const {getProcessPath} = usePermitApplicationProcessUrl(permit, basePath);
    const {isProcessRevertible} = usePermitFieldStatus(permit);
    const {isFormDirty} = useFormDirtyContext();

    const [error, setError] = useState(null);

    const activeStepIndex = steps.findIndex(step => step.path === stepPath);
    const isFinalStep = steps[activeStepIndex]?.order === allSteps.length - 1;

    useEffect(() => {
        if (permit) updateCurrentStep(permit.status);
    }, [permit, location.state?.lastRefreshDate]);

    const handleChangeStep = step => {
        navigate(`${basePath}/${process}/${step.path}`);
    };

    const handleCompleteStep = () => {
        PermitApplicationService.updateStatus(permit.id, {data: "next"})
            .then(updatedPermit => {
                updateCurrentStep(updatedPermit.status);
                const processPath = getProcessPath(updatedPermit, basePath);
                navigate(`${processPath}`, true);
            })
            .catch(error => {
                setError(error);
                console.log(error);
            });
    };

    const handleRevertStep = () => {
        PermitApplicationService.updateStatus(permit.id, {data: "previous"})
            .then(updatedPermit => {
                updateCurrentStep(updatedPermit.status);
                const processPath = getProcessPath(updatedPermit, basePath);
                navigate(`${processPath}`, true);
            })
            .catch(error => {
                setError(error);
                console.log(error);
            });
    };

    const handelCancelProcess = () => {
        PermitApplicationService.updateStatus(permit.id, {data: "denied"})
            .then(updatedPermit => {
                updateCurrentStep(updatedPermit.status);
                navigate(`/permits/applications/list`);
            })
            .catch(error => {
                setError(error);
                console.log(error);
            });
    };

    const handelApproveProcess = () => {
        PermitApplicationService.updateStatus(permit.id, {data: "approved"})
            .then(updatedPermit => {
                updateCurrentStep(updatedPermit.status);
                navigate(`/permits/approved/list`);
            })
            .catch(error => {
                setError(error);
                console.log(error);
            });
    };

    const displayAlert = () => {
        return (
            steps?.length &&
            !steps[activeStepIndex]?.isStepUntouched &&
            steps[activeStepIndex]?.uncompleteFields.length
        );
    };

    const disableCompleteButton = permit?.technical_decision === "Negativo";
    const disableBackButton =
        permit?.status === "Pendente vistoria terreno (MFCE-M)" && !isProcessRevertible;

    return permit && steps ? (
        <Stack spacing={1}>
            <AlertError error={error} />
            <PermitApplicationSummary permit={permit} basePath={basePath} />
            <HorizontalStepper
                steps={steps}
                activeStepIndex={activeStepIndex}
                completedSteps={completedSteps}
                disableNavigation={!isStepEditable || isFormDirty}
                disableCompleteButton={disableCompleteButton}
                disableBackButton={disableBackButton}
                displayAlert={displayAlert()}
                onChangeStep={handleChangeStep}
                onCompleteStep={handleCompleteStep}
                onRevertStep={handleRevertStep}
                onCancelProcess={handelCancelProcess}
                onApproveProcess={isFinalStep ? handelApproveProcess : null}
            />
        </Stack>
    ) : null;
};

export default ViewPermitApplicationWidget;
