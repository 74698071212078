import {CURRENCY_SYMBOL} from "base/format/config/i18n";

const MaterialUtil = {
    getUnitsForQuantity(type) {
        if (type === "Lenha") return "carinhas";
        else return "un.";
    },

    getUnitsForFee(type) {
        let unit = "un.";
        if (type === "Lenha") unit = "carinha";
        if (type === "Viga" || type === "Sobre-viga") unit = "m";

        return `${CURRENCY_SYMBOL}/${unit}`;
    },

    displayLength(type) {
        return type === "Viga" || type === "Sobre-viga";
    },
};

export default MaterialUtil;
