import Button from "@mui/material/Button";

const EntityAddButton = ({onClick}) => {
    return (
        <Button
            id="menu-button"
            color="primary"
            variant="contained"
            onClick={() => {
                onClick();
            }}
        >
            Acrescentar
        </Button>
    );
};

export default EntityAddButton;
