import {useAdministrativeDivisions} from "snimf/location/provider";
import {useViolationDocTypes} from "violation/hooks";

import {FormDatePicker, FormSelect, FormSelectMultiple} from "base/form/components";
import {PermitSearchAutocomplete} from "permit/presentational";
import Grid from "@mui/material/Grid";

const ViolationInitialFormFields = () => {
    const {userDistricts} = useAdministrativeDivisions();
    const {violationTypes} = useViolationDocTypes();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormDatePicker
                    name="on_date"
                    label="Data"
                    rules={{required: "Este campo é obrigatório."}}
                />
                <PermitSearchAutocomplete />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="district"
                    label="Distrito"
                    options={userDistricts}
                    rules={{required: "Este campo é obrigatório."}}
                />
                <FormSelectMultiple
                    name="types"
                    label="Tipo de infracção"
                    options={violationTypes}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
        </Grid>
    );
};

export default ViolationInitialFormFields;
