import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {FieldUtil} from "base/ui/section/utilities";
import {NumberUtil} from "base/format/utilities";
import Grid from "@mui/material/Grid";

const TreeDataFields = ({tree, displayFees = true}) => {
    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Altura",
                    NumberUtil.formatDecimal(tree.height),
                    "m"
                )}
                {FieldUtil.getSectionField(
                    "Volume",
                    NumberUtil.formatDecimal(tree.volume),
                    "m³"
                )}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Diámetro AP",
                    NumberUtil.formatDecimal(tree.diameter),
                    "m"
                )}
                {tree.cutting
                    ? FieldUtil.getSectionField("Nº de toros", tree.number_of_logs)
                    : null}
            </Grid>

            <Grid item xs={6}>
                {tree.felling && displayFees
                    ? FieldUtil.getSectionField(
                          "Taxa de abate",
                          NumberUtil.formatCurrency(tree.felling_fee, false),
                          `${CURRENCY_SYMBOL}/m³`
                      )
                    : null}
                {tree.cutting && displayFees
                    ? FieldUtil.getSectionField(
                          "Taxa de serragem",
                          NumberUtil.formatCurrency(tree.cutting_fee, false),
                          `${CURRENCY_SYMBOL}/toro`
                      )
                    : null}
            </Grid>
            <Grid item xs={6} sx={{marginTop: "auto"}}>
                {tree.felling && displayFees
                    ? FieldUtil.getSectionField(
                          "Valor de abate",
                          NumberUtil.formatCurrency(tree.felling_price, false),
                          CURRENCY_SYMBOL
                      )
                    : null}
                {tree.cutting && displayFees
                    ? FieldUtil.getSectionField(
                          "Valor de serragem",
                          NumberUtil.formatCurrency(tree.cutting_price, false),
                          CURRENCY_SYMBOL
                      )
                    : null}
            </Grid>
        </Grid>
    );
};

export default TreeDataFields;
