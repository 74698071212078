import {useDomain} from "snimf/domain/provider";
import {useHolderFormFields} from "holder/hooks";
import {FormInputText, FormSelect} from "base/form/components";
import Grid from "@mui/material/Grid";

const HolderInitialDataFormFields = () => {
    const {holderTypeDomain, genderDomain, docTypeDomain} = useDomain();
    const {genderLabel, isBusiness} = useHolderFormFields();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormInputText
                    name="name"
                    label="Nome"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="type"
                    label="Tipo"
                    options={holderTypeDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="doc_type"
                    options={docTypeDomain}
                    label="Tipo de documento"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="doc_number"
                    label={isBusiness ? "NIF" : "Número de BI/cartão"}
                    maxLength={20}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="gender"
                    label={genderLabel}
                    options={genderDomain}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="address"
                    label="Morada"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
        </Grid>
    );
};

export default HolderInitialDataFormFields;
