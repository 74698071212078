import {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useAuth} from "base/user/provider";
import {HolderService} from "holder/service";

import {ViewOrUpdateHolderContent} from ".";
import {EntityViewSubPage} from "base/entity/components/container";
import {EntityAuditSection} from "base/entity/components/presentational/sections";
import {
    DeleteEntityButton,
    DeleteEntityDialog,
    DeleteItemDialog,
} from "base/delete/components";

const ViewHolderSummarySubPage = () => {
    const context = useOutletContext();
    const holder = context[0];
    const auth = useAuth();
    const navigate = useNavigate();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletionError, setDeletionError] = useState(null);
    const [canBeDeletedMsg, setCanBeDeletedMsg] = useState("");

    const allowDelete = auth.hasPermission("delete-holders");

    useEffect(() => {
        setDeletionError(null);
    }, []);

    const handleCanBeDeleted = () => {
        HolderService.canBeDeleted(holder.id)
            .then(result => {
                if (result[0]) {
                    handleDelete();
                } else {
                    setCanBeDeletedMsg(result[1]);
                    setDeletionError(result[1]);
                }
            })
            .catch(error => {
                setCanBeDeletedMsg(error);
                setDeletionError(error);
            });
    };
    const handleDelete = () => {
        HolderService.delete(holder.id)
            .then(() => {
                navigate("/holders/all/list");
            })
            .catch(error => {
                setCanBeDeletedMsg(error);
                setDeletionError(error);
            });
    };

    const sectionsDetails = [
        {name: "generaldata", label: "Informações gerais"},
        {name: "personaldocs", label: "Documentação"},
        {name: "comments", label: "Observações"},
    ];

    const sections = [
        ...sectionsDetails.map(section => (
            <ViewOrUpdateHolderContent
                key={section.name}
                section={section.name}
                label={section.label}
                holder={holder}
            />
        )),
        <EntityAuditSection entity={holder} key="audit" />,
    ];

    return (
        holder && (
            <EntityViewSubPage sections={sections}>
                {allowDelete ? (
                    <>
                        <DeleteEntityButton
                            openDialog={() => setIsDeleteDialogOpen(true)}
                            entityName={"titular"}
                        />
                        <DeleteItemDialog
                            isDialogOpen={isDeleteDialogOpen}
                            setIsDialogOpen={setIsDeleteDialogOpen}
                            deletionError={""}
                            onDelete={handleCanBeDeleted}
                        />
                        <DeleteEntityDialog
                            msg={canBeDeletedMsg}
                            setMsg={setCanBeDeletedMsg}
                            deletionError={deletionError}
                            onDelete={handleDelete}
                        />
                    </>
                ) : null}
            </EntityViewSubPage>
        )
    );
};

export default ViewHolderSummarySubPage;
