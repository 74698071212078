import {useFormContext, useWatch} from "react-hook-form";
import {useDomain} from "snimf/domain/provider";
import {FormSelect} from "base/form/components";
import Grid from "@mui/material/Grid";

const PlotLandFormCombo = () => {
    const {
        landOwnershipDomain,
        landUsePrivateDomain,
        landUseStateDomain,
        stateConcessionDomain,
    } = useDomain();

    const {reset, getValues} = useFormContext();

    const landOwnership = useWatch({
        name: "land_ownership",
    });
    const landUse = useWatch({
        name: "land_use",
    });

    const isLandPrivate = landOwnership === "Privado";
    const isStateConcession = landUse === "Concessão estatal";

    const onChangeLandOwnership = () => {
        const values = getValues();
        reset({
            ...values,
            land_use: "",
            state_concession: "",
        });
    };

    const onChangeLandUse = () => {
        const values = getValues();
        reset({
            ...values,
            state_concession: "",
        });
    };

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormSelect
                    name="land_ownership"
                    label="Propriedade da terra"
                    options={landOwnershipDomain}
                    onChangeHandler={onChangeLandOwnership}
                    rules={{required: "Este campo é obrigatório."}}
                />
                {isStateConcession ? (
                    <FormSelect
                        name="state_concession"
                        label="Concessão estatal"
                        options={stateConcessionDomain}
                    />
                ) : null}
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="land_use"
                    label="Uso do solo"
                    options={isLandPrivate ? landUsePrivateDomain : landUseStateDomain}
                    onChangeHandler={onChangeLandUse}
                />
            </Grid>
        </Grid>
    );
};

export default PlotLandFormCombo;
