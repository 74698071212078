import {theme} from "Theme";
import {NumberUtil} from "base/format/utilities";
import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {FieldUtil} from "base/ui/section/utilities";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const TotalField = ({label, total, bold = false}) => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            my={2}
        >
            <Typography
                variant="subtitle1"
                textTransform="uppercase"
                color="primary.dark"
                fontSize={theme.typography.fontSize}
                fontWeight={bold ? 500 : "inherit"}
            >
                {label}:
            </Typography>
            <Typography variant="subtitle2" color="text.primary">
                {total || 0}
            </Typography>
        </Stack>
    );
};

const PermitApplicationBillingFields = ({permit}) => {
    const billingFields = [
        {
            type: "felling",
            label: "Valor pago de abate",
            price: permit?.felling_price,
        },
        {
            type: "cutting",
            label: "Valor pago de serragem",
            price: permit?.cutting_price,
        },
        {
            type: "clearing",
            label: "Valor pago de desbravamento",
            price: permit?.clearing_price,
        },
        {
            type: "transport",
            label: "Valor pago de transporte",
            price: permit?.transport_price,
        },
    ];

    const renderField = (label, price) =>
        FieldUtil.getSectionField(
            label,
            NumberUtil.formatCurrency(price, false),
            CURRENCY_SYMBOL,
            null,
            null,
            true
        );

    return (
        <Grid item xs={6}>
            {billingFields.map(
                ({type, label, price}) =>
                    permit?.types?.includes(type) && renderField(label, price)
            )}

            {renderField("Combustível para deslocação", permit?.fuel_fee)}
            {renderField("Impresão documentos", permit?.printing_fee)}
            {renderField("Plantas", permit?.restock_fee)}

            <Divider />

            <TotalField
                label="Valor de pagamento total"
                total={NumberUtil.formatCurrency(permit?.total_due)}
            />
        </Grid>
    );
};

export default PermitApplicationBillingFields;
