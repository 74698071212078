import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {PlotService} from "plot/service";
import {plot_view_adapter} from "plot/model";
import {PlotForm, PlotInitialDataFormFields} from "plot/presentational/form";
import {FormSection} from "base/form/components";

const CreatePlotForm = ({onCancel = null}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];

    const [error, setError] = useState("");

    const handleFormSubmit = plot => {
        PlotService.create(plot_view_adapter({...plot}))
            .then(createdPlot => {
                navigate(`${basePath}/${createdPlot.id}/summary`);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleFormCancel = () => {
        onCancel ? onCancel() : navigate(`${basePath}/list`);
    };

    return (
        <PlotForm onSubmit={handleFormSubmit} onCancel={handleFormCancel} error={error}>
            <FormSection>
                <PlotInitialDataFormFields />
            </FormSection>
        </PlotForm>
    );
};

export default CreatePlotForm;
