const StepperUtil = {
    getUncompleteFields(requiredFields) {
        return requiredFields.filter(field => !field.value);
    },

    isStepUntouched(requiredFields) {
        return requiredFields.every(field => !field.value);
    },
};

export default StepperUtil;
