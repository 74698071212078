import {useCallback, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";

import {DateUtil} from "base/format/utilities";
import {useList} from "base/entity/provider";
import {useDomain} from "snimf/domain/provider";
import {useAdministrativeDivisions} from "snimf/location/provider";

import {
    FormClearButtonSmall,
    FormDatePicker,
    FormSelect,
    FormSelectMultiple,
    FormSwitchInput,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const ApprovedPermitFilterForm = ({onClear = null}) => {
    const {filter, changeFilter, resetFilter, setPage} = useList();
    const {permitTypeDomain} = useDomain();

    const {districts} = useAdministrativeDivisions();

    useEffect(() => {
        if (!Object.keys(filter).length) {
            handleClearAllFilters();
        }
    }, [filter]);

    useEffect(() => {
        const attributeValue = {district: ""};
        let updatedFilter = {...filter, ...attributeValue};
        changeFilter(updatedFilter);
    }, [districts]);

    // TO-DO: Use if only active permits are to be displayed by default
    // useEffect(() => {
    //     if (!filter || filter.is_valid === undefined) setFilter({is_valid: true});
    // }, []);

    const formMethods = useForm({
        defaultValues: {
            is_valid: filter?.is_valid || false,
            on_date_min: filter?.on_date_min || null,
            on_date_max: filter?.on_date_max || null,
            district: filter?.district || "",
            types: filter?.types || [],
        },
    });

    const handleChange = useCallback(
        attributeValue => {
            setPage(1);

            if (
                isInvalidDate(attributeValue, "on_date_min") ||
                isInvalidDate(attributeValue, "on_date_max")
            ) {
                return;
            }

            let updatedFilter = {...filter, ...attributeValue};

            if (attributeValue?.is_valid === false) {
                updatedFilter = {...updatedFilter, is_valid: null};
            }
            changeFilter(updatedFilter);
        },
        [filter]
    );

    const handleClearAllFilters = () => {
        formMethods.reset({
            is_valid: false,
            on_date_min: null,
            on_date_max: null,
            district: "",
            types: [],
        });
        if (onClear) {
            onClear();
        }
        if (Object.entries(filter).length > 0) resetFilter();
    };

    const isInvalidDate = (attributeValue, key) =>
        key in attributeValue && DateUtil.isInvalidDateObject(attributeValue[key]);

    return (
        <FormProvider {...formMethods}>
            <Grid container columnSpacing={1} alignItems="center" mt={1}>
                <Grid item xs={4} md={1.5}>
                    <FormSwitchInput
                        name="is_valid"
                        label="Em vigor"
                        onChangeHandler={option => {
                            handleChange({
                                is_valid: option ? option.target.checked : null,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <FormDatePicker
                        name="on_date_min"
                        label="Autorização após"
                        onChangeHandler={date => handleChange({on_date_min: date})}
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <FormDatePicker
                        name="on_date_max"
                        label="Autorização anterior a"
                        onChangeHandler={date => handleChange({on_date_max: date})}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <FormSelect
                        name="district"
                        label="Distrito"
                        options={districts}
                        onChangeHandler={option =>
                            handleChange({
                                district: option,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormSelectMultiple
                        name="types"
                        label="Tipo guia"
                        options={permitTypeDomain}
                        onChangeHandler={options => {
                            handleChange({types: options});
                        }}
                    />
                </Grid>

                <Grid item xs={0.5}>
                    <FormClearButtonSmall handleClear={handleClearAllFilters} />
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default ApprovedPermitFilterForm;
