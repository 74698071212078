import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {useTreeFormFields} from "tree/hooks";
import {FormInputDecimal} from "base/form/components";
import {AccordionLayout} from "base/shared/components";
import {TreeDataFieldsSummarized} from "..";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

const TreeDataFeesFormFields = ({tree, permit}) => {
    const {felling, cutting} = useTreeFormFields();

    const fellingFields = (
        <>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="felling_fee"
                    label="Taxa de abate"
                    endAdornment={`${CURRENCY_SYMBOL}/m³`}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="felling_price"
                    label="Valor de abate"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled
                />
            </Grid>
        </>
    );

    const cuttingFields = (
        <>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="cutting_fee"
                    label="Taxa de serragem"
                    endAdornment={`${CURRENCY_SYMBOL}/toro`}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="cutting_price"
                    label="Valor de serragem"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled
                />
            </Grid>
        </>
    );

    const TreeDataLabel = () => {
        return (
            <Stack direction="row" spacing={3}>
                <Chip
                    label={
                        <Typography component="span" fontWeight={600} fontSize={12}>
                            {tree.quality}
                        </Typography>
                    }
                    variant="outlined"
                    color="primary"
                />
                <Typography
                    variant="overline"
                    color="primary.main"
                    textTransform="none"
                >
                    Propriedade da parcela:{" "}
                    <Typography variant="overline" fontWeight={600}>
                        {permit?.plot?.land_ownership}
                    </Typography>
                </Typography>
            </Stack>
        );
    };

    return (
        <>
            <AccordionLayout accordionTitle={<TreeDataLabel />}>
                <TreeDataFieldsSummarized tree={tree} />
            </AccordionLayout>
            {felling ? fellingFields : null}
            {cutting ? cuttingFields : null}
        </>
    );
};

export default TreeDataFeesFormFields;
