import {ViewOrUpdateTreeDataContent} from ".";
import Stack from "@mui/system/Stack";

const ViewTreesContent = ({permit, trees, displayFees, disabled}) => {
    return trees.length ? (
        <Stack spacing={1}>
            {trees.map(tree => (
                <div key={tree.code} id={`${tree.code}`}>
                    <ViewOrUpdateTreeDataContent
                        permit={permit}
                        tree={tree}
                        displayFees={displayFees}
                        disabled={disabled}
                    />
                </div>
            ))}
        </Stack>
    ) : null;
};

export default ViewTreesContent;
