import {FormInputText} from "base/form/components";
import Grid from "@mui/material/Grid";

const ViolationTeamFormFields = () => {
    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormInputText name="inspection_team" label="Equipa técnica DFB" />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="driver" label="Motorista" />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="officers" label="Agentes" />
            </Grid>
        </Grid>
    );
};

export default ViolationTeamFormFields;
