import {DialogLayout} from "base/dialog/components";

const DeleteItemDialog = ({
    isDialogOpen,
    setIsDialogOpen,
    deletionError = null,
    onDelete,
}) => {
    const handleDialog = isOpen => {
        setIsDialogOpen(false);
    };

    const handleConfirmDeletion = () => {
        setIsDialogOpen(false);
        onDelete();
    };

    return (
        <DialogLayout
            dialogTitle={`Deseja eliminar este elemento definitivamente?`}
            dialogContentText={`Se clicar em Remover, o elemento não poderá ser recuperado.`}
            onMainActionClick={handleConfirmDeletion}
            mainActionText="Remover"
            mainActionColor="error"
            onHandleDialog={handleDialog}
            isDialogOpen={isDialogOpen}
            error={deletionError}
        />
    );
};

export default DeleteItemDialog;
