import {useState} from "react";
import {useOutletContext} from "react-router-dom";

import {useNavigateWithReload} from "base/navigation/hooks";
import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";

import {PermitForm} from "./form";
import {FormCheckbox, FormContainer} from "base/form/components";
import Stack from "@mui/material/Stack";

const PermitApplicationSignatureStep = () => {
    const {permit, isStepEditable} = useOutletContext();
    const [error, setError] = useState(null);

    const navigate = useNavigateWithReload();

    const handleFormSubmit = updatedPermit => {
        PermitApplicationService.update(permit_view_adapter(updatedPermit))
            .then(updatedPermit => {
                navigate("", true);
            })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    return permit ? (
        <PermitForm permit={permit} onSubmit={handleFormSubmit} error={error}>
            <Stack spacing={1}>
                <FormContainer style={{alignItems: "center"}}>
                    <FormCheckbox
                        name="application_sent"
                        label="A solicitação foi enviada para a Direcção"
                        disabled={!isStepEditable}
                    />
                </FormContainer>
                <FormContainer style={{alignItems: "center"}}>
                    <FormCheckbox
                        name="application_signed"
                        label="A solicitação foi assinada pela Direcção"
                        disabled={!isStepEditable}
                    />
                </FormContainer>
            </Stack>
        </PermitForm>
    ) : null;
};

export default PermitApplicationSignatureStep;
