import {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";

import {useEntityPermissions} from "snimf/permissions/hooks";
import {ViolationService} from "violation/service";

import {ViewOrUpdateViolationContent} from ".";
import {EntityViewSubPage} from "base/entity/components/container";
import {EntityAuditSection} from "base/entity/components/presentational/sections";
import {DeleteEntityButton, DeleteItemDialog} from "base/delete/components";
import Alert from "@mui/material/Alert";

const ViewViolationSummarySubPage = () => {
    const context = useOutletContext();
    const violation = context[0];

    const navigate = useNavigate();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletionError, setDeletionError] = useState(null);

    const {allowEdit, allowDelete} = useEntityPermissions(violation, "violations");

    useEffect(() => {
        setDeletionError(null);
    }, []);

    const handleDelete = () => {
        ViolationService.delete(violation.id)
            .then(() => {
                navigate("/violations/all/list");
            })
            .catch(error => {
                console.log(error);
                setDeletionError(error);
            });
    };

    const sectionsDetails = [
        {name: "generaldata", label: "Informações gerais"},
        {name: "team", label: "Equipa"},
        {name: "comments", label: "Observações"},
    ];

    //TO-DO: Handle warnings
    const getDistrictWarning = () => {
        if (violation?.plot && violation?.plot?.district !== violation?.district)
            return "O distrito da infracção não é o mesmo que o distrito de parcela.";
        return "";
    };

    const warningMessage = getDistrictWarning();

    const sections = [
        ...(warningMessage
            ? [
                  <Alert key="alert" severity="warning">
                      {warningMessage}
                  </Alert>,
              ]
            : []),
        ...sectionsDetails.map(section => (
            <ViewOrUpdateViolationContent
                key={section.name}
                section={section.name}
                label={section.label}
                violation={violation}
                allowEdit={allowEdit}
            />
        )),
        <EntityAuditSection entity={violation} key="audit" />,
    ];

    return (
        violation && (
            <EntityViewSubPage sections={sections}>
                {allowDelete ? (
                    <>
                        <DeleteEntityButton
                            openDialog={() => setIsDeleteDialogOpen(true)}
                            entityName={"infracção"}
                        />
                        <DeleteItemDialog
                            isDialogOpen={isDeleteDialogOpen}
                            setIsDialogOpen={setIsDeleteDialogOpen}
                            deletionError={deletionError}
                            onDelete={handleDelete}
                        />
                    </>
                ) : null}
            </EntityViewSubPage>
        )
    );
};

export default ViewViolationSummarySubPage;
