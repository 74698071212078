class Plants extends Array {}

const plant_api_adapter = plant => {
    return plant;
};

const plants_api_adapter = plants => {
    return plants.map(plant_api_adapter);
};

const plant_view_adapter = plant => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside contact object

    delete plant["folder"];
    delete plant["featured_image"];
    delete plant["created_by"];
    delete plant["updated_by"];
    delete plant["created_at"];
    delete plant["updated_at"];

    return plant;
};

const createPlants = (data = []) => {
    const plants = Plants.from(data, plant => createPlant(plant));
    return plants;
};

const createPlant = ({
    id = -1,
    code = null,
    species = "",
    scientific_name = "",
    quantity = null,
    source = "",
    sowing_date = null,
    germination_date = null,
    transplant_date = null,
    plantation = null,
    folder = "",
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        code,
        species,
        scientific_name,
        quantity,
        source,
        sowing_date,
        germination_date,
        transplant_date,
        plantation,
        folder,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createPlant as default,
    createPlants,
    plant_api_adapter,
    plants_api_adapter,
    plant_view_adapter,
};
