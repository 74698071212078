import {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useEntityPermissions} from "snimf/permissions/hooks";
import {PlotService} from "plot/service";

import {ViewOrUpdatePlotContent} from ".";
import {EntityViewSubPage} from "base/entity/components/container";
import {EntityAuditSection} from "base/entity/components/presentational/sections";
import {
    DeleteEntityButton,
    DeleteEntityDialog,
    DeleteItemDialog,
} from "base/delete/components";

const ViewPlotSummarySubPage = () => {
    const context = useOutletContext();
    const plot = context[0];
    const navigate = useNavigate();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletionError, setDeletionError] = useState(null);
    const [canBeDeletedMsg, setCanBeDeletedMsg] = useState("");

    const {allowEdit, allowDelete} = useEntityPermissions(plot, "plots");

    useEffect(() => {
        setDeletionError(null);
    }, []);

    const handleCanBeDeleted = () => {
        PlotService.canBeDeleted(plot.id)
            .then(result => {
                if (result[0]) {
                    handleDelete();
                } else {
                    setCanBeDeletedMsg(result[1]);
                    setDeletionError(result[1]);
                }
            })
            .catch(error => {
                setCanBeDeletedMsg(error);
                setDeletionError(error);
            });
    };

    const handleDelete = () => {
        PlotService.delete(plot.id)
            .then(() => {
                navigate("/plots/all/list");
            })
            .catch(error => {
                setCanBeDeletedMsg(error);
                setDeletionError(error);
            });
    };

    const sectionsDetails = [
        {name: "generaldata", label: "Informações gerais"},
        {name: "ownership", label: "Propriedade"},
        {name: "comments", label: "Observações"},
    ];

    const sections = [
        ...sectionsDetails.map(section => (
            <ViewOrUpdatePlotContent
                key={section.name}
                section={section.name}
                label={section.label}
                plot={plot}
                allowEdit={allowEdit}
            />
        )),
        <EntityAuditSection entity={plot} key="audit" />,
    ];

    return (
        plot && (
            <EntityViewSubPage sections={sections}>
                {allowDelete ? (
                    <>
                        <DeleteEntityButton
                            openDialog={() => setIsDeleteDialogOpen(true)}
                            entityName={"parcela"}
                        />
                        <DeleteItemDialog
                            isDialogOpen={isDeleteDialogOpen}
                            setIsDialogOpen={setIsDeleteDialogOpen}
                            deletionError={""}
                            onDelete={handleCanBeDeleted}
                        />
                        <DeleteEntityDialog
                            msg={canBeDeletedMsg}
                            setMsg={setCanBeDeletedMsg}
                            deletionError={deletionError}
                            onDelete={handleDelete}
                        />
                    </>
                ) : null}
            </EntityViewSubPage>
        )
    );
};

export default ViewPlotSummarySubPage;
