import {useAuth} from "base/user/provider";
import {useCreateEntity} from "base/entity/hooks";
import {usePlotsTable} from "plot/data";
import {PlotService} from "plot/service";

import {PlotFilterForm} from "plot/presentational/form";
import {CreatePlotForm} from "plot/container";
import {EntityListPage} from "base/entity/components/container";
import {PlotsMapProvider} from "plot/map";

const ListPlotsPage = () => {
    const {tableColumns, getCellProps} = usePlotsTable();
    const auth = useAuth();

    let {
        button: createButton,
        dialog: createDialog,
        handleClickClose: closeDialog,
    } = useCreateEntity(
        null,
        "Nova parcela",
        <CreatePlotForm onCancel={() => closeDialog()} />
    );

    if (!auth.hasPermission("create-plots")) {
        createDialog = null;
        createButton = null;
    }

    return (
        <>
            {createDialog}
            <PlotsMapProvider>
                <EntityListPage
                    views={["table", "map"]}
                    entityName={{
                        singular: "Parcela",
                        plural: "Parcelas",
                    }}
                    service={PlotService}
                    tableColumns={tableColumns}
                    getCellProps={getCellProps}
                    filterForm={<PlotFilterForm />}
                    createButton={createButton}
                    disableNavigation
                />
            </PlotsMapProvider>
        </>
    );
};
export default ListPlotsPage;
