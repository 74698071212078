import {createContacts} from "contact/model";
import {createHolder, holder_api_adapter} from "holder/model";
import {createPlot, plot_api_adapter} from "plot/model";

class Permits extends Array {}

const permit_api_adapter = permit => {
    if (permit.holder)
        permit["holder"] = createHolder(holder_api_adapter(permit.holder));

    if (permit["plot"]) permit["plot"] = createPlot(plot_api_adapter(permit["plot"]));

    if (permit["contact_set"]) createContacts(permit["contact_set"]);

    return permit;
};

const permits_api_adapter = permits => {
    return permits.map(permit_api_adapter);
};

const permit_view_adapter = permit => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside permit object
    if (permit["holder"]) permit["holder_id"] = permit["holder"].id;
    if (permit["plot"]) permit["plot_id"] = permit["plot"].id;

    if (!permit["types"]) permit["types"] = [];
    permit["application_doc_valid"] = !!permit["application_doc_valid"];
    permit["personal_id_doc_valid"] = !!permit["personal_id_doc_valid"];
    permit["plot_ownership_doc_valid"] = !!permit["plot_ownership_doc_valid"];
    permit["plot_use_authorization_valid"] = !!permit["plot_use_authorization_valid"];
    permit["tax_id_doc_valid"] = !!permit["tax_id_doc_valid"];
    permit["is_the_plot_owner"] = !!permit["is_the_plot_owner"];
    permit["invoice_sent"] = !!permit["invoice_sent"];
    permit["application_sent"] = !!permit["application_sent"];
    permit["application_signed"] = !!permit["application_signed"];

    delete permit["holder"];
    delete permit["plot"];
    delete permit["contact_set"];
    delete permit["tree_set"];
    delete permit["material_set"];
    delete permit["types_label"];
    delete permit["is_valid"];
    delete permit["folder"];
    delete permit["created_by"];
    delete permit["updated_by"];
    delete permit["created_at"];
    delete permit["updated_at"];

    return permit;
};

const createPermits = (data = []) => {
    const permits = Permits.from(data, permit => createPermit(permit));
    return permits;
};

const createPermit = ({
    id = -1,
    is_valid = null,
    code = "",
    on_date = null,
    application_date = null,
    status = "",
    district = "",
    types = null,
    types_label = "",
    comments = null,
    inspection_date = null,
    inspection_team = null,
    felling_start_date = null,
    felling_end_date = null,
    felling_beneficiary = null,
    felling_price = null,
    cutting_start_date = null,
    cutting_end_date = null,
    cutting_beneficiary = null,
    cutting_price = null,
    clearing_start_date = null,
    clearing_end_date = null,
    clearing_beneficiary = null,
    clearing_area = null,
    clearing_fee = null,
    clearing_price = null,
    transport_start_date = null,
    transport_end_date = null,
    transport_beneficiary = null,
    transport_destination = null,
    transport_price = null,
    fuel_fee = null,
    printing_fee = null,
    restock_fee = null,
    total_due = null,
    application_doc = null,
    personal_id_doc = null,
    plot_ownership_doc = null,
    plot_use_authorization = null,
    tax_id_doc = null,
    technical_report = null,
    payment_receipt = null,
    application_doc_valid = null,
    personal_id_doc_valid = null,
    plot_ownership_doc_valid = null,
    plot_use_authorization_valid = null,
    tax_id_doc_valid = null,
    technical_decision = null,
    is_the_plot_owner = null,
    invoice_sent = null,
    application_sent = null,
    application_signed = null,
    printed_permit = null,
    printed_invoice = null,
    holder = null,
    plot = null,
    contact_set = [],
    tree_set = [],
    material_set = [],
    folder = "",
    featured_document = null,
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        is_valid,
        code,
        on_date,
        application_date,
        status,
        district,
        types,
        types_label,
        comments,
        inspection_date,
        inspection_team,
        felling_start_date,
        felling_end_date,
        felling_beneficiary,
        felling_price,
        cutting_start_date,
        cutting_end_date,
        cutting_beneficiary,
        cutting_price,
        clearing_start_date,
        clearing_end_date,
        clearing_beneficiary,
        clearing_area,
        clearing_fee,
        clearing_price,
        transport_start_date,
        transport_end_date,
        transport_beneficiary,
        transport_destination,
        transport_price,
        fuel_fee,
        printing_fee,
        restock_fee,
        total_due,
        application_doc,
        personal_id_doc,
        plot_ownership_doc,
        plot_use_authorization,
        tax_id_doc,
        technical_report,
        payment_receipt,
        application_doc_valid,
        personal_id_doc_valid,
        plot_ownership_doc_valid,
        plot_use_authorization_valid,
        tax_id_doc_valid,
        technical_decision,
        is_the_plot_owner,
        invoice_sent,
        application_sent,
        application_signed,
        printed_permit,
        printed_invoice,
        holder,
        plot,
        contact_set,
        tree_set,
        material_set,
        folder,
        featured_document,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createPermit as default,
    createPermits,
    permit_api_adapter,
    permits_api_adapter,
    permit_view_adapter,
};
