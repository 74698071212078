import {useAuth} from "base/user/provider";
import {useCreateEntity} from "base/entity/hooks";
import {useHoldersTable} from "holder/data";
import {HolderService} from "holder/service";

import {HolderFilterForm} from "holder/presentational/form";
import {CreateHolderForm} from ".";
import {EntityListPage} from "base/entity/components/container";

const ListHoldersPage = () => {
    const {tableColumns, getCellProps} = useHoldersTable();
    const auth = useAuth();

    let {
        button: createButton,
        dialog: createDialog,
        handleClickClose: closeDialog,
    } = useCreateEntity(
        null,
        "Novo/a titular",
        <CreateHolderForm onCancel={() => closeDialog()} />
    );

    if (!auth.hasPermission("create-holders")) {
        createDialog = null;
        createButton = null;
    }

    return (
        <>
            {createDialog}
            <EntityListPage
                views={["table"]}
                entityName={{
                    singular: "Titular",
                    plural: "Titulares",
                }}
                service={HolderService}
                tableColumns={tableColumns}
                getCellProps={getCellProps}
                filterForm={<HolderFilterForm />}
                createButton={createButton}
                disableNavigation
            />
        </>
    );
};
export default ListHoldersPage;
