import {DateUtil} from "base/format/utilities";
import {TextLink} from "base/navigation/components";

export function useViolationsSummaryTable() {
    const tableColumns = [
        {
            id: "code",
            label: "Código",
            width: 20,
            formatFunction: item => (
                <TextLink
                    text={item?.code}
                    to={`/violations/all/list/${item.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
        },
        {
            id: "on_date",
            label: "Data",
            width: 20,
            formatFunction: item => DateUtil.formatDate(item.on_date),
        },
        {
            id: "district",
            label: "Distrito",
            width: 20,
        },
        {
            id: "status",
            label: "Estado",
            width: 20,
        },
        {
            id: "tipo",
            label: "Tipo",
            width: 20,
        },
    ];

    return {tableColumns};
}
