import {useLocation} from "react-router-dom";

import {useAuth} from "base/user/provider";
import {PageMenu, PageMenuListItemButton} from "base/ui/menu";

import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";

const PermitPageMenu = () => {
    const {ROLES} = useAuth();
    const location = useLocation();
    const currentUrl = location.pathname;
    const entityUrlSlug = "permits";
    const basePath = currentUrl.split(`/${entityUrlSlug}/`)[0];

    return (
        <PageMenu collapsed={true}>
            <PageMenuListItemButton
                key="permits-applications"
                to={`${basePath}/${entityUrlSlug}/applications/list`}
                text="Em processo"
                icon={<PendingActionsIcon />}
            />
            <PageMenuListItemButton
                key="permits-approved"
                to={`${basePath}/${entityUrlSlug}/approved/list`}
                text="Aprovadas"
                icon={<AssignmentTurnedInOutlinedIcon />}
            />
        </PageMenu>
    );
};

export default PermitPageMenu;
