import {theme} from "Theme";
import {VIOLATION_TYPES_COLORS} from "violation/hooks";

import {GenericChip} from "base/shared/components";
import Stack from "@mui/material/Stack";

const ViolationActionsChips = ({actions}) => {
    const purpleStyle = {
        border: `2px solid ${VIOLATION_TYPES_COLORS[0].color}`,
        backgroundColor: VIOLATION_TYPES_COLORS[9].color,
        color: VIOLATION_TYPES_COLORS[0].color,
        fontWeight: "500",
    };

    const greyStyle = {
        border: `2px solid ${theme.palette.grey[500]}`,
        backgroundColor: "none",
        color: theme.palette.text,
        fontWeight: "500",
    };

    return (
        <Stack direction="row" flexWrap="wrap" spacing={1}>
            {actions?.split(",").map((action, index) => (
                <GenericChip key={index} label={action} style={greyStyle} />
            ))}
        </Stack>
    );
};

export default ViolationActionsChips;
