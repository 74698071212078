import {createHolder, holder_api_adapter} from "holder/model";

class Plots extends Array {}

const plot_api_adapter = plot => {
    if (plot.holder) plot["holder"] = createHolder(holder_api_adapter(plot.holder));

    return plot;
};

const plots_api_adapter = plots => {
    return plots.map(plot_api_adapter);
};

const plot_view_adapter = plot => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside plot object
    if (plot["holder"]) plot["holder_id"] = plot["holder"].id;
    plot["is_area_protected"] = !!plot["is_area_protected"];

    delete plot["holder"];
    delete plot["folder"];
    delete plot["featured_image"];
    delete plot["created_by"];
    delete plot["updated_by"];
    delete plot["created_at"];
    delete plot["updated_at"];

    return plot;
};

const createPlots = (data = []) => {
    const plots = Plots.from(data, plot => createPlot(plot));
    return plots;
};

const createPlot = ({
    id = -1,
    number = "",
    code = null,
    land_ownership = "",
    land_use = "",
    state_concession = "",
    land_size = "",
    land_cover = [],
    is_area_protected = null,
    official_area = null,
    geom_area = null,
    island = "",
    district = "",
    sub_district = "",
    locality = "",
    comments = "",
    geom = null,
    holder = null,
    holder_id = null,
    folder = "",
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        number,
        code,
        land_ownership,
        land_use,
        state_concession,
        land_size,
        land_cover,
        is_area_protected,
        official_area,
        geom_area,
        island,
        district,
        sub_district,
        locality,
        comments,
        geom,
        holder,
        holder_id,
        folder,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createPlot as default,
    createPlots,
    plot_api_adapter,
    plots_api_adapter,
    plot_view_adapter,
};
