import {DateUtil} from "base/format/utilities";
import {TextLink} from "base/navigation/components";

export function usePlantationsTable() {
    const tableColumns = [
        {
            id: "code",
            label: "Código",
            formatFunction: item => (
                <TextLink
                    text={item?.code}
                    to={`/plantations/all/list/${item.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
            width: 10,
        },
        {
            id: "on_date",
            label: "Colocação do visto",
            formatFunction: item => DateUtil.formatDate(item?.on_date),
            width: 10,
        },
        {
            id: "holder",
            label: "Requerente",
            formatFunction: item => (
                <TextLink
                    text={item?.holder?.name}
                    to={`/holders/all/list/${item?.holder?.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
            width: 20,
        },
        {
            id: "district",
            label: "Distrito",
            width: 10,
        },
        {
            id: "plot__land_ownership",
            label: "Propriedade da terra",
            formatFunction: item => {
                return item?.plot?.land_ownership;
            },
            width: 15,
        },
    ];

    return {tableColumns};
}
