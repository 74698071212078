import {FieldUtil} from "base/ui/section/utilities";

import Grid from "@mui/material/Grid";

const HolderBasicDataSection = ({holder}) => {
    const isBusiness = holder?.type === "Empresa";
    const genderLabel = isBusiness ? "Gênero do representante" : "Gênero";

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Nome",
                    holder?.name,
                    null,
                    null,
                    `/holders/all/list/${holder?.id}/summary`
                )}
                {FieldUtil.getSectionField("Tipo de documento", holder?.doc_type)}
                {FieldUtil.getSectionField(genderLabel, holder?.gender)}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Tipo", holder?.type)}
                {FieldUtil.getSectionField("Número de documento", holder?.doc_number)}
                {FieldUtil.getSectionField("Morada", holder?.address)}
            </Grid>
        </Grid>
    );
};

export default HolderBasicDataSection;
