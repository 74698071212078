import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const SectionValueBox = ({value = null, size = null}) => {
    const emptyField = (
        <Typography component="span" sx={{fontSize: 14, fontStyle: "italic"}}>
            —
        </Typography>
    );

    return (
        <Grid
            item
            xs={size}
            sx={{
                width: "100%",
                border: 1,
                borderRadius: 1,
                borderStyle: "solid",
                borderColor: "grey.300",
                backgroundColor: "grey.100",
                p: 1,
                fontSize: 14,
            }}
        >
            {value || emptyField}
        </Grid>
    );
};

export default SectionValueBox;
