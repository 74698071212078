import {MapBaseLayersProvider} from "base/map/layer";
import {createBasePlotLayerConfig, useSnimfMapConfig} from "snimf/map";
import {createPlotLayerConfig, createSinglePlotLayerConfig} from "plot/map";
import {MapProvider} from "base/map";
import {creatPermitTreeLayerConfig} from "tree/map";

const PlotTreesMapProvider = ({children, permit}) => {
    // map config
    const {crs, crsType, tocOptions, mapOptions, baseLayers} = useSnimfMapConfig();

    const singlePlotLayerConfig = createSinglePlotLayerConfig({plot: permit.plot});

    const treeLayerConfig = creatPermitTreeLayerConfig(permit);

    const plotLayerConfig = createPlotLayerConfig({
        label: "Outras parcelas",
        excludedPlotId: permit.plot?.id,
    });

    const basePlotLayerConfig = createBasePlotLayerConfig();

    // end map config

    return (
        <MapProvider
            crs={crs}
            crsType={crsType}
            baseLayers={baseLayers}
            tocOptions={tocOptions}
            mapOptions={mapOptions}
        >
            <MapBaseLayersProvider
                layers={[singlePlotLayerConfig, treeLayerConfig]}
                auxLayers={[plotLayerConfig, basePlotLayerConfig]}
            >
                {children}
            </MapBaseLayersProvider>
        </MapProvider>
    );
};
export default PlotTreesMapProvider;
