import {useAuth} from "base/user/provider";
import {useDistrictIsland} from "snimf/location/hooks";

export const useEntityPermissions = (entity, entityName) => {
    const auth = useAuth();
    const {getDistrictIsland} = useDistrictIsland();

    const island = entity ? getDistrictIsland(entity.district) : null;

    const allowEdit = entity
        ? auth.hasPermission(`edit-${entityName}`, {island})
        : false;

    const allowDelete = entity
        ? auth.hasPermission(`delete-${entityName}`, {island})
        : false;

    return {allowEdit, allowDelete};
};
