import {useMapLayerConfig} from "base/map/layer";
import {useWMSLayer} from "base/map/leaflet/layer";
import {createWMSLegend, createWMSLegendIcon} from "base/map/legend";

export const baseLayerUrl = process.env.REACT_APP_SNIMF_BASE_LAYER_URL;

export function createBasePlotLegend() {
    return createWMSLegend({
        code: "capa_base",
        label: "Camadas base",
        children: [
            createWMSLegend({
                code: "arboles",
                layerCodes: ["app_mothertree"],
                label: "Árvores matriz",
                icon: createWMSLegendIcon({
                    type: "image",
                    src: "/map/icons/tree.svg",
                    style: {
                        filter: "invert(10%) sepia(63%) saturate(18%) hue-rotate(115deg) brightness(90%) contrast(91%)",
                    },
                }),
            }),
            createWMSLegend({
                code: "parcelas_base",
                label: "Parcelas base",
                children: [
                    createWMSLegend({
                        code: "privado_gleba",
                        layerCodes: ["app_privadogleba"],
                        label: "Privado - Gleba",
                        icon: createWMSLegendIcon({
                            type: "square",
                            style: {
                                color: "#cc2629",
                                fillColor: "#cc26294d",
                            },
                        }),
                    }),
                    createWMSLegend({
                        code: "media_empresa",
                        layerCodes: ["app_mediaempresa"],
                        label: "Estatal - Media empresas",
                        icon: createWMSLegendIcon({
                            type: "square",
                            style: {
                                color: "#7022d5",
                                fillColor: "#7022d59e",
                            },
                        }),
                    }),
                    createWMSLegend({
                        code: "parcela_agricola",
                        layerCodes: ["app_parcelaagricola"],
                        label: "Estatal - Parcelas agrícolas",
                        icon: createWMSLegendIcon({
                            type: "square",
                            style: {
                                color: "#3bb443",
                                fillColor: "#3bb4434d",
                            },
                        }),
                    }),
                    createWMSLegend({
                        code: "modulo_pinheira",
                        layerCodes: ["app_modulopinheira"],
                        label: "Estatal - Modulos pinheira",
                        icon: createWMSLegendIcon({
                            type: "square",
                            style: {
                                color: "#13ff42",
                            },
                        }),
                    }),
                ],
            }),
            createWMSLegend({
                code: "landcover",
                layerCodes: ["app_landcover"],
                label: "Cobertura do solo",
                visible: false,
                showGraphic: true,
            }),
            createWMSLegend({
                code: "protected_area",
                layerCodes: ["app_protectedarea"],
                label: "Áreas protegidas",
                visible: false,
                showGraphic: true,
            }),
            createWMSLegend({
                code: "limites",
                layerCodes: ["app_district", "app_subdistrict"],
                label: "Limites administrativos",
            }),
            createWMSLegend({
                code: "hidrologia",
                label: "Hidrologia",
                visible: false,
                children: [
                    createWMSLegend({
                        code: "river",
                        layerCodes: ["app_river"],
                        label: "Rios",
                        icon: createWMSLegendIcon({
                            type: "line",
                            style: {
                                color: "#5bb8ff",
                                weight: 5,
                            },
                        }),
                    }),
                    createWMSLegend({
                        code: "basin",
                        layerCodes: ["app_basin"],
                        label: "Bacias",
                        icon: createWMSLegendIcon({
                            type: "polygon",
                            // TODO:egago Pending migration from CSS to SVG
                            style: {
                                borderColor: "#5bb8ff",
                                backgroundColor: "#5bb8ff4d",
                            },
                        }),
                        showGraphic: true,
                    }),
                ],
            }),
        ],
    });
}

export function createBasePlotLayer() {
    return useWMSLayer({
        format: "image/png",
        opacity: 1,
        pane: 2,
        otherLayerOptions: {
            tileSize: 1024,
            transparent: true,
            // crs: L.CRS.EPSG4326,
            minZoom: 8,
            tiled: true,
        },
    });
}

export function createBasePlotLayerConfig() {
    const baseLayerLoad = baseLayerUrl;

    const basePlotLayer = createBasePlotLayer();
    const basePlotLegend = createBasePlotLegend();
    return useMapLayerConfig({
        load: baseLayerLoad,
        layer: basePlotLayer,
        legend: basePlotLegend,
    });
}
