import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil} from "base/format/utilities";

import {PERMIT_APPLICATION_STATUS} from "permitapplication/data";
import {ViolationTypeTags} from ".";
import Grid from "@mui/material/Grid";

const ViolationGeneralDataSection = ({violation}) => {
    const getPermitPath = () => {
        if (!violation.permit) {
            return null;
        } else {
            const statusPath =
                violation.permit.status === PERMIT_APPLICATION_STATUS.approved
                    ? "approved"
                    : "applications";

            return `/permits/${statusPath}/list/${violation.permit?.id}/summary`;
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Código", violation.code)}
                {FieldUtil.getSectionField(
                    "Data",
                    DateUtil.formatDate(violation.on_date)
                )}
                {FieldUtil.getSectionField(
                    "Tipo",
                    <ViolationTypeTags types={violation.types} />
                )}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Distrito", violation.district)}
                {FieldUtil.getSectionField("Estado", violation.status)}
                {FieldUtil.getSectionField(
                    "Autorização",
                    violation.permit?.code,
                    null,
                    null,
                    getPermitPath()
                )}
            </Grid>
        </Grid>
    );
};

export default ViolationGeneralDataSection;
