import {SectionCard, SectionField} from "base/ui/section/components";
import Stack from "@mui/material/Stack";

const HolderSummary = ({holder}) => {
    return (
        <SectionCard>
            <Stack direction="row" spacing={2}>
                <SectionField label="Nome" value={holder?.name} />
                <SectionField label="Tipo" value={holder?.type} />
                <SectionField label="Nº documento" value={holder?.doc_number} />
            </Stack>
        </SectionCard>
    );
};

export default HolderSummary;
