import {ViewDocumentPanel} from "base/file/components";
import {Navigate, Route} from "react-router-dom";
import {
    ListViolationsPage,
    ViewViolationActionsSubPage,
    ViewViolationDocumentsSubPage,
    ViewViolationHolderSubPage,
    ViewViolationPage,
    ViewViolationPlotSubPage,
    ViewViolationSummarySubPage,
} from "violation/container";

const violationRoutes = [
    <Route key="violation-manage" path="all/list">
        <Route key="violations-list" path="" element={<ListViolationsPage />} />
        <Route key="violation-detail" path=":id" element={<ViewViolationPage />}>
            <Route
                key="violation-summary"
                path="summary"
                element={<ViewViolationSummarySubPage />}
            />
            <Route
                key="violation-holder"
                path="holder"
                element={<ViewViolationHolderSubPage />}
            />
            <Route
                key="violation-plot"
                path="plot"
                element={<ViewViolationPlotSubPage />}
            />
            <Route
                key="violation-actions"
                path="actions"
                element={<ViewViolationActionsSubPage />}
            />
            <Route
                key="violation-documents"
                path="documents/*"
                element={<ViewViolationDocumentsSubPage />}
            >
                <Route
                    key="violation-documents-view"
                    path="detail/*"
                    element={<ViewDocumentPanel />}
                />
            </Route>
            <Route index element={<Navigate to="summary" replace />} />
        </Route>
    </Route>,

    <Route
        key="redirect-violation-index"
        index
        element={<Navigate to="list" replace />}
    />,
];

export default violationRoutes;
