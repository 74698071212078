import {NumberUtil} from "base/format/utilities";
import {MaterialUtil} from "material/utils";

export function useTransportMaterialTable(displayFees) {
    const tableColumns = [
        {
            id: "species",
            label: "Espécie",
            width: 20,
        },
        {
            id: "quality",
            label: "Qualidade",
            width: 20,
        },
        {
            id: "type",
            label: "Tipo",
            width: 20,
        },
        {
            id: "quantity",
            label: "Quantidade",
            width: 10,
            textAlign: "right",
            formatFunction: item => {
                const units = MaterialUtil.getUnitsForQuantity();
                if (item.quantity)
                    return `${NumberUtil.formatInteger(item.quantity)} ${units}`;
            },
        },
        {
            id: "length",
            label: "Cumprimento das vigas",
            width: 15,
            textAlign: "right",
            formatFunction: item =>
                item.length ? `${NumberUtil.formatDecimal(item.length)} m` : null,
        },

        displayFees
            ? {
                  id: "price",
                  label: "Valor de transporte",
                  textAlign: "right",
                  formatFunction: item => NumberUtil.formatCurrency(item.price),
              }
            : null,
    ].filter(column => column !== null);

    return {tableColumns};
}
