import {FormCheckbox, FormContainer} from "base/form/components";

const PermitApplicationInvoiceFormFields = ({disabled = false}) => {
    return (
        <FormContainer style={{alignItems: "center"}}>
            <FormCheckbox
                name="invoice_sent"
                label="A factura foi enviada para o/a titular"
                disabled={disabled}
            />
        </FormContainer>
    );
};

export default PermitApplicationInvoiceFormFields;
