import {useState} from "react";
import {useOutletContext} from "react-router-dom";

import {useDownload} from "base/file/utilities";
import {useNavigateWithReload} from "base/navigation/hooks";
import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";

import {PermitApplicationInvoiceFormFields, PermitForm} from "./form";
import {DownloadPDFButton} from "base/pdf/presentational";
import Stack from "@mui/material/Stack";

const PermitApplicationInvoiceStep = () => {
    const download = useDownload();
    const navigate = useNavigateWithReload();

    const {permit, isStepEditable} = useOutletContext();
    const [error, setError] = useState(null);

    const handleDownload = () => {
        if (permit) {
            return PermitApplicationService.downloadInvoice(permit.id).then(
                response => {
                    return download(response)
                        .then(() => navigate("", true))
                        .catch(error => setError(error));
                }
            );
        } else {
            return Promise.reject("Nenhuma factura foi selecionada.");
        }
    };

    const handleFormSubmit = updatedPermit => {
        PermitApplicationService.update(permit_view_adapter(updatedPermit))
            .then(updatedPermit => {
                navigate("", true);
            })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    return permit ? (
        <Stack spacing={2}>
            <Stack alignItems="center">
                <DownloadPDFButton
                    handleGeneratePDF={handleDownload}
                    text="Imprimir factura"
                    color="secondary"
                    disabled={!isStepEditable}
                />
            </Stack>
            <PermitForm permit={permit} onSubmit={handleFormSubmit} error={error}>
                <PermitApplicationInvoiceFormFields disabled={!isStepEditable} />
            </PermitForm>
        </Stack>
    ) : null;
};

export default PermitApplicationInvoiceStep;
