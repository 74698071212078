import {useNavigate} from "react-router-dom";

import {theme} from "Theme";
import {TabUtil} from "../utilities";
import {useTabLogic} from "../hooks";

import {TabPanel} from ".";
import {AlertError} from "base/error/components";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";

const TabContainer = ({tabs, basePath = "", info = null, error = null}) => {
    const {tabIndex, handleChangeTab} = useTabLogic(tabs, basePath);
    const navigate = useNavigate();

    const tabStyle = {
        borderRight: "1px solid #eaeaea",
        "&.Mui-selected": {
            backgroundColor: "white",
        },
    };
    const separatorTabStyle = {
        borderRight: `3px solid ${theme.palette.primary.lighter}`,

        "&.Mui-selected": {
            backgroundColor: "white",
        },
    };

    return (
        <Box width="100%">
            <Grid
                container
                sx={{
                    backgroundColor: "grey.100",
                }}
            >
                <Grid item flexGrow={1}>
                    <Tabs value={tabIndex} aria-label="tabs">
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                {...TabUtil.a11yProps(index)}
                                onClick={
                                    tab.path
                                        ? () => navigate(tab.path)
                                        : () => handleChangeTab(null, index)
                                }
                                disabled={tab.disabled}
                                sx={tab.separator ? separatorTabStyle : tabStyle}
                            />
                        ))}
                    </Tabs>
                </Grid>
                {info && (
                    <Grid
                        item
                        xs
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{
                            px: 2,
                            // borderLeft: 1,
                            // borderColor: "grey.200",
                            // backgroundColor: "white",
                        }}
                    >
                        {info}
                    </Grid>
                )}
            </Grid>
            <Divider />
            <AlertError error={error} />
            {tabs.map((tab, index) => (
                <TabPanel key={index} index={index} visible={tabIndex === index}>
                    {tab.content}
                </TabPanel>
            ))}
        </Box>
    );
};

export default TabContainer;
