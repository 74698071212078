import Button from "@mui/material/Button";

const DeleteEntityButton = ({openDialog, entityName = "", disabled = false}) => {
    const handleDialog = () => {
        openDialog();
    };

    return (
        <Button
            variant="contained"
            color="error"
            disabled={disabled}
            onClick={handleDialog}
        >
            {entityName ? `Remover ${entityName}` : "Remover"}
        </Button>
    );
};

export default DeleteEntityButton;
