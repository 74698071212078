import {DialogLayout} from "base/dialog/components";

const CancelProcessDialog = ({isDialogOpen, setIsDialogOpen, onCancel}) => {
    const handleDialog = isOpen => {
        setIsDialogOpen(false);
    };

    const handleConfirmCancel = () => {
        setIsDialogOpen(false);
        onCancel();
    };

    return (
        <DialogLayout
            dialogTitle={`Tem certeza de que deseja cancelar o processo?`}
            dialogContentText={`Esta acção não poderá ser desfeita.`}
            mainActionText="Sim"
            secondaryActionText="Não"
            onMainActionClick={handleConfirmCancel}
            onHandleDialog={handleDialog}
            isDialogOpen={isDialogOpen}
            mainActionColor="error"
        />
    );
};

export default CancelProcessDialog;
