import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

const GenericChip = ({
    label,
    iconSrc = null,
    avatarText = null,
    tooltipText = "",
    style = {},
}) => {
    return (
        <Chip
            avatar={
                iconSrc || avatarText ? (
                    <Tooltip title={tooltipText}>
                        {iconSrc ? (
                            <Avatar
                                sx={{backgroundColor: "white", p: 0.4}}
                                src={iconSrc}
                            />
                        ) : (
                            <Avatar sx={{backgroundColor: "white", fontWeight: "bold"}}>
                                {avatarText}
                            </Avatar>
                        )}
                    </Tooltip>
                ) : null
            }
            label={label}
            sx={{
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                fontSize: "0.75em",
                color: "white",
                ...style,
            }}
        />
    );
};

export default GenericChip;
