import {useState} from "react";

import {EntityCreateDialog} from "base/entity/components/container";
import {EntityCreateButton} from "base/entity/components/presentational";

export function useCreateEntity(basePath = null, dialogTitle, creationForm = null) {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

    const handleClickCreate = () => {
        setIsCreateDialogOpen(true);
    };

    const handleClickClose = () => {
        setIsCreateDialogOpen(false);
    };

    const button = (
        <EntityCreateButton basePath={basePath} onClick={handleClickCreate} />
    );

    const dialog = (
        <EntityCreateDialog
            isDialogOpen={isCreateDialogOpen}
            formTitle={dialogTitle}
            creationForm={creationForm}
        />
    );

    return {button, dialog, handleClickClose};
}
