import {useState} from "react";
import {useOutletContext} from "react-router-dom";

import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";
import {useNavigateWithReload} from "base/navigation/hooks";
import {usePermitFieldStatus} from "permitapplication/hooks";

import {
    PermitForm,
    PermitApplicationInspectionFormFields,
} from "permitapplication/presentational/form";
import {PermitApplicationFieldSection} from "permitapplication/presentational";
import {FormContainer} from "base/form/components";
import {SectionCard} from "base/ui/section/components";

import Stack from "@mui/material/Stack";

const PermitApplicationInspectionStep = () => {
    const {permit, isStepEditable} = useOutletContext();
    const [error, setError] = useState(null);

    const {fieldStatus} = usePermitFieldStatus(permit);

    const navigate = useNavigateWithReload();

    const handleFormSubmit = updatedPermit => {
        PermitApplicationService.update(permit_view_adapter(updatedPermit))
            .then(updatedPermit => {
                navigate("", true);
            })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    return (
        <Stack spacing={1}>
            <FormContainer>
                <SectionCard>
                    <PermitApplicationFieldSection
                        permit={permit}
                        fieldStatus={fieldStatus}
                    />
                </SectionCard>
            </FormContainer>
            {["imported", "forced_imported"].includes(fieldStatus) && (
                <FormContainer>
                    <SectionCard>
                        <PermitForm
                            permit={permit}
                            onSubmit={handleFormSubmit}
                            error={error}
                        >
                            <Stack spacing={1}>
                                <PermitApplicationInspectionFormFields
                                    disabled={!isStepEditable}
                                />
                            </Stack>
                        </PermitForm>
                    </SectionCard>
                </FormContainer>
            )}
        </Stack>
    );
};

export default PermitApplicationInspectionStep;
