import {useState} from "react";

import {PlantationService} from "plantation/service";
import {plantation_view_adapter} from "plantation/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {EntityCommentsSection} from "base/entity/components/presentational/sections";
import {SectionCard, SectionCardHeaderAction} from "base/ui/section/components";
import {FormContainer, FormCommentsField} from "base/form/components";
import {
    PlantationForm,
    PlantationGeneralDataFormFields,
} from "plantation/presentational/form";
import {PlantationGeneralDataSection} from "plantation/presentational";

import EditIcon from "@mui/icons-material/Edit";

const ViewOrUpdatePlantationContent = ({
    plantation,
    section,
    label,
    allowEdit = true,
}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);

    const handleFormSubmit = plantation => {
        PlantationService.update(plantation_view_adapter({...plantation}))
            .then(updatedPlantation => {
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const getDataComponent = section => {
        if (section === "generaldata") {
            return <PlantationGeneralDataSection plantation={plantation} />;
        }
        if (section === "comments") {
            return <EntityCommentsSection comments={plantation?.comments} />;
        }
    };

    const getFormComponent = section => {
        if (section === "generaldata") {
            return <PlantationGeneralDataFormFields />;
        }
        if (section === "comments") {
            return <FormCommentsField placeholder="Observações" displayLabel={false} />;
        }
    };

    const getComponent = mode => {
        if (mode === "view") {
            return getDataComponent(section);
        }
        if (mode === "edit") {
            return (
                <FormContainer>
                    <PlantationForm
                        plantation={plantation}
                        onSubmit={handleFormSubmit}
                        onCancel={() => {
                            setMode("view");
                        }}
                        error={error}
                    >
                        {getFormComponent(section)}
                    </PlantationForm>
                </FormContainer>
            );
        }
    };

    const actions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                setMode("edit");
            }}
        />,
    ];

    return (
        <SectionCard title={label} secondaryActions={allowEdit ? actions : null}>
            {getComponent(mode)}
        </SectionCard>
    );
};

export default ViewOrUpdatePlantationContent;
