import {DialogLayout} from "base/dialog/components";

const CompleteProcessDialog = ({isDialogOpen, setIsDialogOpen, onComplete}) => {
    const handleDialog = isOpen => {
        setIsDialogOpen(false);
    };

    const handleConfirmCancel = () => {
        setIsDialogOpen(false);
        onComplete();
    };

    return (
        <DialogLayout
            dialogTitle={`Tem certeza de que deseja completar o processo?`}
            mainActionText="Sim"
            secondaryActionText="Não"
            onMainActionClick={handleConfirmCancel}
            onHandleDialog={handleDialog}
            isDialogOpen={isDialogOpen}
            mainActionColor="info"
        />
    );
};

export default CompleteProcessDialog;
