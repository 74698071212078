import {useState} from "react";
import {HolderSearchAutocomplete, HolderSummary} from "..";
import Grid from "@mui/material/Grid";

const HolderFormSearch = ({label = "", onClickSelected = null, rules = {}}) => {
    const [existingHolder, setExistingHolder] = useState(null);

    const handleSelectExistingHolder = holder => {
        if (onClickSelected) {
            onClickSelected(holder);
        }
        setExistingHolder(holder);
    };

    return (
        <Grid container rowSpacing={1} mt={1}>
            <Grid item xs={12}>
                <HolderSearchAutocomplete
                    label={label}
                    onChangeHandler={handleSelectExistingHolder}
                    rules={rules}
                />
            </Grid>
            <Grid item xs={12}>
                {existingHolder && <HolderSummary holder={existingHolder} />}
            </Grid>
        </Grid>
    );
};

export default HolderFormSearch;
