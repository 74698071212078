import {PlotService} from "plot/service";
import {SearchAutocomplete} from "base/search/components";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const PlotSearchAutocomplete = ({onSelect}) => {
    const optionComponent = option => {
        return (
            <Stack>
                <Typography>{option.code}</Typography>
                <Typography variant="caption" sx={{ml: 1}}>
                    {option.district} | {option.holder?.name || "Sem propriétario/a"}
                </Typography>
            </Stack>
        );
    };

    const getFilterOption = option => {
        return option.code + " - " + option.district + option.holder?.name;
    };

    return (
        <SearchAutocomplete
            name="plot"
            label="Parcela"
            optionLabel="code"
            optionLabelSecondary="district"
            optionComponent={optionComponent}
            getFilterOption={getFilterOption}
            search={PlotService.getBySearchText}
            onChangeHandler={onSelect}
        />
    );
};

export default PlotSearchAutocomplete;
