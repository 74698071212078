import {useDomain} from "snimf/domain/provider";
import {
    FormCheckbox,
    FormInputDecimal,
    FormInputText,
    FormSelect,
    FormSelectMultiple,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const PlotGeneralDataFormFields = () => {
    const {landCoverDomain, landSizeDomain} = useDomain();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormInputText name="number" label="Número" />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="code" label="Código" disabled />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="official_area"
                    label="Área do título de terras"
                    endAdornment="m²"
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="geom_area"
                    label="Área da geometria"
                    endAdornment="m²"
                    disabled
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="land_size"
                    label="Classificação por tamanho"
                    options={landSizeDomain}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelectMultiple
                    name="land_cover"
                    label="Cobertura do solo"
                    options={landCoverDomain}
                />
            </Grid>
            <Grid item xs={6}>
                <FormCheckbox name="is_area_protected" label="Zona de protecção" />
            </Grid>
        </Grid>
    );
};

export default PlotGeneralDataFormFields;
