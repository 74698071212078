import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {NumberUtil} from "base/format/utilities";
import {FieldUtil} from "base/ui/section/utilities";
import {MaterialUtil} from "material/utils";

import Grid from "@mui/material/Grid";

const MaterialDataFields = ({material, displayFees = true}) => {
    const unitsForQuantity = MaterialUtil.getUnitsForQuantity(material.type);
    const unitsForFee = MaterialUtil.getUnitsForFee(material.type);

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Tipo", material.type)}
                {MaterialUtil.displayLength(material?.type) &&
                    FieldUtil.getSectionField(
                        "Cumprimento total das vigas",
                        NumberUtil.formatDecimal(material.length),
                        "m"
                    )}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField(
                    "Quantidade",
                    NumberUtil.formatInteger(material.quantity),
                    unitsForQuantity
                )}
            </Grid>
            {displayFees ? (
                <>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Taxa de transporte",
                            NumberUtil.formatCurrency(material.fee, false),
                            unitsForFee
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Valor de transporte",
                            NumberUtil.formatCurrency(material.price, false),
                            CURRENCY_SYMBOL
                        )}
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default MaterialDataFields;
