import {useEffect, useState} from "react";
import {useDomain} from "snimf/domain/provider";

export const VIOLATION_TYPES_COLORS = [
    // {color: "#2a2234", contrastTextColor: "#FFFFFF"},
    {color: "#37233D", contrastTextColor: "#FFFFFF"},
    {color: "#72617E", contrastTextColor: "#FFFFFF"},
    {color: "#9C88A7", contrastTextColor: "#000000"},
    {color: "#877593", contrastTextColor: "#FFFFFF"},
    // {color: "#685775", contrastTextColor: "#FFFFFF"},
    {color: "#B19CBB", contrastTextColor: "#000000"},
    {color: "#D2C5DD", contrastTextColor: "#000000"},
    {color: "#DBC4E4", contrastTextColor: "#000000"},
    {color: "#C19CBB", contrastTextColor: "#000000"},
    {color: "#5E4E6A", contrastTextColor: "#FFFFFF"},
    {color: "#4B3B57", contrastTextColor: "#FFFFFF"},
    {color: "#F2E9F5", contrastTextColor: "#000000"},
    {color: "grey.500", contrastTextColor: "#000000"},
];

function useViolationTypesWithColors(types) {
    const {violationTypeDomain: violationTypes} = useDomain();

    const [violationTypesWithColor, setViolationTypesWithColor] = useState([]);

    useEffect(() => {
        const typesWithColors = violationTypes.map((type, index) => ({
            ...type,
            ...(index < VIOLATION_TYPES_COLORS.length
                ? VIOLATION_TYPES_COLORS[index]
                : VIOLATION_TYPES_COLORS[VIOLATION_TYPES_COLORS.length - 1]),
        }));
        setViolationTypesWithColor(typesWithColors);
    }, [violationTypes]);

    const getFilteredTypesWithColors = () => {
        return violationTypesWithColor.filter(type => types.includes(type.value));
    };

    const filteredTypesWithColors = getFilteredTypesWithColors();

    return {violationTypesWithColor, filteredTypesWithColors};
}

export {useViolationTypesWithColors};
