import {FormContainer, FormDatePicker, FormInputText} from "base/form/components";
import Grid from "@mui/material/Grid";

const PermitApplicationInspectionFormFields = ({disabled = false}) => {
    return (
        <FormContainer>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={4}>
                    <FormDatePicker
                        name="inspection_date"
                        label="Data da vistoria *"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={8}>
                    <FormInputText
                        name="inspection_team"
                        label="Equipa *"
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </FormContainer>
    );
};

export default PermitApplicationInspectionFormFields;
