import {theme} from "Theme";
import {RouterUtil} from "base/navigation/utilities";
import {PERMIT_APPLICATION_STATUS} from "permitapplication/data";
import {usePermitApplicationProcessUrl} from "permitapplication/hooks";
import {SubPageMenu, SubPageMenuListItemButton} from "base/ui/menu";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import FolderOpenIconOutlined from "@mui/icons-material/FolderOpenOutlined";

// TO-DO: Find better icons
const PermitApplicationSubPageMenu = ({permit}) => {
    const basePath = `/permits/applications/list/${permit?.id}`;

    const {getApplicationProcessPath, getPaymentProcessPath} =
        usePermitApplicationProcessUrl(permit, basePath);

    const applicationProcessPath = getApplicationProcessPath();
    const paymentProcessPath = getPaymentProcessPath();

    const applicationResolvedPath = applicationProcessPath.slice(
        0,
        RouterUtil.getLastSlashIndex(applicationProcessPath)
    );
    const paymentResolvedPath = paymentProcessPath.slice(
        0,
        RouterUtil.getLastSlashIndex(paymentProcessPath)
    );

    const headerSecondary = (
        <>
            <Stack
                sx={{
                    backgroundColor: "#ffe0b2",
                    borderRadius: 1,
                    p: 0.5,
                    mb: 0.5,
                }}
            >
                <Typography
                    color={
                        permit?.status === PERMIT_APPLICATION_STATUS.not_approved
                            ? theme.palette.error.dark
                            : theme.palette.primary.dark
                    }
                    fontSize={theme.typography.fontSize}
                    fontWeight={500}
                    lineHeight={1.25}
                >
                    {permit?.status || "Estado pendente"}
                </Typography>
            </Stack>
            {permit?.district || "Sem distrito"}
            {permit?.plot?.land_ownership &&
                ` | ${permit?.plot?.land_ownership.toUpperCase()}`}
        </>
    );

    return (
        <SubPageMenu
            headerTitle="Autorização"
            headerText={permit?.code}
            headerSecondary={headerSecondary}
        >
            <SubPageMenuListItemButton
                key="permit-summary"
                to={`${basePath}/summary`}
                text="Resumo"
                icon={<InfoOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="permit-application-process"
                to={applicationProcessPath}
                resolvedPathName={applicationResolvedPath}
                text="Processo de solicitação"
                icon={<FactCheckOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="permit-payment-process"
                to={paymentProcessPath}
                resolvedPathName={paymentResolvedPath}
                text="Processo de pagamento"
                icon={<RequestPageOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="holder-documents"
                to={`${basePath}/documents`}
                text="Documentos"
                icon={<FolderOpenIconOutlined />}
            />
        </SubPageMenu>
    );
};

export default PermitApplicationSubPageMenu;
