import {DialogLayout} from "base/dialog/components";

const DeleteItemDialog = ({msg, setMsg, deletionError = null, onDelete}) => {
    const handleDialog = isOpen => {
        setMsg("");
    };

    const handleConfirmDeletion = () => {
        setMsg("");
        onDelete();
    };

    return (
        <DialogLayout
            dialogTitle={deletionError ? "Erro" : "Aviso"}
            dialogContentText={msg}
            onMainActionClick={handleConfirmDeletion}
            mainActionText="Remover"
            mainActionColor="error"
            onHandleDialog={handleDialog}
            isDialogOpen={!!msg}
            error={deletionError}
        />
    );
};

export default DeleteItemDialog;
