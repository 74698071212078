import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";

import {LocationProvider} from "snimf/location/provider";
import {useFormDirtyContext} from "base/form/provider";
import {FormUtil} from "base/form/utilities";

import {EntityForm} from "base/entity/components/form";
import {AlertError} from "base/error/components";
import Stack from "@mui/material/Stack";

const PermitForm = ({
    permit = null,
    onSubmit,
    onCancel = null,
    error = null,
    direction = "column",
    children,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const defaultFormValues = {
        id: FormUtil.getFormValue(permit?.id),
        code: FormUtil.getFormValue(permit?.code),
        on_date: FormUtil.getFormValue(permit?.on_date),
        application_date: FormUtil.getFormValue(permit?.application_date),
        status: FormUtil.getFormValue(permit?.status),
        district: FormUtil.getFormValue(permit?.district),
        types: FormUtil.getFormValue(permit?.types, []),
        comments: FormUtil.getFormValue(permit?.comments),
        inspection_date: FormUtil.getFormValue(permit?.inspection_date),
        inspection_team: FormUtil.getFormValue(permit?.inspection_team),
        felling_start_date: FormUtil.getFormValue(permit?.felling_start_date),
        felling_end_date: FormUtil.getFormValue(permit?.felling_end_date),
        felling_beneficiary: FormUtil.getFormValue(permit?.felling_beneficiary),
        felling_price: FormUtil.getFormValue(permit?.felling_price),
        cutting_start_date: FormUtil.getFormValue(permit?.cutting_start_date),
        cutting_end_date: FormUtil.getFormValue(permit?.cutting_end_date),
        cutting_beneficiary: FormUtil.getFormValue(permit?.cutting_beneficiary),
        cutting_price: FormUtil.getFormValue(permit?.cutting_price),
        clearing_start_date: FormUtil.getFormValue(permit?.clearing_start_date),
        clearing_end_date: FormUtil.getFormValue(permit?.clearing_end_date),
        clearing_beneficiary: FormUtil.getFormValue(permit?.clearing_beneficiary),
        clearing_area: FormUtil.getFormValue(permit?.clearing_area),
        clearing_fee: FormUtil.getFormValue(permit?.clearing_fee),
        clearing_price: FormUtil.getFormValue(permit?.clearing_price),
        transport_start_date: FormUtil.getFormValue(permit?.transport_start_date),
        transport_end_date: FormUtil.getFormValue(permit?.transport_end_date),
        transport_beneficiary: FormUtil.getFormValue(permit?.transport_beneficiary),
        transport_destination: FormUtil.getFormValue(permit?.transport_destination),
        transport_price: FormUtil.getFormValue(permit?.transport_price),
        fuel_fee: FormUtil.getFormValue(permit?.fuel_fee),
        printing_fee: FormUtil.getFormValue(permit?.printing_fee),
        restock_fee: FormUtil.getFormValue(permit?.restock_fee),
        total_due: FormUtil.getFormValue(permit?.total_due),
        application_doc: FormUtil.getFormValue(permit?.application_doc),
        personal_id_doc: FormUtil.getFormValue(permit?.personal_id_doc),
        plot_ownership_doc: FormUtil.getFormValue(permit?.plot_ownership_doc),
        plot_use_authorization: FormUtil.getFormValue(permit?.plot_use_authorization),
        tax_id_doc: FormUtil.getFormValue(permit?.tax_id_doc),
        technical_report: FormUtil.getFormValue(permit?.technical_report),
        payment_receipt: FormUtil.getFormValue(permit?.payment_receipt),
        technical_decision: FormUtil.getFormValue(permit?.technical_decision),
        application_doc_valid: FormUtil.getFormValue(permit?.application_doc_valid),
        personal_id_doc_valid: FormUtil.getFormValue(permit?.personal_id_doc_valid),
        plot_ownership_doc_valid: FormUtil.getFormValue(
            permit?.plot_ownership_doc_valid
        ),
        plot_use_authorization_valid: FormUtil.getFormValue(
            permit?.plot_use_authorization_valid
        ),
        tax_id_doc_valid: FormUtil.getFormValue(permit?.tax_id_doc_valid),
        is_the_plot_owner: FormUtil.getFormValue(permit?.is_the_plot_owner),
        invoice_sent: FormUtil.getFormValue(permit?.invoice_sent),
        application_sent: FormUtil.getFormValue(permit?.application_sent),
        application_signed: FormUtil.getFormValue(permit?.application_signed),
        holder: FormUtil.getFormValue(permit?.holder),
        plot: FormUtil.getFormValue(permit?.plot),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const {
        formState: {isDirty},
    } = formMethods;

    const context = useFormDirtyContext();
    const setDirty = context?.setDirty ?? (() => {});

    useEffect(() => {
        setDirty(isDirty);
    }, [isDirty]);

    const handleFormSubmit = data => {
        setIsSubmitting(true);
        const updatedPermitApplicaton = {
            id: FormUtil.getDataValue(data.id),
            code: FormUtil.getDataValue(data.code),
            on_date: FormUtil.getDataValue(data.on_date),
            application_date: FormUtil.getDataValue(data.application_date),
            status: FormUtil.getDataValue(data.status),
            district: FormUtil.getDataValue(data.district),
            types: FormUtil.getDataValue(data.types),
            comments: FormUtil.getDataValue(data.comments),
            inspection_date: FormUtil.getDataValue(data.inspection_date),
            inspection_team: FormUtil.getDataValue(data.inspection_team),
            felling_start_date: FormUtil.getDataValue(data.felling_start_date),
            felling_end_date: FormUtil.getDataValue(data.felling_end_date),
            felling_beneficiary: FormUtil.getDataValue(data.felling_beneficiary),
            felling_price: FormUtil.getDataValue(data.felling_price),
            cutting_start_date: FormUtil.getDataValue(data.cutting_start_date),
            cutting_end_date: FormUtil.getDataValue(data.cutting_end_date),
            cutting_beneficiary: FormUtil.getDataValue(data.cutting_beneficiary),
            cutting_price: FormUtil.getDataValue(data.cutting_price),
            clearing_start_date: FormUtil.getDataValue(data.clearing_start_date),
            clearing_end_date: FormUtil.getDataValue(data.clearing_end_date),
            clearing_beneficiary: FormUtil.getDataValue(data.clearing_beneficiary),
            clearing_area: FormUtil.getDataValue(data.clearing_area),
            clearing_fee: FormUtil.getDataValue(data.clearing_fee),
            clearing_price: FormUtil.getDataValue(data.clearing_price),
            transport_start_date: FormUtil.getDataValue(data.transport_start_date),
            transport_end_date: FormUtil.getDataValue(data.transport_end_date),
            transport_beneficiary: FormUtil.getDataValue(data.transport_beneficiary),
            transport_destination: FormUtil.getDataValue(data.transport_destination),
            transport_price: FormUtil.getDataValue(data.transport_price),
            fuel_fee: FormUtil.getDataValue(data.fuel_fee),
            printing_fee: FormUtil.getDataValue(data.printing_fee),
            restock_fee: FormUtil.getDataValue(data.restock_fee),
            total_due: FormUtil.getDataValue(data.total_due),
            application_doc: FormUtil.getDataValue(data.application_doc),
            personal_id_doc: FormUtil.getDataValue(data.personal_id_doc),
            plot_ownership_doc: FormUtil.getDataValue(data.plot_ownership_doc),
            plot_use_authorization: FormUtil.getDataValue(data.plot_use_authorization),
            tax_id_doc: FormUtil.getDataValue(data.tax_id_doc),
            technical_report: FormUtil.getDataValue(data.technical_report),
            payment_receipt: FormUtil.getDataValue(data.payment_receipt),
            technical_decision: FormUtil.getDataValue(data.technical_decision),
            application_doc_valid: FormUtil.getDataValue(data.application_doc_valid),
            personal_id_doc_valid: FormUtil.getDataValue(data.personal_id_doc_valid),
            plot_ownership_doc_valid: FormUtil.getDataValue(
                data.plot_ownership_doc_valid
            ),
            plot_use_authorization_valid: FormUtil.getDataValue(
                data.plot_use_authorization_valid
            ),
            tax_id_doc_valid: FormUtil.getDataValue(data.tax_id_doc_valid),
            is_the_plot_owner: FormUtil.getDataValue(data.is_the_plot_owner),
            invoice_sent: FormUtil.getDataValue(data.invoice_sent),
            application_sent: FormUtil.getDataValue(data.application_sent),
            application_signed: FormUtil.getDataValue(data.application_signed),
            holder: FormUtil.getDataValue(data.holder),
            plot: FormUtil.getDataValue(data.plot),
        };

        onSubmit(updatedPermitApplicaton, success => {
            setIsSubmitting(false);
        });
    };

    return (
        <LocationProvider>
            <FormProvider {...formMethods}>
                <Stack spacing={1}>
                    <AlertError error={error} />
                    <EntityForm
                        onSubmit={formMethods.handleSubmit(handleFormSubmit)}
                        onCancel={onCancel}
                        isFormDirty={isDirty}
                        isSubmitting={isSubmitting}
                        direction={direction}
                    >
                        {children}
                    </EntityForm>
                </Stack>
            </FormProvider>
        </LocationProvider>
    );
};

export default PermitForm;
