class Contacts extends Array {}

const contact_api_adapter = contact => {
    return contact;
};

const contacts_api_adapter = contacts => {
    return contacts.map(contact_api_adapter);
};

const contact_view_adapter = contact => {
    // in front-end falsy values are "" or undefined or null
    // we must destructure object before its adaptation because
    // nested objects are still inmutable inside contact object

    delete contact["created_by"];
    delete contact["updated_by"];
    delete contact["created_at"];
    delete contact["updated_at"];

    return contact;
};

const createContacts = (data = []) => {
    const contacts = Contacts.from(data, contact => createContact(contact));
    return contacts;
};

const createContact = ({
    id = -1,
    name = "",
    phone = "",
    email = "",
    comments = null,
    permit = null,
    created_by = "",
    updated_by = "",
    created_at = null,
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        name,
        phone,
        email,
        comments,
        permit,
        created_by,
        updated_by,
        created_at,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createContact as default,
    createContacts,
    contact_api_adapter,
    contacts_api_adapter,
    contact_view_adapter,
};
