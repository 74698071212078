import {APP_LOGO_URL, APP_NAME, APP_NAME_LONG} from "snimf/config/appInfo";
import {HERO_HEIGHT} from "base/ui/app/config/measurements";
import {LogoLink} from "base/navigation/components";
import {HeaderHero} from "base/ui/header";

const SnimfHeaderHero = () => {
    const logoHeight = `${HERO_HEIGHT - 16}px`;

    const snimfLeftLogos = [
        <LogoLink
            key="app_name"
            href="/"
            textLogo={APP_NAME_LONG}
            title={`Página inicial do ${APP_NAME}`}
            targetBlank={false}
            style={{fontSize: 18, maxWidth: "400px", color: "#fff"}}
        />,
    ];

    const snimfRightLogo = [
        <LogoLink
            key="main_logo"
            href="http://snmf.app.gov.st/"
            title="Web do SNMF"
            src={APP_LOGO_URL}
            alt="Logo del DFB"
            logoHeight={logoHeight}
        />,
    ];

    return (
        <>
            <HeaderHero left={snimfLeftLogos} right={snimfRightLogo} />
        </>
    );
};

export default SnimfHeaderHero;
