import {createRoot} from "react-dom/client";
import "./App.css";
import App from "./App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import GA4React from "ga-4-react";
import * as Sentry from "@sentry/react";
import {captureConsoleIntegration} from "@sentry/react";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

if (process.env.REACT_APP_GOOGLE_ANALYTICS_CODE) {
    try {
        setTimeout(_ => {
            const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
            ga4react
                .initialize()
                .then(ga4 => {
                    ga4.pageview("path");
                    ga4.gtag("event", "pageview", "path"); // or your custom gtag event
                })
                .catch(err => console.error(err));
        }, 4000);
    } catch (err) {
        console.error(err);
    }
}

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            captureConsoleIntegration({levels: ["warn", "error"]}),
        ],

        // performance
        tracesSampleRate: 0.05,

        // errors
        sampleRate: 1.0,

        //release:
        environment: "production",
    });
}
