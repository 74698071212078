import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const useTabLogic = (tabs, basePath, initialTab = 0) => {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(initialTab);

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash.replace("#", "");
        const tabIndexFromHash = tabs.findIndex(tab => tab.hash === hash);
        const tabIndexFromPath = tabs.findIndex(tab =>
            location.pathname.startsWith(`${basePath}/${tab.path}`)
        );

        if (hash && tabIndexFromHash !== -1) {
            setTabIndex(tabIndexFromHash);
        } else {
            if (basePath) {
                setTabIndex(tabIndexFromPath);
            } else {
                const firstEnabledTab = tabs.findIndex(tab => !tab.disabled);
                setTabIndex(firstEnabledTab !== -1 ? firstEnabledTab : 0);
            }
        }
    }, [location.hash, location.pathname, tabs]);

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
        const tabHash = tabs[newValue].hash;
        navigate(`#${tabHash}`);
    };

    return {tabIndex, handleChangeTab};
};

export default useTabLogic;
