import {DateUtil} from "base/format/utilities";
import {TextLink} from "base/navigation/components";
import {PermitTypeTags} from "permit/presentational";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export function useApprovedPermitsTable() {
    const tableColumns = [
        {
            id: "code",
            label: "Código",
            width: 10,
            formatFunction: item => (
                <TextLink
                    text={item?.code}
                    to={`/permits/approved/list/${item.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
        },
        {
            id: "is_valid",
            label: "Em vigor",
            width: 5,
            formatFunction: item =>
                item.is_valid ? (
                    <CheckCircleOutlinedIcon color="success" />
                ) : (
                    <CancelOutlinedIcon color="action" />
                ),
        },
        {
            id: "on_date",
            label: "Data de aprovação",
            width: 10,
            formatFunction: item => DateUtil.formatDate(item.on_date),
        },
        {
            id: "holder__name",
            label: "Titular",
            width: 15,
            formatFunction: item => (
                <TextLink
                    text={item?.holder?.name}
                    to={`/holders/all/list/${item.holder?.id}/summary`}
                    textStyle={{fontSize: "inherit"}}
                />
            ),
        },
        {
            id: "district",
            label: "Distrito",
            width: 15,
        },
        {
            id: "plot__land_ownership",
            label: "Propriedade da terra",
            width: 15,
            formatFunction: item => {
                return item?.plot?.land_ownership;
            },
        },
        {
            id: "types",
            label: "Guias",
            width: 30,
            formatFunction: item => {
                return <PermitTypeTags types={item?.types} />;
            },
            disableSorting: true,
        },
    ];

    return {tableColumns};
}
