import {DialogLayout} from "base/dialog/components";
import {DocumentService} from "../service";

const DeleteDocumentDialog = ({
    folderElement,
    onDeletedFolderElement = null,
    isDialogOpen,
    setIsDialogOpen,
}) => {
    const handleDialog = isOpen => {
        setIsDialogOpen(false);
    };

    const handleConfirmDeletion = () => {
        setIsDialogOpen(false);

        DocumentService.delete(folderElement.path).then(() => {
            if (onDeletedFolderElement) {
                onDeletedFolderElement();
            }
        });
    };

    return (
        <DialogLayout
            dialogLabel="Delete document"
            dialogTitle="Deseja eliminar este ficheiro?"
            dialogContentText="Se clicar em Remover, o ficheiro será eliminado e não poderá ser recuperado."
            onMainActionClick={handleConfirmDeletion}
            mainActionText="Remover"
            mainActionColor="error"
            onHandleDialog={handleDialog}
            isDialogOpen={isDialogOpen}
        />
    );
};

export default DeleteDocumentDialog;
