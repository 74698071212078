import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil} from "base/format/utilities";

import Grid from "@mui/material/Grid";

const HolderPersonalDocsSection = ({holder}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Tipo de documento", holder.doc_type)}
                {FieldUtil.getSectionField(
                    "Data de validade",
                    DateUtil.formatDate(holder.doc_expiration_date)
                )}
            </Grid>
            <Grid item xs={6}>
                {FieldUtil.getSectionField("Número de documento", holder.doc_number)}
            </Grid>
        </Grid>
    );
};

export default HolderPersonalDocsSection;
