import {
    createPlant,
    createPlants,
    plant_api_adapter,
    plants_api_adapter,
} from "plant/model";
import {createEntityService} from "base/entity/service";

const basePath = "/api/app/plants";

const entityService = createEntityService(
    basePath,
    createPlant,
    createPlants,
    plant_api_adapter,
    plants_api_adapter
);

const PlantService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(plant) {
        return entityService.create(plant);
    },

    update(plant) {
        return entityService.update(plant);
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default PlantService;
