import {useState} from "react";
import {useOutletContext} from "react-router-dom";

import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";
import {useNavigateWithReload} from "base/navigation/hooks";

import {PermitApplicationTechnicalDataTabs} from ".";
import {PermitForm, PermitApplicationTypesFormField} from "./form";
import {FormContainer} from "base/form/components";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {PermitApplicationTechinalDataMapProvider, PlotTreesMapProvider} from "plot/map";
import {MapBaseLayout, useMapDialog} from "base/map/layout";

const PermitApplicationTechnicalDataStep = () => {
    const {permit, isStepEditable} = useOutletContext();
    const navigate = useNavigateWithReload();

    const [error, setError] = useState(null);

    const {button, getDialog} = useMapDialog();

    const handlePermitApplicationFormSubmit = updatedPermit => {
        PermitApplicationService.update(permit_view_adapter(updatedPermit))
            .then(updatedPermit => {
                navigate("", true);
            })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    return (
        <Stack spacing={1}>
            <Grid container>
                <Grid item xs={12} md={9}>
                    <FormContainer>
                        <PermitForm
                            permit={permit}
                            onSubmit={handlePermitApplicationFormSubmit}
                            error={error}
                            direction="row"
                        >
                            <PermitApplicationTypesFormField
                                disabled={!isStepEditable}
                            />
                        </PermitForm>
                    </FormContainer>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Stack sx={{p: 1}} justifyContent="center">
                        {button}
                        {getDialog(
                            <PermitApplicationTechinalDataMapProvider permit={permit}>
                                <MapBaseLayout height="100%" />
                            </PermitApplicationTechinalDataMapProvider>,
                            dirty => {
                                console.log("closing", {dirty});
                            }
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <FormContainer>
                <PermitApplicationTechnicalDataTabs
                    permit={permit}
                    onSubmitPermitApplication={handlePermitApplicationFormSubmit}
                    isStepEditable={isStepEditable}
                    error={error}
                />
            </FormContainer>
        </Stack>
    );
};

export default PermitApplicationTechnicalDataStep;
