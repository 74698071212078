import {useOutletContext} from "react-router-dom";
import {useEntityPermissions} from "snimf/permissions/hooks";
import {ViewDocumentsSubPage} from "base/file/components";

const ViewPlantationDocumentsSubPage = () => {
    let plantation;
    [plantation] = useOutletContext();

    const {allowEdit} = useEntityPermissions(plantation, "plantations");

    return (
        plantation && (
            <ViewDocumentsSubPage
                entity={plantation}
                basePath={"/plantations/all/list"}
                disabled={!allowEdit}
            />
        )
    );
};

export default ViewPlantationDocumentsSubPage;
