import {SubPageMenu, SubPageMenuListItemButton} from "base/ui/menu";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import FolderOpenIconOutlined from "@mui/icons-material/FolderOpenOutlined";

const PlotSubPageMenu = ({plot}) => {
    const basePath = `/plots/all/list/${plot?.id}`;

    const secondary = (
        <>
            <strong>{plot?.holder?.name || "Sem propriétario/a"}</strong>
            <br />
            {plot?.district || "Sem distrito"}
        </>
    );

    return (
        <SubPageMenu
            headerTitle="Parcela"
            headerText={`${plot?.code}`}
            headerSecondary={secondary}
        >
            <SubPageMenuListItemButton
                key="plot-summary"
                to={`${basePath}/summary`}
                text="Resumo"
                icon={<InfoOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="plot-location"
                to={`${basePath}/location`}
                text="Localização"
                icon={<PlaceOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="plot-procedures"
                to={`${basePath}/procedures`}
                text="Procedimentos"
                icon={<SummarizeOutlinedIcon />}
            />
            {/* <SubPageMenuListItemButton
                key="plot-documents"
                to={`${basePath}/documents`}
                text="Documentos"
                icon={<FolderOpenIconOutlined />}
            /> */}
        </SubPageMenu>
    );
};

export default PlotSubPageMenu;
