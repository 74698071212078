import {useCallback, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";

import {DateUtil} from "base/format/utilities";
import {useList} from "base/entity/provider";
import {useDomain} from "snimf/domain/provider";
import {useAdministrativeDivisions} from "snimf/location/provider";

import {
    FormClearButtonSmall,
    FormSelect,
    FormSelectMultiple,
    FormDatePicker,
    FormSwitchInput,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const PermitApplicationFilterForm = ({onClear = null}) => {
    const {filter, changeFilter, resetFilter, setPage} = useList();
    const {permitApplicationStatusDomain, permitTypeDomain} = useDomain();

    const {districts} = useAdministrativeDivisions();

    useEffect(() => {
        if (!Object.keys(filter).length) {
            handleClearAllFilters();
        }
    }, [filter]);

    useEffect(() => {
        const attributeValue = {district: ""};
        let updatedFilter = {...filter, ...attributeValue};
        changeFilter(updatedFilter);
    }, [districts]);

    const formMethods = useForm({
        defaultValues: {
            include_denied: filter?.include_denied || true,
            status: filter?.status || "",
            application_date_min: filter?.application_date_min || null,
            application_date_max: filter?.application_date_max || null,
            district: filter?.district || "",
            types: filter?.types || [],
        },
    });

    const handleChange = useCallback(
        attributeValue => {
            setPage(1);

            if (
                isInvalidDate(attributeValue, "application_date_min") ||
                isInvalidDate(attributeValue, "application_date_max")
            ) {
                return;
            }

            let updatedFilter = {...filter, ...attributeValue};

            if (attributeValue?.include_denied === true) {
                updatedFilter = {...updatedFilter, include_denied: null};
            }

            changeFilter(updatedFilter);
        },
        [filter]
    );

    const handleClearAllFilters = () => {
        formMethods.reset({
            include_denied: true,
            status: "",
            application_date_min: null,
            application_date_max: null,
            district: "",
            types: [],
        });
        if (onClear) {
            onClear();
        }
        if (Object.entries(filter).length > 0) resetFilter();
    };

    const isInvalidDate = (attributeValue, key) =>
        key in attributeValue && DateUtil.isInvalidDateObject(attributeValue[key]);

    return (
        <FormProvider {...formMethods}>
            <Grid container columnSpacing={1} alignItems="center" mt={1}>
                <Grid item xs={4} md={1.5}>
                    <FormSwitchInput
                        name="include_denied"
                        label="Não aprovadas"
                        onChangeHandler={option => {
                            handleChange({
                                include_denied: option?.target?.checked || false,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormSelect
                        name="status"
                        label="Estado"
                        options={permitApplicationStatusDomain}
                        onChangeHandler={options => {
                            handleChange({status: options});
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormDatePicker
                        name="application_date_min"
                        label="Solicitação após"
                        onChangeHandler={date =>
                            handleChange({application_date_min: date})
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormDatePicker
                        name="application_date_max"
                        label="Solicitação anterior a"
                        onChangeHandler={date =>
                            handleChange({application_date_max: date})
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormSelect
                        name="district"
                        label="Distrito"
                        options={districts}
                        onChangeHandler={option =>
                            handleChange({
                                district: option,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormSelectMultiple
                        name="types"
                        label="Tipo guia"
                        options={permitTypeDomain}
                        onChangeHandler={options => {
                            handleChange({types: options});
                        }}
                    />
                </Grid>

                <Grid item xs={0.5}>
                    <FormClearButtonSmall handleClear={handleClearAllFilters} />
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default PermitApplicationFilterForm;
