import {useMapGeojsonLayerConfig, useMapLayerConfig} from "base/map/layer";
import {useGeojsonEditableLayer, useGeojsonLayer} from "base/map/leaflet/layer";
import {
    createLayerLegend,
    createLayerLegendDiscriminator,
    createLayerLegendDiscriminatorItem,
    createWMSLegendIcon,
} from "base/map/legend";
import {PlotService} from "plot/service";

const popup = feature => {
    let data = feature.properties;
    let popupContent = `<b>Parcela: ${data["code"] ? data["code"] : "---"}</b>`;
    popupContent += "<ul class='attributes'>";
    popupContent += `<li><i>Proprietário/a</i>: ${data["holder_name"] || "-"}</li>`;
    popupContent += "</ul>";
    popupContent += `<div style="width: 100%; text-align: center;"><a href='/plots/all/list/${feature.id}/summary' target="_blank">Ver parcela</a></div>`;
    return popupContent;
};

export function createPlotLayer({
    interactive = true,
    cluster = true,
    fitBounds = false,
}) {
    return useGeojsonLayer({
        type: "polygon",
        cluster: cluster
            ? {
                  zoomStop: 14,
              }
            : null,
        style: {
            color: "#ff8c00cc",
            fillColor: "#ff8c00cc",
        },
        popup: popup,
        pane: 8,
        interactive,
        fitBounds,
    });
}

export function createPlotLegend() {
    return createLayerLegend({
        code: "plots",
        label: "Parcelas",
        icon: createWMSLegendIcon({
            type: "square",
        }),
    });
}

export function createProcedureLegendDiscriminator() {
    return createLayerLegendDiscriminator({
        name: "Procedimento",
        field: "procedure",
        style: "points",
        entries: [
            createLayerLegendDiscriminatorItem({
                code: "procedure-violation",
                label: "Infracção",
                style: {
                    fillColor: "orange",
                },
                filter: val => val.includes("violation"),
            }),
            createLayerLegendDiscriminatorItem({
                code: "procedure-plantation",
                label: "Plantio",
                style: {
                    fillColor: "green",
                },
                filter: val => val.includes("plantation"),
            }),
            createLayerLegendDiscriminatorItem({
                code: "procedure-permit",
                label: "Autorização",
                style: {
                    fillColor: "blue",
                },
                filter: val => val.includes("permit"),
            }),
        ],
    });
}

export function createNoHolderLegendDiscriminator() {
    return createLayerLegendDiscriminator({
        name: "Propietário/a",
        field: "holder_name",
        entries: [
            createLayerLegendDiscriminatorItem({
                code: "code-under-1000",
                label: "Sim propietário/a",
                style: {
                    fillColor: "red",
                },
                filter: val => val === null,
            }),
            createLayerLegendDiscriminatorItem({
                code: "code-over-1000",
                label: "Com propietário/a",
                style: {
                    fillColor: "green",
                },
                filter: val => val !== null,
            }),
        ],
    });
}

export function createPlotLayerConfig({
    label = "Parcelas",
    excludedPlotId = null,
    fitBounds = false,
}) {
    const plotLayer = createPlotLayer({interactive: true, cluster: true, fitBounds});
    const plotLegend = createLayerLegend({
        code: "plots",
        label,
        icon: createWMSLegendIcon({
            type: "square",
        }),
    });
    const procedureDiscriminator = createProcedureLegendDiscriminator();
    const noHolderDiscriminator = createNoHolderLegendDiscriminator();

    return useMapGeojsonLayerConfig({
        load: filter => {
            return PlotService.getFeatures({
                ...filter,
                excluded_id: excludedPlotId,
            });
        },
        update: (id, feature) => {
            console.log(`Updating ${id}`, feature);
            return PlotService.updateFeature(id, feature).then(response => {
                return response;
            });
        },
        layer: plotLayer,
        legend: plotLegend,
        discriminators: [procedureDiscriminator, noHolderDiscriminator],
    });
}

export function createSinglePlotLayerConfig({plot, list = null, onUpdate = null}) {
    let permitPlotLegendLabel;
    if (plot) {
        permitPlotLegendLabel = `Parcela ${plot.code}`;
    } else {
        permitPlotLegendLabel = "Sim parcela";
    }
    return useMapGeojsonLayerConfig({
        load: () => {
            if (plot) {
                return PlotService.getFeature(plot.id);
            }
            return Promise.resolve(false);
        },
        update: (id, feature) => {
            console.log(`Updating ${id}`, feature);
            return PlotService.updateFeature(id, feature).then(response => {
                if (onUpdate) {
                    onUpdate(response);
                }
                return response;
            });
        },
        layer: useGeojsonLayer({
            type: "polygon",
            style: {
                color: "#1d721d",
                fillColor: "#1d721d4d",
            },
            interactive: false,
            fitBounds: true,
        }),
        legend: createLayerLegend({
            code: "single-plot",
            label: permitPlotLegendLabel,
            icon: createWMSLegendIcon({
                type: "square",
            }),
        }),
        list,
    });
}
