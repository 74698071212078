import {Outlet, useLocation, useOutletContext} from "react-router-dom";

import {RouterUtil} from "base/navigation/utilities";

import {TabContainer} from "base/ui/tab/components";
import Box from "@mui/material/Box";

const ViewApprovedPermitTypesSubPage = () => {
    const [permit] = useOutletContext();

    const location = useLocation();
    const basePath = RouterUtil.getPathForSegment(location, "types");

    const treesForFelling = permit?.tree_set?.filter(tree => tree.felling);
    const treesForCutting = permit?.tree_set?.filter(tree => tree.cutting);

    const contextForOutlet = {
        permit: permit,
    };

    const tabs = [
        {
            label: "Resumo guias",
            path: "overview",
            content: <Outlet context={contextForOutlet} />,
        },
        {
            label: "Abate de árvores",
            path: "felling",
            content: <Outlet context={{permit, treesForFelling}} />,
            disabled: !permit?.types?.includes("felling"),
        },
        {
            label: "Serragem",
            path: "cutting",
            content: <Outlet context={{permit, treesForCutting}} />,
            disabled: !permit?.types?.includes("cutting"),
        },
        {
            label: "Desbravamento",
            path: "clearing",
            content: <Outlet context={{permit}} />,
            disabled: !permit?.types?.includes("clearing"),
        },
        {
            label: "Transporte",
            path: "transport",
            content: <Outlet context={{permit}} />,
            disabled: !permit?.types?.includes("transport"),
        },
    ];

    return (
        <Box>
            <TabContainer tabs={tabs} basePath={basePath} />
        </Box>
    );
};

export default ViewApprovedPermitTypesSubPage;
