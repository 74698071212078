import {ViewOrUpdateMaterialDataContent} from ".";
import Stack from "@mui/system/Stack";

const ViewMaterialsContent = ({permitId, materials, displayFees, disabled}) => {
    return materials.length ? (
        <Stack spacing={1}>
            {materials.map(material => (
                <div key={material.code} id={`${material.code}`}>
                    <ViewOrUpdateMaterialDataContent
                        permitId={permitId}
                        material={material}
                        displayFees={displayFees}
                        disabled={disabled}
                    />
                </div>
            ))}
        </Stack>
    ) : null;
};

export default ViewMaterialsContent;
