import {NoDataContainer} from "base/shared/components";
import {
    CreateContactDataContent,
    ViewOrUpdateContactDataContent,
} from "contact/container";
import Stack from "@mui/system/Stack";

const ContactsSection = ({permit, allowEdit = true}) => {
    return (
        <Stack spacing={2}>
            {permit?.contact_set.length ? (
                permit.contact_set.map(contact => {
                    return (
                        <ViewOrUpdateContactDataContent
                            key={contact.id}
                            permit={permit}
                            contact={contact}
                            allowEdit={allowEdit}
                        />
                    );
                })
            ) : (
                <NoDataContainer text="Não existem contactos associados." />
            )}
            {allowEdit ? <CreateContactDataContent permitId={permit?.id} /> : null}
        </Stack>
    );
};

export default ContactsSection;
