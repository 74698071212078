import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {PermitService} from "permit/service";
import {permit_view_adapter} from "permit/model";
import {FormSection} from "base/form/components";
import {
    PermitForm,
    PermitApplicationInitialFormFields,
} from "permitapplication/presentational/form";

const CreatePermitApplicationForm = ({onCancel = null}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname;

    const [error, setError] = useState("");

    const handleFormSubmit = permit => {
        PermitService.create(
            permit_view_adapter({
                ...permit,
                status: "Pendente dados titular (S)",
            })
        )
            .then(createdPermit => {
                navigate(`${basePath}/${createdPermit.id}/application/holder_s`);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const handleFormCancel = () => {
        onCancel ? onCancel() : navigate(`${basePath}/list`);
    };

    return (
        <PermitForm
            onSubmit={handleFormSubmit}
            onCancel={handleFormCancel}
            error={error}
        >
            <FormSection>
                <PermitApplicationInitialFormFields />
            </FormSection>
        </PermitForm>
    );
};

export default CreatePermitApplicationForm;
