import {useDomain} from "snimf/domain/provider";
import {FormDatePicker, FormInputText, FormSelect} from "base/form/components";
import Grid from "@mui/material/Grid";

const HolderPersonalDocsFormFields = () => {
    const {docTypeDomain} = useDomain();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={6}>
                <FormSelect
                    name="doc_type"
                    options={docTypeDomain}
                    label="Tipo de documento"
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormDatePicker
                    name="doc_expiration_date"
                    label="Data de validade"
                    disableFuture={false}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="doc_number"
                    label="Número de documento"
                    maxLength={20}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
        </Grid>
    );
};

export default HolderPersonalDocsFormFields;
