import {SectionCard, SectionField} from "base/ui/section/components";
import Stack from "@mui/material/Stack";

const PlotSummary = ({plot}) => {
    return (
        <SectionCard>
            <Stack direction="row" spacing={1}>
                <SectionField label="Código" value={plot?.code} />
                <SectionField label="Distrito" value={plot?.district} />
                <SectionField label="Propietário/a" value={plot?.holder?.name} />
                <SectionField label="Propriedade" value={plot?.land_ownership} />
            </Stack>
        </SectionCard>
    );
};

export default PlotSummary;
