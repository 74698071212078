import {
    createPlantation,
    createPlantations,
    plantation_api_adapter,
    plantations_api_adapter,
} from "plantation/model";
import {createEntityService} from "base/entity/service";
import {AuthApiService} from "base/api/service";
import {createHolder, holder_api_adapter} from "holder/model";
import {createPlot, plot_api_adapter} from "plot/model";
import {
    createPlant,
    createPlants,
    plant_api_adapter,
    plants_api_adapter,
} from "plant/model";

const basePath = "/api/app/plantations";

const entityService = createEntityService(
    basePath,
    createPlantation,
    createPlantations,
    plantation_api_adapter,
    plantations_api_adapter
);

const PlantationService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    getPlants(permitId) {
        return AuthApiService.get(`${basePath}/${permitId}/plants/`).then(response => {
            return createPlants(plants_api_adapter(response));
        });
    },

    create(plantation) {
        return entityService.create(plantation);
    },

    createHolder(plantationId, holder) {
        return AuthApiService.post(`${basePath}/${plantationId}/holders/`, holder).then(
            response => createHolder(holder_api_adapter(response))
        );
    },

    createPlot(plantationId, plot) {
        return AuthApiService.post(`${basePath}/${plantationId}/plots/`, plot).then(
            response => createPlot(plot_api_adapter(response))
        );
    },

    createPlant(plantationId, plant) {
        return AuthApiService.post(`${basePath}/${plantationId}/plants/`, plant).then(
            response => createPlant(plant_api_adapter(response))
        );
    },

    update(plantation) {
        return entityService.update(plantation);
    },

    canBeDeleted(id) {
        return entityService.canBeDeleted(id).then(response => {
            if (response.result === "success") {
                return [true, ""];
            }
            return [false, response.detail];
        });
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default PlantationService;
