import {theme} from "Theme";
import {useDocFieldsValue} from "permitapplication/hooks";
import {FormCheckbox} from "base/form/components";
import {FileUploadField} from "base/file/components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const PermitApplicationDocReviewFormFields = ({disabled}) => {
    const maxSize = 5000000;

    const {
        application_doc,
        personal_id_doc,
        plot_ownership_doc,
        plot_use_authorization,
        tax_id_doc,
    } = useDocFieldsValue();

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
        >
            <Grid item xs={11} aria-hidden="true"></Grid>
            <Grid item xs={1} container justifyContent="center">
                <Typography
                    variant="subtitle1"
                    component="span"
                    fontSize={theme.typography.fontSize}
                    color={theme.palette.primary.dark}
                >
                    Validado
                </Typography>
                <Divider
                    sx={{
                        width: "100%",
                        borderColor: theme.palette.primary.lighter,
                    }}
                />
            </Grid>
            <Grid item xs={11}>
                <FileUploadField
                    name="application_doc"
                    label="Folha de solicitação preenchida e firmada pelo solicitante *"
                    maxSize={maxSize}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1} container justifyContent="center">
                <FormCheckbox
                    name="application_doc_valid"
                    disabled={disabled || !application_doc}
                />
            </Grid>
            {personal_id_doc ? (
                <>
                    <Grid item xs={11}>
                        <FileUploadField
                            name="personal_id_doc"
                            label="BI/Cartão de residência *"
                            maxSize={maxSize}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={1} container justifyContent="center">
                        <FormCheckbox
                            name="personal_id_doc_valid"
                            disabled={disabled || !personal_id_doc}
                        />
                    </Grid>
                </>
            ) : null}
            {tax_id_doc ? (
                <>
                    <Grid item xs={11}>
                        <FileUploadField
                            name="tax_id_doc"
                            label="Cartão Fiscal *"
                            subLabel="No caso de o solicitante ser uma empresa"
                            maxSize={maxSize}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={1} container justifyContent="center">
                        <FormCheckbox
                            name="tax_id_doc_valid"
                            disabled={disabled || !tax_id_doc}
                        />
                    </Grid>
                </>
            ) : null}
            <Grid item xs={11}>
                <FileUploadField
                    name="plot_ownership_doc"
                    label="Título de terras *"
                    maxSize={maxSize}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1} container justifyContent="center">
                <FormCheckbox
                    name="plot_ownership_doc_valid"
                    disabled={disabled || !plot_ownership_doc}
                />
            </Grid>
            {plot_use_authorization ? (
                <>
                    <Grid item xs={11}>
                        <FileUploadField
                            name="plot_use_authorization"
                            label="Declaração de autorização de posse/aproveitamento de terra *"
                            subLabel="No caso de o proprietário cede a posse a outra pessoa"
                            maxSize={maxSize}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={1} container justifyContent="center">
                        <FormCheckbox
                            name="plot_use_authorization_valid"
                            disabled={disabled || !plot_use_authorization}
                        />
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default PermitApplicationDocReviewFormFields;
