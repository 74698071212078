import {useTransportMaterialTable} from "material/data";
import {CreateMaterialDataContent} from "material/container";
import {SectionCard} from "base/ui/section/components";
import {SimpleTable} from "base/table/components";
import {NoDataContainer} from "base/shared/components";
import Stack from "@mui/system/Stack";

const ViewMaterialsTable = ({permitId, materials, displayFees, disabled}) => {
    const {tableColumns} = useTransportMaterialTable(displayFees);

    return (
        <SectionCard>
            <Stack spacing={3} mt={2}>
                {materials.length ? (
                    <SimpleTable dataRows={materials} tableColumns={tableColumns} />
                ) : (
                    <NoDataContainer text="Esta solicitação ainda não tem materiais." />
                )}

                {!disabled ? (
                    <CreateMaterialDataContent
                        permitId={permitId}
                        displayFees={displayFees}
                    />
                ) : null}
            </Stack>
        </SectionCard>
    );
};

export default ViewMaterialsTable;
