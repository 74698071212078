import {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useAuth} from "base/user/provider";
import {PermitApplicationService} from "permitapplication/service";

import {PermitApplicationGeneralDataSection} from "permitapplication/presentational";
import {EntityViewSubPage} from "base/entity/components/container";
import {EntityAuditSection} from "base/entity/components/presentational/sections";
import {DeleteEntityButton, DeleteItemDialog} from "base/delete/components";

const ViewPermitApplicationSummarySubPage = () => {
    const context = useOutletContext();
    const permit = context[0];
    const auth = useAuth();
    const navigate = useNavigate();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deletionError, setDeletionError] = useState(null);

    const allowDelete = auth.hasPermission("delete-permitapplications");

    useEffect(() => {
        setDeletionError(null);
    }, []);

    const handleDelete = () => {
        PermitApplicationService.delete(permit.id)
            .then(() => {
                navigate("/permits/applications/list");
            })
            .catch(error => {
                console.log(error);
                setDeletionError(error);
            });
    };

    const sections = [
        <PermitApplicationGeneralDataSection permit={permit} key="generaldata" />,
        <EntityAuditSection entity={permit} key="audit" />,
    ];

    return (
        permit && (
            <EntityViewSubPage sections={sections}>
                {allowDelete ? (
                    <>
                        <DeleteEntityButton
                            openDialog={() => setIsDeleteDialogOpen(true)}
                            entityName={"solicitação"}
                        />
                        <DeleteItemDialog
                            isDialogOpen={isDeleteDialogOpen}
                            setIsDialogOpen={setIsDeleteDialogOpen}
                            deletionError={deletionError}
                            onDelete={handleDelete}
                        />
                    </>
                ) : null}
            </EntityViewSubPage>
        )
    );
};

export default ViewPermitApplicationSummarySubPage;
