import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil} from "base/format/utilities";
import {PermitTypeTags} from ".";
import {SectionCard} from "base/ui/section/components";
import Grid from "@mui/material/Grid";

const ApprovedPermitGeneralDataSection = ({permit}) => {
    return (
        <SectionCard title="Informações gerais">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {FieldUtil.getSectionField("Código", permit.code)}
                    {FieldUtil.getSectionField(
                        "Data de solicitação",
                        DateUtil.formatDate(permit.application_date)
                    )}
                    {FieldUtil.getSectionField(
                        "Guias",
                        <PermitTypeTags types={permit.types} />
                    )}
                </Grid>
                <Grid item xs={6}>
                    {FieldUtil.getSectionField("Distrito", permit.district)}
                    {FieldUtil.getSectionField(
                        "Data de aprovação",
                        DateUtil.formatDate(permit.on_date)
                    )}
                    {FieldUtil.getSectionCheckBoxField("Em vigor", permit?.is_valid)}
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ApprovedPermitGeneralDataSection;
